import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import {
  SellOutlined,
  PaymentsOutlined,
  AccountBalanceOutlined,
  MoneyOffOutlined,
  TuneOutlined,
  ExpandMore,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import DataCard from '../../components/DataCard';
import SidebarLayout from '../../components/SidebarLayout';
import { SummaryWrapper, TableWrapper, FilterWrapper } from './index.styled';
import { setDateFormat, setMoneyFormat } from '../../utils/dataUtils';
import { CommissionContext } from '../../contexts/CommissionContext';
import type { AscDesc } from '../../contexts/types';
import { BOOKINGS_ROUTE } from '../../constants/routes';
import CustomTable from '../../components/CustomTable';
import CommissionStatus from '../../components/CommissionStatus';
import Card from '../../components/Card';
import CustomDatePicker from '../../components/CustomDatePicker';
import { useWindowSize } from '../../utils/windowUtils';

function CommissionsContainer() {
  const { commissionSummary, commissionList, getCommissions } = useContext(CommissionContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<AscDesc>('asc');

  const [fromDate, setFromDate] = useState<Date | null>(dayjs().toDate());
  const [toDate, setToDate] = useState<Date | null>(dayjs().add(1, 'M').toDate());
  const [status, setStatus] = useState<number>(-1);

  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const dateToRef = useRef<ReactDatePicker<string, undefined>>(null);
  const { width } = useWindowSize();

  const handleGetCommissions = async () => {
    setError('');
    setLoading(true);
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    const response = await getCommissions(
      offset,
      limit,
      sortColumn,
      sortDirection,
      fromDate,
      toDate,
      status,
    );
    //   if (response.error) setError(response.error); TODO: uncommented this line to show error
    setLoading(false);
  };

  useEffect(() => {
    handleGetCommissions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sortColumn, sortDirection, fromDate, toDate, status]);

  const handleOpenTo = () => {
    if (dateToRef?.current) (dateToRef.current as any).handleFocus();
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortColumn = (id: string) => {
    if (!sortColumn || sortColumn !== id) {
      setSortColumn(id);
      setSortDirection('asc');
    } else if (sortColumn === id && sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortColumn(null);
      setSortDirection('asc');
    }
  };

  const handleChangeFromDate = (date: Date) => {
    setFromDate(date);

    if (dayjs(date).isAfter(dayjs(toDate), 'day')) {
      setToDate(dayjs(date).add(1, 'M').toDate());
    }
  };

  const headers = [
    { id: 'id', name: '#' },
    { id: 'status', name: t('commissions.table.status') },
    { id: 'date', name: t('commissions.table.date') },
    { id: 'createdBy', name: t('commissions.table.createdBy') },
    { id: 'bookingId', name: t('commissions.table.bookingId') },
    { id: 'currency', name: t('commissions.table.currency') },
    { id: 'amount', name: t('commissions.table.amount') },
    { id: 'commission', name: t('commissions.table.commission') },
  ];

  const statusList = [
    { id: -1, name: t('commissionStatus.all') },
    { id: 0, name: t('commissionStatus.0') },
    { id: 1, name: t('commissionStatus.1') },
    { id: 2, name: t('commissionStatus.2') },
  ];

  const getTableContent = () => {
    return commissionList?.results?.map(
      ({ id, status, date, createdBy, bookingId, currency, amount, commission }) => (
        <TableRow hover key={id}>
          <TableCell component="th" scope="row">
            <Link className="TableLink" to={`${BOOKINGS_ROUTE.BOOKING_DETAILS}?id=${id}`}>
              {id}
            </Link>
          </TableCell>
          <TableCell component="th" scope="row">
            <CommissionStatus status={status} />
          </TableCell>
          <TableCell component="th" scope="row">
            {setDateFormat(date, 'DD/MM/YY')}
          </TableCell>
          <TableCell component="th" scope="row">
            {createdBy}
          </TableCell>
          <TableCell component="th" scope="row">
            <Link className="TableLink" to={`${BOOKINGS_ROUTE.BOOKING_DETAILS}?id=${bookingId}`}>
              {bookingId}
            </Link>
          </TableCell>
          <TableCell component="th" scope="row">
            {currency}
          </TableCell>
          <TableCell component="th" scope="row">
            {setMoneyFormat(amount)}
          </TableCell>
          <TableCell component="th" scope="row" className="CommissionValue">
            {setMoneyFormat(commission)}
          </TableCell>
        </TableRow>
      ),
    );
  };

  return (
    <SidebarLayout>
      <Typography variant="h1">{t('commissions.title')}</Typography>
      <SummaryWrapper container spacing={{ xs: 1, md: 2, lg: 3 }}>
        {commissionSummary?.historicalTotal && (
          <Grid item xl={3} md={6} xs={12} className="SummaryItem">
            <DataCard
              amount={setMoneyFormat(commissionSummary.historicalTotal)}
              title={t('commissions.historicalTotal')}
              icon={<SellOutlined />}
              iconColor={theme.palette.success.main}
            />
          </Grid>
        )}
        {commissionSummary?.monthlyTotal && (
          <Grid item xl={3} md={6} xs={12} className="SummaryItem">
            <DataCard
              amount={setMoneyFormat(commissionSummary.monthlyTotal)}
              title={t('commissions.monthlyTotal')}
              icon={<PaymentsOutlined />}
              iconColor={theme.palette.info.main}
            />
          </Grid>
        )}
        {commissionSummary?.cashed && (
          <Grid item xl={3} md={6} xs={12} className="SummaryItem">
            <DataCard
              amount={setMoneyFormat(commissionSummary.cashed)}
              title={t('commissions.cashed')}
              icon={<AccountBalanceOutlined />}
              iconColor={theme.palette.primary.main}
            />
          </Grid>
        )}
        {commissionSummary?.receivable && (
          <Grid item xl={3} md={6} xs={12} className="SummaryItem">
            <DataCard
              amount={setMoneyFormat(commissionSummary.receivable)}
              title={t('commissions.receivable')}
              icon={<MoneyOffOutlined />}
              iconColor={theme.palette.secondary.main}
            />
          </Grid>
        )}
      </SummaryWrapper>
      <FilterWrapper>
        <Card>
          <Box className="FilterIcon">
            <TuneOutlined />
          </Box>
          <Grid container spacing={{ xs: 1, md: 2 }}>
            <Grid item xs={6} md={4}>
              <CustomDatePicker
                label={t('general.from')}
                selected={fromDate || dayjs().toDate()}
                selectsStart
                monthsShown={width > 460 ? 2 : 1}
                onChange={handleChangeFromDate}
                onCalendarClose={handleOpenTo}
                startDate={fromDate || dayjs().toDate()}
                endDate={toDate || dayjs().add(1, 'M').toDate()}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <CustomDatePicker
                label={t('general.to')}
                selected={toDate || dayjs().add(1, 'M').toDate()}
                minDate={fromDate || dayjs().toDate()}
                monthsShown={width > 460 ? 2 : 1}
                onChange={(date) => setToDate(date)}
                selectsEnd
                startDate={fromDate || dayjs().toDate()}
                endDate={toDate || dayjs().add(1, 'M').toDate()}
                ref={dateToRef}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth className="StatusFilter">
                <InputLabel id="status-label">{t('commissions.status')}</InputLabel>
                <Select
                  labelId="status-label"
                  value={status}
                  label={t('commissions.status')}
                  onChange={({ target }) => setStatus(target.value as number)}
                  IconComponent={ExpandMore}
                  defaultValue={status || ''}
                  margin="dense"
                  placeholder="select"
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {statusList.map((item, idx) => {
                    return (
                      <MenuItem key={idx} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Card>
      </FilterWrapper>
      <TableWrapper>
        <CustomTable
          headers={headers}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          emptyTable={!commissionList || commissionList.results?.length === 0}
          emptyTableText={t('commissions.table.noData')}
          loading={loading}
          error={error}
          colSpan={9}
          count={commissionList?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          tableContent={getTableContent()}
          handleRetry={handleGetCommissions}
          handleSortColumn={handleSortColumn}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableWrapper>
    </SidebarLayout>
  );
}
export default CommissionsContainer;
