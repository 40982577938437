import { Button } from '@mui/material';
import styled from 'styled-components';

const StyledUploadFileBtn = styled((props) => <Button {...props} />)`
  position: relative;
  margin-left: -16px;

  input {
    position: absolute;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
`;

export { StyledUploadFileBtn };
