import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_SM, SCREEN_XL, SCREEN_XXL } from '../../constants/screenSize';

const StyledLoginContainer = styled((props) => <Box {...props} />)`
  display: flex;
  height: 100%;

  & > .MuiGrid-container {
    max-width: 70%;
    margin: auto;
  }

  .GridTitle-Container {
    display: flex;
    align-items: center;

    .Grid-Title {
      max-width: 600px;
      font-size: 1.8rem;
      font-weight: 500;
      color: ${({ theme: { palette } }) => palette.text.secondary};
    }
  }

  .ErrorWrapper {
    margin-bottom: 1rem;
  }

  @media (max-width: ${SCREEN_SM}px) {
    & > .MuiGrid-container {
      max-width: 90%;
    }

    .GridTitle-Container .Grid-Title {
      font-size: 1.2rem;
    }
  }

  @media (max-width: ${SCREEN_XL}px) {
    .GridTitle-Container .Grid-Title {
      margin: auto;
      margin-bottom: 6%;
      text-align: center;
    }
  }

  @media (min-width: ${SCREEN_XXL}px) {
    .MuiGrid-container {
      max-width: 1440px;
    }

    .GridTitle-Container .Grid-Title {
      font-size: 2.5rem;
    }
  }
`;

const LoginForm = styled((props) => <Grid {...props} />)`
  .LogoWrapper,
  .Grid-Forgot {
    text-align: center;
  }

  .LogoWrapper {
    img {
      width: 180px;
      height: auto;
    }
    margin-bottom: 5%;
  }
`;

export { StyledLoginContainer, LoginForm };
