import { useContext, useEffect, useState } from 'react';
import { Button, Grid, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import SidebarLayout from '../../components/SidebarLayout';
import { HeaderWrapper, TableWrapper } from './index.styled';
import { BookingContext } from '../../contexts/BookingContext';
import { setDateFormat, setMoneyFormat } from '../../utils/dataUtils';
import { BOOKINGS_ROUTE } from '../../constants/routes';
import BookingStatus from '../../components/BookingStatus';
import type { AscDesc } from '../../contexts/types';
import CustomTable from '../../components/CustomTable';

function BookingsContainer() {
  const { bookingList, getBookings } = useContext(BookingContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<AscDesc>('asc');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGetBookings = async () => {
    setError('');
    setLoading(true);
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    const response = await getBookings(offset, limit, sortColumn, sortDirection);
    //   if (response.error) setError(response.error); TODO: uncommented this line to show error
    setLoading(false);
  };

  useEffect(() => {
    handleGetBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sortColumn, sortDirection]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortColumn = (id: string) => {
    if (!sortColumn || sortColumn !== id) {
      setSortColumn(id);
      setSortDirection('asc');
    } else if (sortColumn === id && sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortColumn(null);
      setSortDirection('asc');
    }
  };

  const headers = [
    { id: 'id', name: '#' },
    { id: 'state', name: t('bookings.table.headers.state') },
    { id: 'customer', name: t('bookings.table.headers.customer') },
    { id: 'from', name: t('bookings.table.headers.from') },
    { id: 'to', name: t('bookings.table.headers.to') },
    { id: 'author', name: t('bookings.table.headers.author') },
    { id: 'price', name: t('bookings.table.headers.price') },
    { id: 'currency', name: t('bookings.table.headers.currency') },
  ];

  const getTableContent = () => {
    return bookingList?.results?.map(
      ({ agentName, currentStatus, id, fromDate, toDate, customer, price, currency }) => (
        <TableRow hover key={id}>
          <TableCell component="th" scope="row">
            <Link className="TableLink" to={`${BOOKINGS_ROUTE.BOOKING_DETAILS}?id=${id}`}>
              {id}
            </Link>
          </TableCell>
          <TableCell component="th" scope="row">
            <BookingStatus status={currentStatus} />
          </TableCell>
          <TableCell component="th" scope="row">
            {`${customer.firstName} ${customer.lastName}`}
          </TableCell>
          <TableCell component="th" scope="row">
            {setDateFormat(fromDate, 'DD/MM/YY hh:mm')}
          </TableCell>
          <TableCell component="th" scope="row">
            {setDateFormat(toDate, 'DD/MM/YY hh:mm')}
          </TableCell>
          <TableCell component="th" scope="row">
            {agentName}
          </TableCell>
          <TableCell component="th" scope="row">
            {setMoneyFormat(price)}
          </TableCell>
          <TableCell component="th" scope="row">
            {currency}
          </TableCell>
        </TableRow>
      ),
    );
  };

  return (
    <SidebarLayout>
      <HeaderWrapper container>
        <Grid item xs={12} md={6}>
          <Typography variant="h1">{t('bookings.title')}</Typography>
        </Grid>
        <Grid item xs={12} md={6} className="RightCol">
          <Button variant="contained" onClick={() => navigate('#')}>
            {t('bookings.newBookingButton')}
          </Button>
        </Grid>
      </HeaderWrapper>
      <TableWrapper>
        <CustomTable
          headers={headers}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          emptyTable={bookingList?.results?.length === 0}
          emptyTableText={t('bookings.table.noData')}
          loading={loading}
          error={error}
          colSpan={9}
          count={bookingList?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          tableContent={getTableContent()}
          handleRetry={handleGetBookings}
          handleSortColumn={handleSortColumn}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableWrapper>
    </SidebarLayout>
  );
}

export default BookingsContainer;
