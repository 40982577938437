import { CircularProgress, TableCell } from '@mui/material';
import { TableLoadingWrapper } from './index.styled';
import { TableLoadingProps } from './types';

function TableLoading({ colSpan }: TableLoadingProps) {
  return (
    <TableLoadingWrapper>
      <TableCell colSpan={colSpan} align="center">
        <CircularProgress size={20} />
      </TableCell>
    </TableLoadingWrapper>
  );
}
export default TableLoading;
