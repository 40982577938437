import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
// import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

import type { SearchFiltersProps } from './types';
import StyledSearchFilters from './index.styled';
import Card from '../Card';
import { RentalContext } from '../../contexts/RentalContext';

const SearchFilters = ({
  selectedCategories,
  selectedKm,
  handleSelectCategory,
  handleSelectKm,
}: SearchFiltersProps) => {
  const { categoryList } = useContext(RentalContext);
  const { t } = useTranslation();

  return (
    <StyledSearchFilters className="SearchFiltersComponent">
      <Card>
        <Typography className="Filter-Title" variant="h3">
          {t('general.categories')}
        </Typography>
        <FormGroup>
          {categoryList.map((category) => {
            return (
              <FormControlLabel
                key={category.id}
                control={
                  <Checkbox
                    checked={selectedCategories.includes(category.id)}
                    onClick={() => handleSelectCategory(category.id)}
                  />
                }
                label={category.name}
              />
            );
          })}
        </FormGroup>
      </Card>
      {/* 
      // TODO: Add kilometer filter
      <Card>
        <Typography className="Filter-Title" variant="h3">
          {t('general.kilometers')}
        </Typography>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedKm === 0}
                  onClick={() => handleSelectKm(0)}
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<RadioButtonChecked />}
                />
              }
              label={t('search.unlimited')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedKm === 1}
                  onClick={() => handleSelectKm(1)}
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<RadioButtonChecked />}
                />
              }
              label={t('search.daily')}
            />
          </FormGroup>
        </Box>
      </Card> */}
    </StyledSearchFilters>
  );
};
export default SearchFilters;
