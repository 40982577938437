import { Box, lighten } from '@mui/material';
import styled from 'styled-components';

const StyledDatePicker = styled((props) => <Box {...props} />)`
  .react-datepicker {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background: ${({ theme: { palette } }) => palette.background.paper};
    border: ${({ theme }) => `1px solid ${theme.extra.border}`};
    border-radius: 10px;
    box-shadow: 4px 4px 13px #0000001a;
  }

  .react-datepicker__month-container .react-datepicker__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
  }

  .react-datepicker__month-container:last-of-type .react-datepicker__header {
    border-top-right-radius: 10px;
    border-top-left-radius: 0;
  }

  .react-datepicker__header {
    background: ${({ theme: { palette } }) => palette.background.default};
    border-bottom: ${({ theme }) => `1px solid ${theme.extra.border}`};
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: ${({ theme }) => theme.extra.border};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: ${({ theme: { palette } }) => palette.primary.main};
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: ${({ theme: { palette } }) => lighten(palette.primary.light, 0.4)};
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected {
    color: white;
  }

  .react-datepicker__day--excluded,
  .react-datepicker__day--disabled {
    color: ${({ theme: { palette } }) => lighten(palette.text.secondary, 0.4)};
    background-color: ${({ theme: { palette } }) => lighten(palette.text.disabled, 0.9)};
    border-radius: 5px;
  }

  .react-datepicker__current-month {
    text-transform: capitalize;
  }

  .react-datepicker__day-name {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
  }
`;
export default StyledDatePicker;
