import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Box,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

import { StyledContainer } from './index.styled';
import Card from '../Card/index';
import { RentalContext } from '../../contexts/RentalContext';
import type { Agreements } from '../../contexts/BookingContext';
import type { City, Customer } from '../../contexts/types';
import type { CustomerDataProps } from './types';
import { countries } from '../../constants/data';
import { SearchContext } from '../../contexts/SearchContext';
import { useEffectOnMount } from '../../hooks';
import CustomAutocomplete from '../CustomAutocomplete';
import { numberToString } from '../../utils/dataUtils';

// eslint-disable-next-line no-empty-pattern
function CustomerData({
  customerInfo,
  agreements,
  formErrors,
  accordionExpanded,
  handleAccordion,
  setAgreements,
  setCustomerInfo,
}: CustomerDataProps) {
  const { t } = useTranslation();

  const { searchParams, availableCommercialAgreementList, setSearchParams } =
    useContext(SearchContext);

  const { documentTypes, fiscalConditionList } = useContext(RentalContext);
  const { customerList, getCustomerByEmail, getPrices } = useContext(SearchContext);
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);

  const handleAgreementsChange = async (key: string, value: string) => {
    const prevAgreements = agreements || {};
    setAgreements({ ...prevAgreements, [key]: value } as Agreements);
    setSearchParams({ ...searchParams!, commercialAgreement: searchParams!.commercialAgreement });
    await getPrices();
  };

  useEffectOnMount(() => {
    // Add default values
    if (searchParams?.commercialAgreement) {
      handleAgreementsChange('commercialAgreement', searchParams.commercialAgreement);
    }
  });

  useEffect(() => {
    // Call customer email set debounce
    const timeoutHandler = setTimeout(async () => {
      if (!customerInfo?.emailAddress) return;

      setLoadingEmail(true);
      await getCustomerByEmail(customerInfo?.emailAddress || '');
      setLoadingEmail(false);
    }, 300);
    return () => clearTimeout(timeoutHandler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo?.emailAddress]);

  const handleChange = (key: string, value: string | number | null) => {
    setCustomerInfo({ ...customerInfo, [key]: value } as Customer);
  };

  const handleSelectCustomerByEmail = (customer: Customer | null) => {
    if (customerInfo && customer) {
      setCustomerInfo({
        ...customerInfo,
        ...(customer.firstName && { firstName: customer.firstName }),
        ...(customer.lastName && { lastName: customer.lastName }),
        ...(customer.documentId && { documentId: customer.documentId }),
        ...(customer.documentTypeId && { documentTypeId: customer.documentTypeId }),
        ...(customer.emailAddress && { emailAddress: customer.emailAddress }),
        ...(customer.address && { address: customer.address }),
        ...(customer.addressNumber && { addressNumber: customer.addressNumber }),
        ...(customer.addressDepartment && { addressDepartment: customer.addressDepartment }),
        ...(customer.cellPhone && { cellPhone: customer.cellPhone }),
        ...(customer.zipCode && { zipCode: customer.zipCode }),
        ...(customer.country && { country: customer.country }),
        ...(customer.city && { city: customer.city }),
        ...(customer.state && { state: customer.state }),
        ...(customer.id && { id: customer.id }),
        ...(customer.globalId && { globalId: customer.globalId }),
        ...(customer.birthDate && { birthDate: customer.birthDate }),
        ...(customer.taxPayer && { taxPayer: customer.taxPayer }),
      });
    }
  };

  const getAgreementsRenderOption = (
    agreement: string | null,
    props: HTMLAttributes<HTMLLIElement>,
  ) => {
    return (
      <li {...props}>
        <Typography className="Item-Name">{agreement}</Typography>
      </li>
    );
  };

  const getRenderOption = (props: HTMLAttributes<HTMLLIElement>, customer: Customer) => {
    return (
      <MenuItem {...props}>
        <Typography className="Item-Name">{customer.emailAddress}</Typography>
      </MenuItem>
    );
  };

  const getAgreementByCode = (code?: string) => {
    if (!code) return null;

    return availableCommercialAgreementList.find((p) => p.code === code) || null;
  };

  return (
    <StyledContainer>
      <Grid item>
        <Card padding="1rem">
          <Accordion
            expanded={accordionExpanded}
            onChange={() => handleAccordion(!accordionExpanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="customer-details-content"
              id="customer-details-header"
            >
              <Box className="IconWrapper">
                <PermIdentityIcon />
                <Typography variant="subtitle1" className="Typography">
                  {t('customerData.title')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails">
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={2.4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      className="EmailInput"
                      placeholder={t('customerData.emailAddress')}
                      freeSolo
                      onChange={(e, value) => handleSelectCustomerByEmail(value as Customer)}
                      onInputChange={(e, value) => handleChange('emailAddress', value)}
                      inputValue={customerInfo?.emailAddress || ''}
                      options={customerList}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('customerData.emailAddress')}
                          error={!!formErrors?.emailAddress}
                          helperText={formErrors?.emailAddress}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingEmail ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  params.InputProps.endAdornment
                                )}
                              </>
                            ),
                          }}
                        />
                      )}
                      openOnFocus={false}
                      renderOption={(props, customer) => getRenderOption(props, customer)}
                      getOptionLabel={(option) => (option as Customer).emailAddress}
                      noOptionsText={t('customerData.noClientFound')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <TextField
                    value={customerInfo?.firstName || ''}
                    onChange={({ target }) => handleChange('firstName', target.value)}
                    label={t('customerData.firstName')}
                    error={!!formErrors?.firstName}
                    helperText={formErrors?.firstName}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <TextField
                    label={t('customerData.lastName')}
                    value={customerInfo?.lastName || ''}
                    onChange={({ target }) => handleChange('lastName', target.value)}
                    error={!!formErrors?.lastName}
                    helperText={formErrors?.lastName}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2.4}>
                  <FormControl size="medium" className="Select">
                    <InputLabel id="pickup-country-label">{t('customerData.country')}</InputLabel>
                    <Select
                      labelId="pickup-country-label"
                      value={customerInfo?.country || ''}
                      defaultValue=""
                      onChange={({ target }) => handleChange('country', target.value)}
                      autoWidth
                      label={t('customerData.country')}
                      error={!!formErrors?.country}
                    >
                      {countries.map((country, id) => {
                        return (
                          <MenuItem key={id} value={country.name}>
                            {country.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formErrors?.country && (
                      <FormHelperText error>{formErrors?.country}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={2.4}>
                  <TextField
                    label={t('customerData.city')}
                    value={customerInfo?.city || ''}
                    onChange={({ target }) => handleChange('city', target.value)}
                    error={!!formErrors?.city}
                    helperText={formErrors?.city}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2.4}>
                  <TextField
                    label={t('customerData.state')}
                    value={customerInfo?.state || ''}
                    onChange={({ target }) => handleChange('state', target.value)}
                    error={!!formErrors?.state}
                    helperText={formErrors?.state}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2.4}>
                  <TextField
                    label={t('customerData.addressDepartment')}
                    value={customerInfo?.addressDepartment || ''}
                    onChange={({ target }) => handleChange('addressDepartment', target.value)}
                    error={!!formErrors?.addressDepartment}
                    helperText={formErrors?.addressDepartment}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <TextField
                    label={t('customerData.address')}
                    value={customerInfo?.address || ''}
                    onChange={({ target }) => handleChange('address', target.value)}
                    error={!!formErrors?.address}
                    helperText={formErrors?.address}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={1.8}>
                  <TextField
                    label={t('customerData.addressNumber')}
                    value={customerInfo?.addressNumber || ''}
                    onChange={({ target }) => handleChange('addressNumber', target.value)}
                    error={!!formErrors?.addressNumber}
                    helperText={formErrors?.addressNumber}
                    inputProps={{ inputMode: 'numeric' }}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2.4}>
                  <TextField
                    label={t('customerData.zipCode')}
                    value={customerInfo?.zipCode || ''}
                    onChange={({ target }) => handleChange('zipCode', target.value)}
                    error={!!formErrors?.zipCode}
                    helperText={formErrors?.zipCode}
                  />
                </Grid>

                <Grid item xs={12} md={3} lg={2.1}>
                  <FormControl size="medium" className="Select">
                    <InputLabel id="pickup-documentTypeId-label">
                      {t('customerData.documentTypeId')}
                    </InputLabel>
                    <Select
                      labelId="documentTypeId-label"
                      value={numberToString(customerInfo?.documentTypeId)}
                      onChange={({ target }) =>
                        handleChange('documentTypeId', Number(target.value))
                      }
                      label={t('customerData.documentTypeId')}
                      error={!!formErrors?.documentTypeId}
                    >
                      {documentTypes.map((document) => {
                        return (
                          <MenuItem key={`${document.id}`} value={`${document.id}`}>
                            {document.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formErrors?.documentTypeId && (
                      <FormHelperText error>{formErrors?.documentTypeId}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3.3}>
                  <TextField
                    value={customerInfo?.documentId || ''}
                    label={t('customerData.documentId')}
                    onChange={({ target }) => handleChange('documentId', target.value)}
                    error={!!formErrors?.documentId}
                    helperText={formErrors?.documentId}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3.3}>
                  <TextField
                    value={customerInfo?.cellPhone || ''}
                    label={t('customerData.cellPhone')}
                    onChange={({ target }) => handleChange('cellPhone', target.value)}
                    error={!!formErrors?.cellPhone}
                    helperText={formErrors?.cellPhone}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3.3}>
                  <FormControl size="medium" className="Select">
                    <InputLabel id="pickup-taxPayer-label">{t('customerData.taxPayer')}</InputLabel>
                    <Select
                      labelId="pickup-taxPayer-label"
                      value={numberToString(customerInfo?.taxPayer)}
                      onChange={({ target }) => handleChange('taxPayer', Number(target.value))}
                      label={t('customerData.taxPayer')}
                      error={!!formErrors?.taxPayer}
                    >
                      {fiscalConditionList.map((item) => {
                        return (
                          <MenuItem key={`${item.id}`} value={`${item.id}`}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formErrors?.taxPayer && (
                      <FormHelperText error>{formErrors?.taxPayer}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item>
                <Box className="Agreements">
                  <HandshakeOutlinedIcon className="Agreements" />
                  <Typography variant="subtitle1" className="Typography">
                    {t('customerData.subTitle')}
                  </Typography>
                </Box>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                  <CustomAutocomplete
                    placeholder={t('searchForm.commercialAgreement')}
                    value={getAgreementByCode(agreements?.commercialAgreement)}
                    onChange={(e, value) =>
                      handleAgreementsChange('commercialAgreement', value?.code || null)
                    }
                    options={availableCommercialAgreementList}
                    renderOption={(props, agreement) =>
                      getAgreementsRenderOption(agreement?.code || null, props)
                    }
                    getOptionLabel={(option) => option?.code || ''}
                    noOptionsText={t('searchForm.noAvailableCommercialAgreements')}
                    disableClearable={false}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <FormControl fullWidth className="Select">
                    <InputLabel id="discount-type">
                      {t('checkout.agreements.promotionType')}
                    </InputLabel>
                    <Select
                      labelId="discount-type"
                      value={agreements?.promotionType || ''}
                      defaultValue=""
                      onChange={({ target }) =>
                        handleAgreementsChange('promotionType', target.value)
                      }
                      autoWidth
                      label={t('checkout.agreements.promotionType')}
                      error={!!formErrors?.promotionType}
                    >
                      <MenuItem value="percentage">{t('checkout.agreements.percentage')}</MenuItem>
                      <MenuItem value="amount">{t('checkout.agreements.amount')}</MenuItem>
                    </Select>
                    {formErrors?.promotionType && (
                      <FormHelperText error>{formErrors?.promotionType}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <TextField
                    label={t('checkout.agreements.discountAmount')}
                    value={agreements?.discountAmount || ''}
                    onChange={({ target }) =>
                      handleAgreementsChange('discountAmount', target.value)
                    }
                    type="number"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Grid>
    </StyledContainer>
  );
}

export default CustomerData;
