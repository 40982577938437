import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_LG } from '../../constants/screenSize';

const GridContainer = styled((props) => <Grid {...props} />)`
  padding-bottom: 5rem;

  .MuiGrid-container {
    min-width: 100%;
  }

  .ButtonsContainer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

    button {
      min-width: 200px;
      margin-left: 14px;
    }
  }

  @media (max-width: ${SCREEN_LG}px) {
    .ButtonsContainer {
      flex-direction: column;
      width: 100%;

      button {
        margin-left: 0;
        margin-bottom: 14px;
      }
    }
  }
`;

const CheckoutRightContainer = styled((props) => <Grid {...props} />)`
  .RightContainer-Item {
    width: 100%;
  }
`;

const NoSearchParamsWrapper = styled((props) => <Box {...props} />)`
  max-width: 400px;
  margin: auto;
  margin-top: 20%;
  text-align: center;

  svg {
    width: 60px;
    height: auto;
    margin-bottom: 10px;
    color: ${({ theme: { palette } }) => palette.text.secondary};
    opacity: 0.5;
  }

  p {
    color: ${({ theme: { palette } }) => palette.text.secondary};
    margin-bottom: 30px;
  }

  button {
    min-width: 200px;
  }
`;

export { CheckoutRightContainer, GridContainer, NoSearchParamsWrapper };
