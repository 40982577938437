import { FormControl } from '@mui/material';
import styled from 'styled-components';

const StyledCustomAutocomplete = styled((props) => <FormControl {...props} />)`
  .MuiTypography-root {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .MuiInputBase-root {
    .StartAdornment {
      color: ${({ theme: { palette } }) => palette.primary.main};
    }

    .MuiAutocomplete-popupIndicator {
      opacity: 0;
    }

    .EndAdornment {
      position: absolute;
      right: 10px;
      color: rgba(0, 0, 0, 0.54);
      width: 24px;

      &.OpenMenu {
        transform: rotate(180);
      }
    }
  }
`;

export { StyledCustomAutocomplete };
