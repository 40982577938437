import { useContext } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import {
  PersonOutlineOutlined,
  LuggageOutlined,
  ImageNotSupportedOutlined,
  SpeedOutlined,
  DeviceHub,
} from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { SearchResultItemProps } from './types';
import { StyledSearchResultItem, CarImage, CarDescription, CarTitle } from './index.styled';
import Card from '../Card';
import { SEARCH_ROUTE } from '../../constants/routes';
import { SCREEN_LG } from '../../constants/screenSize';
import { SearchContext } from '../../contexts/SearchContext';
import { useWindowSize } from '../../utils/windowUtils';
import { setMoneyFormat } from '../../utils/dataUtils';

const SearchResultItem = ({ item, setLoading }: SearchResultItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSelectedResult, getPrices, getAdditionalList } = useContext(SearchContext);

  const { width } = useWindowSize();
  const { car, unlimitedKm, maxAllowedDistanceByDay, price, totalDaysString, currency } = item;

  const handleSelectCar = async () => {
    setLoading(true);
    setSelectedResult(item);
    await getPrices([], item.car.model.id);
    await getAdditionalList(item.car.model.id, item.category.id);
    setLoading(false);
    navigate(SEARCH_ROUTE.CHECKOUT);
  };

  const carTitle = (
    <CarTitle>
      <Typography variant="h2">
        {car.model.name} <span>{t('search.orSimilar')}</span>
      </Typography>
      <Typography>{`${t('general.category')} ${car.model.category.name}`}</Typography>
    </CarTitle>
  );

  const mobileView = width < SCREEN_LG;

  return (
    <StyledSearchResultItem>
      <Card padding="1rem 1.5rem">
        <Grid container>
          <Grid item lg={3} xl={2} className="Item-FirstCol">
            {car.model.imagePath ? (
              <CarImage src={car.model.imagePath} alt={car.model.name} loading="lazy" />
            ) : (
              <ImageNotSupportedOutlined />
            )}
            {mobileView && carTitle}
          </Grid>
          <Grid item lg={6} xl={7} className="Item-SndCol">
            {!mobileView && carTitle}
            <Grid item container columnSpacing={1}>
              <Grid item xs={6}>
                <CarDescription>
                  <PersonOutlineOutlined />
                  <Typography>{t('search.passengers', { count: car.model.passengers })}</Typography>
                </CarDescription>

                <CarDescription>
                  <LuggageOutlined />
                  <Typography>
                    {t('search.smallLuggages', { count: car.model.smallLuggage })}
                  </Typography>
                </CarDescription>

                <CarDescription>
                  <LuggageOutlined />
                  <Typography>
                    {t('search.bigLuggages', { count: car.model.bigLuggage })}
                  </Typography>
                </CarDescription>
              </Grid>
              <Grid item xs={6}>
                {car.model.gearbox && (
                  <CarDescription>
                    <DeviceHub />
                    <Typography>{car.model.gearbox}</Typography>
                  </CarDescription>
                )}

                <CarDescription>
                  <SpeedOutlined />
                  <Typography>
                    {unlimitedKm
                      ? t('search.unlimitedKm')
                      : t('search.maxAllowedDistance', { num: maxAllowedDistanceByDay })}
                  </Typography>
                </CarDescription>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} className="Item-ThirdCol">
            <Typography className="TotalDays">{`${t(
              'search.rentFor',
            )} ${totalDaysString}`}</Typography>
            <Typography className="Price">
              <span>{currency}</span>
              {setMoneyFormat(Math.round(price), 0)}
            </Typography>
            <Button variant="contained" onClick={handleSelectCar} fullWidth>
              {t('search.book')}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </StyledSearchResultItem>
  );
};
export default SearchResultItem;
