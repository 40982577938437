import { useContext, useEffect, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { StyledLoginContainer, LoginForm } from './index.styled';
import Logo from '../../assets/images/logo.png';
import Card from '../../components/Card';
import { AuthContext } from '../../contexts/AuthContext';
import { isValidEmail, isValidPassword } from '../../utils/validationUtils';
import { AUTH_ROUTE } from '../../constants/routes';
import { RentalContext } from '../../contexts/RentalContext';

function LoginContainer() {
  const { signIn, isAuthenticated } = useContext(AuthContext);
  const { getBookingSettings } = useContext(RentalContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberUser, setRememberUser] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState({ email: false, password: false });
  const [loginError, setLoginError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) return navigate('/');
  }, [isAuthenticated, navigate]);

  const handleSignIn = async (e: MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    const { error } = await signIn(email, password, rememberUser);
    if (error) setLoginError(error);
    else {
      await getBookingSettings();
    }
    setLoading(false);
  };

  //Disables button if password is less than 8 characters or the email is not in the correct format.
  const disabledButton = !isValidEmail(email) || !isValidPassword(password);

  const handleEmailOnBlur = () => {
    const emailError = !isValidEmail(email);
    setFieldError({ ...fieldError, email: emailError });
  };

  const handlePasswordOnBlur = () => {
    const passwordError = !isValidPassword(password);
    setFieldError({ ...fieldError, password: passwordError });
  };

  return (
    <StyledLoginContainer>
      <Grid container direction="row" columnSpacing={{ md: 0, lg: 6 }}>
        <Grid item md={12} lg={6} className="GridTitle-Container">
          <h1 className="Grid-Title">{t('login.title')}</h1>
        </Grid>
        <Grid item md={12} lg={6}>
          <Card>
            <LoginForm container rowSpacing={3}>
              <Grid item xs={12} className="LogoWrapper">
                <img src={Logo} alt="Company logo" />
              </Grid>
              {loginError && (
                <Grid item xs={12} className="ErrorWrapper">
                  <Alert severity="error">{loginError}</Alert>
                </Grid>
              )}
              <Grid item xs={12} className="Grid-Email">
                <TextField
                  error={fieldError.email}
                  value={email}
                  helperText={fieldError.email && t('errors.wrongMail')}
                  onBlur={handleEmailOnBlur}
                  name="email"
                  onChange={({ target }) => setEmail(target.value)}
                  label={t('login.email')}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                />
              </Grid>
              <Grid item xs={12} className="Grid-Password">
                <FormControl fullWidth variant="outlined">
                  <TextField
                    error={fieldError.password}
                    value={password}
                    onBlur={handlePasswordOnBlur}
                    type={showPassword ? 'text' : 'password'}
                    onChange={({ target }) => setPassword(target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label={t('login.password')}
                    helperText={fieldError.password && t('errors.wrongPassword')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} className="Grid-Remember">
                <FormControlLabel
                  control={
                    <Checkbox
                      className="Checkbox"
                      value={rememberUser}
                      onChange={() => setRememberUser(!rememberUser)}
                    />
                  }
                  label={t('login.checkLabel')}
                />
              </Grid>
              <Grid item xs={12} className="Grid-Button">
                <LoadingButton
                  variant="contained"
                  fullWidth
                  size="large"
                  type="submit"
                  onClick={handleSignIn}
                  disabled={disabledButton}
                  loading={loading}
                >
                  {t('login.buttonTitle')}
                </LoadingButton>
              </Grid>

              <Grid item xs={12} className="Grid-Forgot">
                <Button variant="text" onClick={() => navigate(AUTH_ROUTE.FORGOT_PASSWORD)}>
                  {t('login.forgotPassword')}
                </Button>
              </Grid>
            </LoginForm>
          </Card>
        </Grid>
      </Grid>
    </StyledLoginContainer>
  );
}

export default LoginContainer;
