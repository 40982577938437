import { DeleteOutlineOutlined, FileDownloadOutlined, PhotoOutlined } from '@mui/icons-material';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { StyledFileItem } from './index.styles';
import type { FileItemProps } from './types';

function FileItem({ name, link, onDelete }: FileItemProps) {
  return (
    <StyledFileItem>
      <Box className="FileName">
        <PhotoOutlined />
        <Typography>{name}</Typography>
      </Box>
      <Box className="FileActions">
        <IconButton onClick={onDelete}>
          <DeleteOutlineOutlined />
        </IconButton>
        <IconButton component={Link} href={link} target="_blank" download>
          <FileDownloadOutlined />
        </IconButton>
      </Box>
    </StyledFileItem>
  );
}
export default FileItem;
