import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_XS } from '../../constants/screenSize';

const HeaderWrapper = styled((props) => <Grid {...props} />)`
  align-items: center;
  margin-bottom: 2rem;

  .RightCol {
    display: flex;
    justify-content: flex-end;
  }

  button {
    min-width: 200px;
    line-height: 1;
  }

  @media (max-width: ${SCREEN_XS}px) {
    .RightCol {
      justify-content: flex-start;

      button {
        width: 100%;
      }
    }

    h1 {
      margin-bottom: 1rem;
    }
  }
`;

const ErrorWrapper = styled((props) => <Box {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  max-width: 525px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  p {
    color: ${({ theme: { palette } }) => palette.text.primary};
    margin-bottom: 20px;
  }

  button {
    min-width: 150px;
  }
`;

const ContentWrapper = styled((props) => <Grid {...props} />)`
  max-width: 1600px;
  color: ${({ theme: { palette } }) => palette.text.secondary};
  font-size: 1rem;

  .Subtitle {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .ContactDetails-Wrapper {
    margin-bottom: 2rem;
  }

  .UploadFiles-Btn {
    margin-bottom: 1rem;
  }
`;

const ContactItem = styled((props) => <Box {...props} />)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme: { palette } }) => palette.text.secondary};
  font-size: 1rem;
  margin-bottom: 0.5rem;
  min-height: 36px;

  .TextWrapper {
    display: flex;
    align-items: center;
    margin-right: 10px;
    width: calc(100% - 46px);

    svg {
      margin-right: 10px;
      font-size: 1.1rem;
    }

    .Ellipsis-Text {
      width: calc(100% - 28px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .CopyBtn {
    position: relative;

    .MuiAlert-root {
      position: absolute;
      right: 40px;
      top: 0;
      padding: 5px 10px;

      div {
        padding: 2px 0;
      }
    }
  }

  .MuiIconButton-root {
    svg {
      font-size: 1.2rem;
    }
  }
`;

export { ContentWrapper, HeaderWrapper, ErrorWrapper, ContactItem };
