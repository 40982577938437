import { getAPI } from '../API';
import type { AscDesc } from '../contexts/types';
import { getAuthHeader } from '../utils/storageUtils';

export const handleGetBookings = async (
  offset: number,
  limit: number,
  sortBy: string | null,
  sortDirection: AscDesc | null,
) => {
  const api = getAPI();
  const sortByInfo = sortBy ? `?sortBy=${sortBy}` : '';
  const sortDirectionInfo = sortDirection ? `?sortDirection=${sortDirection}` : '';

  return await api.get(
    `/bookings?offset=${offset}&limit=${limit}${sortByInfo}${sortDirectionInfo}`,
    await getAuthHeader(),
  );
};

export const handleGetBookingItem = async (id: string) => {
  const api = getAPI();
  return await api.get(`/bookings?id=${id}`, await getAuthHeader());
};

export const handleCancelBooking = async (id: number) => {
  const api = getAPI();
  return await api.post(`/bookings?id=${id}`, await getAuthHeader());
};

export const handleGetBookingsByDay = async (date: Date) => {
  const api = getAPI();
  return await api.get(`/bookings?date=${date}`, await getAuthHeader());
};

export const handleGetBookingsSummary = async () => {
  const api = getAPI();
  return await api.get(`/bookings/summary`, await getAuthHeader());
};

export const handleChangePayer = async (
  bookingId: number,
  priceItemId: number,
  assigned: number,
) => {
  const api = getAPI();
  return await api.post(
    `/bookings?id=${bookingId}&priceItemId=${priceItemId}&=assigned=${assigned}`,
    await getAuthHeader(),
  );
};
