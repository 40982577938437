import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_SM } from '../../constants/screenSize';

const StyledContainer = styled((props) => <Box {...props} />)`
  border-radius: 10px;

  .AccordionDetails {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .Agreements {
    margin: 20px 0;
    display: flex;
    align-items: center;
  }

  .AccordionItemsWrapper {
    box-shadow: none;
  }

  .IconWrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }

  .Typography {
    margin-left: 20px;
  }

  .Typography2 {
    margin-left: 15px;
  }

  @media (max-width: ${SCREEN_SM}px) {
    .Typography {
      margin-left: 5px;
    }

    .Typography2 {
      margin-left: 1px;
    }
  }
`;

export { StyledContainer };
