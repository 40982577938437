import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Card from '../Card';
import { StyledSearchFilter } from './index.styled';
import type { MobileSearchFilterProps } from './types';

function MobileSearchFilter({ handleAddFilter }: MobileSearchFilterProps) {
  const { t } = useTranslation();

  return (
    <StyledSearchFilter onClick={handleAddFilter}>
      <Card padding="0.6rem 1rem">
        <TuneOutlinedIcon />
        <Typography>{t('search.filterBy')}</Typography>
      </Card>
    </StyledSearchFilter>
  );
}
export default MobileSearchFilter;
