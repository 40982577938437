import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StyledForgetPasswordContainer } from './index.styled';
import Card from '../../components/Card';
import { AuthContext } from '../../contexts/AuthContext';
import { isValidEmail } from '../../utils/validationUtils';
import Logo from '../../assets/images/logo.png';
import { FeedbackMsg } from '../../contexts/types';
import { AUTH_ROUTE } from '../../constants/routes';

function ForgetPasswordContainer() {
  const { sendResetPasswordRequest } = useContext(AuthContext);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [resetActionFeedback, setResetActionFeedback] = useState<FeedbackMsg>({
    severity: 'success',
    text: '',
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setLoading(true);
    const response = await sendResetPasswordRequest(email);
    if (response.error) setResetActionFeedback({ severity: 'error', text: response.error });
    else setResetActionFeedback({ severity: 'success', text: t('login.requestSentSuccessfully') });
    setLoading(false);
  };

  return (
    <StyledForgetPasswordContainer>
      <Card>
        <img className="CompanyLogo" src={Logo} alt="Company logo" />
        {resetActionFeedback?.text && (
          <Box className="AlertWrapper">
            <Alert severity={resetActionFeedback.severity}>{resetActionFeedback.text}</Alert>
          </Box>
        )}
        <TextField
          error={emailError}
          value={email}
          helperText={emailError && t('errors.wrongMail')}
          onBlur={() => setEmailError(!isValidEmail(email))}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          label={t('login.email')}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        />
        <LoadingButton
          variant="contained"
          fullWidth
          size="large"
          onClick={handleResetPassword}
          disabled={emailError}
          className="ResetButton"
          loading={loading}
        >
          {t('login.resetPassword')}
        </LoadingButton>
        <Button variant="text" onClick={() => navigate(AUTH_ROUTE.LOGIN)}>
          {t('general.cancel')}
        </Button>
      </Card>
    </StyledForgetPasswordContainer>
  );
}

export default ForgetPasswordContainer;
