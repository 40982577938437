import { useContext } from 'react';
import { Grid, Box, Typography, Link } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { OverviewItem } from './index.styled';
import Card from '../../Card';
import { SearchContext } from '../../../contexts/SearchContext';
import { setDateFormat } from '../../../utils/dataUtils';
import { ImageNotSupportedOutlined } from '@mui/icons-material';

function CarResume() {
  const { t } = useTranslation();
  const { selectedResult } = useContext(SearchContext);

  if (!selectedResult) return null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Card padding="1.5rem">
          <Grid container columnSpacing={3} className="CarMenuContainer">
            <Grid item sm={12}>
              <OverviewItem>
                <Box className="CarResumeTitles">
                  <Typography variant="h6">
                    {selectedResult.car.model.brand.name} {selectedResult.car.model.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {`${t('general.category')} ${selectedResult.car.model.category.name}`}
                  </Typography>
                </Box>
                <Grid container spacing={3} className="CarMenuRows">
                  <Grid item xs={2} sm={12} md={12} lg={5} className="CarImageWrapper">
                    <Box>
                      {selectedResult.car.model.imagePath ? (
                        <img
                          src={selectedResult.car.model.imagePath}
                          alt={selectedResult.car.model.name}
                          className="CarImage"
                          loading="lazy"
                        />
                      ) : (
                        <ImageNotSupportedOutlined className="NoCarPicture" />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={10} sm={12} md={12} lg={7}>
                    <Box className="CarDatesContainer">
                      <Box>
                        <Typography variant="body2" className="CarDates">
                          <ArrowForwardIcon className="ArrowIcon ArrowForward" />
                          {setDateFormat(selectedResult.fromDate, 'DD/MM/YYYY hh:mm')}
                        </Typography>
                        <Typography variant="body2" className="CarDates">
                          <Link>{selectedResult.deliveryPlace.name}</Link>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2" className="CarDates">
                          <ArrowBackIcon className="ArrowIcon arrowBack" />
                          {setDateFormat(selectedResult.toDate, 'DD/MM/YYYY hh:mm')}
                        </Typography>
                        <Typography variant="body2" className="CarDates">
                          <Link>{selectedResult.returnPlace.name}</Link>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </OverviewItem>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CarResume;
