import { Box } from '@mui/material';
import styled from 'styled-components';

const TitleWrapper = styled(Box)`
  margin-bottom: 2rem;
`;

const ContentWrapper = styled(Box)`
  max-width: 1200px;

  .CardComponent > div:first-child {
    margin-bottom: 2.5rem;
  }

  .Subtitle {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .ChangePassword-Btn {
    margin-top: 10px;
  }

  .Select {
    margin-top: 8px;
  }

  .AlertText {
    margin-bottom: 2rem;
  }
`;

export { TitleWrapper, ContentWrapper };
