import { Button } from '@mui/material';
import styled from 'styled-components';

const StyledSearchFilter = styled(Button)`
  &.MuiButton-root {
    width: 100%;
    margin-top: 7px;
    padding: 0;
  }

  .CardComponent {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  svg {
    width: 17px;
    margin-right: 10px;
  }
`;

export { StyledSearchFilter };
