import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledErrorPageContainer, ErrorWrapper } from './index.styled';
import Astronaut from '../../assets/images/astronaut';
import Logo from '../../assets/images/logo.png';

function ErrorPageContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledErrorPageContainer>
      <Astronaut />
      <ErrorWrapper>
        <Box className="LogoWrapper">
          <img src={Logo} alt="Rently" />
          <Typography className="Title">404</Typography>
        </Box>
        <Typography className="Subtitle">{t('errorPage.title')}</Typography>
        <Typography className="Paragraph">{t('errorPage.description')}</Typography>
        <Button variant="contained" onClick={() => navigate('/')}>
          {t('errorPage.returnToHome')}
        </Button>
      </ErrorWrapper>
    </StyledErrorPageContainer>
  );
}

export default ErrorPageContainer;
