import { Box, Badge, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import type { HeaderProps } from './types';
import { StyledHeader, StyledRightMenuWrapper } from './index.styled';
import AccountMenu from './AccountMenu';
import Logo from '../../assets/images/logo.png';
import SearchBar from './SearchBar';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

function Header({ handleDrawerToggle }: HeaderProps) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <StyledHeader position="fixed">
      <Toolbar className="Toolbar">
        {isAuthenticated && (
          <>
            {/* Mobile Drawer toggler */}
            <IconButton
              className="MenuIcon"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            {/* Content */}
          </>
        )}
        {/* Logo */}
        <Link to="/">
          <img src={Logo} alt="Company logo" className="AppLogo" />
        </Link>
        {isAuthenticated && (
          <>
            {/* SearchBar Component */}
            <SearchBar />

            {/* Right Menu */}
            <StyledRightMenuWrapper>
              <AccountMenu />
              <Box className="NotificationsWrapper">
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="default"
                  className="IconButton"
                >
                  <Badge badgeContent={10} variant="dot" overlap="circular" color="primary">
                    <NotificationsNoneIcon className="HoverIcon" />
                  </Badge>
                </IconButton>
              </Box>
            </StyledRightMenuWrapper>
          </>
        )}
      </Toolbar>
    </StyledHeader>
  );
}
export default Header;
