import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledSearchForm = styled(Box)`
  width: 100%;
  margin-top: 10px;

  button {
    padding: 0.4rem 0.5rem;
  }

  .MuiGrid-container {
    align-items: center;
  }

  .CardComponent {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .Places {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .DateWrapper {
    margin-top: 10px;
    font-size: 0.9rem;
  }

  .Hours {
    font-weight: 500;
  }

  .DotSeparator {
    padding: 0 14px;
  }

  .ArrowIcon {
    width: 15px;
    position: relative;
    top: 6px;
    margin-right: 10px;

    &.ArrowForward {
      fill: ${({ theme: { palette } }) => palette.success.main};
    }

    &.ArrowBack {
      fill: ${({ theme: { palette } }) => palette.primary.main};
    }
  }
`;

export { StyledSearchForm };
