import { BookingItem } from './BookingContext';

export const mockBookingConfiguration = {
  driverAgeAllowedMin: 18,
  driverAgeAllowedMax: 90,
  minDays: 2,
  maxDays: 365,
  gasolineTankFractions: 8,
  returnToleranceInMinutes: 61,
  halfDayToleranceInHours: 8,
  minDaysToCharge: 1,
  driverAgeWithoutCautionMin: 21,
  driverAgeWithoutCautionMax: 90,
  requireDriverLicenseForDelivery: true,
  useDriverAgeAllowed: true,
  cutsByDistance: [],
};

export const priceItems = [
  {
    id: 0,
    isBookingPrice: true,
    description: 'Reserva por 1 días ($ 19.291,10 x día)',
    price: 19291.1,
    type: 0,
    typeId: 0,
    unitPrice: 19291.1,
    quantity: 1,
    assigned: 1,
  },
  {
    id: 1,
    isBookingPrice: false,
    description: 'Entrega en Buenos Aires Aeroparque',
    price: 0.0,
    type: 3,
    typeId: 0,
    unitPrice: 0.0,
    quantity: 1,
    assigned: 1,
  },
  {
    id: 2,
    isBookingPrice: false,
    description: 'Devolución en Buenos Aires Aeroparque',
    price: 0.0,
    type: 11,
    typeId: 0,
    unitPrice: 0.0,
    quantity: 1,
    assigned: 1,
  },
  {
    id: 3,
    isBookingPrice: false,
    description: '(1) Cargo de Aeropuerto (Precio variable por Reserva)',
    price: 2893.66,
    type: 1,
    typeId: 58,
    unitPrice: 2893.66,
    quantity: 1,
    assigned: 0,
  },
  {
    id: 4,
    isBookingPrice: false,
    description: 'Seguro Plus Cover',
    price: 2893.66,
    type: 16,
    typeId: 58,
    unitPrice: 123.66,
    quantity: 1,
    assigned: 0,
  },
  {
    id: 5,
    isBookingPrice: false,
    description: 'PAYNOW20',
    price: -3858.22,
    type: 2,
    typeId: 89,
    unitPrice: -3858.22,
    quantity: 1,
    assigned: 0,
  },
  {
    id: 6,
    isBookingPrice: false,
    description: 'Iva Diarias (21,00 %)',
    price: 3240.9,
    type: 15,
    typeId: 2,
    unitPrice: 3240.9,
    quantity: 1,
    assigned: 0,
  },
  {
    id: 7,
    isBookingPrice: false,
    description: 'Iva Adicionales (21,00 %)',
    price: 607.67,
    type: 15,
    typeId: 4,
    unitPrice: 607.67,
    quantity: 1,
    assigned: 0,
  },
];

export const mockPrices = {
  priceItems,
  total: 17000,
  customer: {
    total: 12000,
    payed: 0,
    balance: 0,
  },
  agency: {
    total: 5000,
    payed: 0,
    balance: 5000,
  },
};

export const mockSearchResult = [
  {
    car: {
      id: null,
      model: {
        description: 'Volksgawen Suran 5 ptas',
        imagePath:
          'https://rently.blob.core.windows.net/kompass/CarModel/c39574b6-0829-4a80-bae8-1198b0509c1d.jpg',
        franchise: 700.0,
        franchiseDamage: 0.0,
        franchiseRollover: 0.0,
        franchiseTheft: 0.0,
        brand: { name: 'Volkswagen' },
        doors: 5,
        passengers: 0,
        bigLuggage: 2,
        smallLuggage: 3,
        steering: null,
        gearbox: 'Automatic',
        multimedia: null,
        airConditioner: null,
        dailyPrice: 0.0,
        id: 25,
        name: 'Suran 5',
        category: {
          id: 3,
          name: 'Large',
          imagePath: null,
          principalModelId: null,
          franchise: 0.0,
          franchiseDamage: 0.0,
          franchiseRollover: 0.0,
          franchiseTheft: 0.0,
        },
        sipp: 'EDAV',
      },
    },
    category: {
      id: 3,
      name: 'Large',
      imagePath: null,
      principalModelId: null,
      franchise: 0.0,
      franchiseDamage: 0.0,
      franchiseRollover: 0.0,
      franchiseTheft: 0.0,
    },
    fromDate: '2023-01-16T09:00:00Z',
    toDate: '2023-01-20T09:00:00Z',
    deliveryPlace: {
      id: 168,
      price: 0.0,
      name: 'Ministro Pistarini Airport',
      category: 'Airport',
      serviceType: 'MeetAndGreet',
      address: 'AU Tte. Gral. Pablo Riccheri Km 33,5',
      city: 'Buenos Aires',
      country: 'AR',
      order: 0.0,
      branchOfficeId: 168,
      branchOfficeName: null,
      branchOfficeIATACode: 'EZE',
      availableReturnPlaces: null,
      pickupInstructions: null,
    },
    returnPlace: {
      id: 168,
      price: 0.0,
      name: 'Ministro Pistarini Airport',
      category: 'Airport',
      serviceType: 'MeetAndGreet',
      address: 'AU Tte. Gral. Pablo Riccheri Km 33,5',
      city: 'Buenos Aires',
      country: 'AR',
      order: 0.0,
      branchOfficeId: 168,
      branchOfficeName: null,
      branchOfficeIATACode: 'EZE',
      availableReturnPlaces: null,
      pickupInstructions: null,
    },
    totalDaysString: '4 Días',
    price: 383.33,
    franchise: 0.0,
    totalDays: 4.0,
    unlimitedKm: true,
    hasFranchiseModifiers: false,
    willLeaveCountry: null,
    averageDayPrice: 75.2,
    priceItems: [
      {
        id: 0,
        isBookingPrice: true,
        description: 'Reserva por 4 días (USD 75,20 x día)',
        price: 300.8,
        type: 0,
        typeId: 0,
        unitPrice: 75.2,
        quantity: 4,
        assigned: 0,
      },
      {
        id: 1,
        isBookingPrice: false,
        description: 'Entrega en Aeropuerto Int. Ezeiza',
        price: 0.0,
        type: 3,
        typeId: 0,
        unitPrice: 0.0,
        quantity: 1,
        assigned: 0,
      },
      {
        id: 2,
        isBookingPrice: false,
        description: 'Devolución en Aeropuerto Int. Ezeiza',
        price: 0.0,
        type: 11,
        typeId: 0,
        unitPrice: 0.0,
        quantity: 1,
        assigned: 0,
      },
      {
        id: 3,
        isBookingPrice: false,
        description: '(1) Protección obligatoria (USD 4,00 por Día) x 4 Días',
        price: 16.0,
        type: 16,
        typeId: 16,
        unitPrice: 4.0,
        quantity: 4,
        assigned: 0,
      },
      {
        id: 4,
        isBookingPrice: false,
        description: 'IVA (21,00 %)',
        price: 66.53,
        type: 15,
        typeId: 3,
        unitPrice: 66.53,
        quantity: 1,
        assigned: 0,
      },
    ],
    availablePromotions: [],
    additional: [
      {
        additional: {
          id: 16,
          name: 'Protección obligatoria',
          description: 'Ptroección Básica Obligatoria',
          isPriceByDay: true,
          price: 909.09,
          maxQuantityPerBooking: 1,
          type: 'Insurance' as any,
        },
        quantity: 1,
      },
    ],
    currency: 'USD',
    onlyQuote: false,
    maxAllowedDistance: 0,
    maxAllowedDistanceByDay: 0,
    createdAt: '2016-06-30',
  },
  {
    car: {
      id: null,
      model: {
        description: 'Nissan Versa V-drive AT',
        imagePath:
          'https://rently.blob.core.windows.net/kompass/CarModel/6c05972c-9121-4969-a0c6-55d2d8a86478.PNG',
        franchise: 700.0,
        franchiseDamage: 0.0,
        franchiseRollover: 0.0,
        franchiseTheft: 0.0,
        brand: { name: 'Nissan' },
        doors: 4,
        passengers: 0,
        bigLuggage: 2,
        smallLuggage: 2,
        steering: null,
        gearbox: null,
        multimedia: null,
        airConditioner: null,
        dailyPrice: 0.0,
        id: 24,
        name: 'Versa',
        category: {
          id: 2,
          name: 'Medium',
          imagePath: null,
          principalModelId: null,
          franchise: 0.0,
          franchiseDamage: 0.0,
          franchiseRollover: 0.0,
          franchiseTheft: 0.0,
        },
        sipp: 'GDAV',
      },
    },
    category: {
      id: 2,
      name: 'Medium',
      imagePath: null,
      principalModelId: null,
      franchise: 0.0,
      franchiseDamage: 0.0,
      franchiseRollover: 0.0,
      franchiseTheft: 0.0,
    },
    fromDate: '2023-01-16T09:00:00Z',
    toDate: '2023-01-20T09:00:00Z',
    deliveryPlace: {
      id: 168,
      price: 0.0,
      name: 'Ministro Pistarini Airport',
      category: 'Airport',
      serviceType: 'MeetAndGreet',
      address: 'AU Tte. Gral. Pablo Riccheri Km 33,5',
      city: 'Buenos Aires',
      country: 'AR',
      order: 0.0,
      branchOfficeId: 168,
      branchOfficeName: null,
      branchOfficeIATACode: 'EZE',
      availableReturnPlaces: null,
      pickupInstructions: null,
    },
    returnPlace: {
      id: 168,
      price: 0.0,
      name: 'Ministro Pistarini Airport',
      category: 'Airport',
      serviceType: 'MeetAndGreet',
      address: 'AU Tte. Gral. Pablo Riccheri Km 33,5',
      city: 'Buenos Aires',
      country: 'AR',
      order: 0.0,
      branchOfficeId: 168,
      branchOfficeName: null,
      branchOfficeIATACode: 'EZE',
      availableReturnPlaces: null,
      pickupInstructions: null,
    },
    totalDaysString: '4 Días',
    price: 437.1,
    franchise: 0.0,
    totalDays: 4.0,
    unlimitedKm: true,
    hasFranchiseModifiers: false,
    willLeaveCountry: null,
    averageDayPrice: 86.31,
    priceItems: [
      {
        id: 0,
        isBookingPrice: true,
        description: 'Reserva por 4 días (USD 86,31 x día)',
        price: 345.24,
        type: 0,
        typeId: 0,
        unitPrice: 86.31,
        quantity: 4,
        assigned: 0,
      },
      {
        id: 0,
        isBookingPrice: false,
        description: 'Entrega en Aeropuerto Int. Ezeiza',
        price: 0.0,
        type: 3,
        typeId: 0,
        unitPrice: 0.0,
        quantity: 1,
        assigned: 0,
      },
      {
        id: 0,
        isBookingPrice: false,
        description: 'Devolución en Aeropuerto Int. Ezeiza',
        price: 0.0,
        type: 11,
        typeId: 0,
        unitPrice: 0.0,
        quantity: 1,
        assigned: 0,
      },
      {
        id: 0,
        isBookingPrice: false,
        description: '(1) Protección obligatoria (USD 4,00 por Día) x 4 Días',
        price: 16.0,
        type: 16,
        typeId: 16,
        unitPrice: 4.0,
        quantity: 4,
        assigned: 0,
      },
      {
        id: 0,
        isBookingPrice: false,
        description: 'IVA (21,00 %)',
        price: 75.86,
        type: 15,
        typeId: 3,
        unitPrice: 75.86,
        quantity: 1,
        assigned: 0,
      },
    ],
    availablePromotions: [],
    additional: [
      {
        additional: {
          id: 16,
          name: 'Protección obligatoria',
          description: 'Ptroección Básica Obligatoria',
          isPriceByDay: true,
          price: 909.09,
          maxQuantityPerBooking: 1,
          type: 'Insurance' as any,
        },
        quantity: 1,
      },
    ],
    currency: 'USD',
    onlyQuote: false,
    maxAllowedDistance: 0,
    maxAllowedDistanceByDay: 0,
  },
];

const customer = {
  id: 1,
  globalId: '1',
  firstName: 'Pedro',
  lastName: 'Lopez',
  documentId: '23.456.221',
  documentTypeId: 1,
  emailAddress: 'pedrolopez@gmail.com',
  cellPhone: '15-122-3356',
  address: 'Av Cabildo',
  addressNumber: 456,
  addressDepartment: '',
  zipCode: '',
  country: 'Argentina',
  birthDate: new Date(),
  city: 'CABA',
  state: '',
  region: '',
  taxPayer: 0,
  commercialAgreements: '',
  pricesAgreements: '',
  currency: '',
  notes: 'Realizar promociones por viajes frecuentes',
  files: [
    {
      name: 'Licencia de conducir-Martinez.png',
      content:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQith_EtXOmhiVos0Hw6lC4DZtUSwjLkDYUSQ&usqp=CAU',
      type: '',
    },
    {
      name: 'Licencia de conducir-Martinez2.png',
      content:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQith_EtXOmhiVos0Hw6lC4DZtUSwjLkDYUSQ&usqp=CAU',
      type: '',
    },
  ],
  driverLicense: '1234',
  licenseExpirationDate: new Date(),
  licenseIssueByCountry: '',
  licenseIssueByState: '',
};

const operator = {
  id: 1,
  globalId: '1',
  firstName: 'Carlos',
  lastName: 'Bermejo',
  emailAddress: 'carlosbermejo@gmail.com',
  status: 1,
  active: true,
};

const item = {
  id: 1248,
  balance: 105,
  totalPayed: 0,
  extra: '',
  promotion: 0,
  isQuotation: false,
  isOnRequest: false,
  customer,
  car: {
    id: 12,
    model: {
      description: 'Volksgawen Suran 5 ptas',
      imagePath:
        'https://rently.blob.core.windows.net/kompass/CarModel/c39574b6-0829-4a80-bae8-1198b0509c1d.jpg',
      franchise: 700.0,
      franchiseDamage: 0.0,
      franchiseRollover: 0.0,
      franchiseTheft: 0.0,
      brand: { name: 'Volkswagen' },
      doors: 5,
      passengers: 0,
      bigLuggage: 2,
      smallLuggage: 3,
      steering: null,
      gearbox: null,
      multimedia: null,
      airConditioner: null,
      dailyPrice: 0.0,
      id: 25,
      name: 'Suran 5 or Similar',
      category: {
        id: 3,
        name: 'Large',
        imagePath: null,
        principalModelId: null,
        franchise: 0.0,
        franchiseDamage: 0.0,
        franchiseRollover: 0.0,
        franchiseTheft: 0.0,
      },
      sipp: 'EDAV',
    },
  },
  category: {
    id: 3,
    name: 'Large',
    imagePath: null,
    principalModelId: null,
    franchise: 0.0,
    franchiseDamage: 0.0,
    franchiseRollover: 0.0,
    franchiseTheft: 0.0,
  },
  fromDate: '2023-01-09T09:00:00Z',
  toDate: '2023-01-13T09:00:00Z',
  deliveryPlace: {
    id: 168,
    price: 0.0,
    name: 'Ministro Pistarini Airport',
    category: 'Airport',
    serviceType: 'MeetAndGreet',
    address: 'AU Tte. Gral. Pablo Riccheri Km 33,5',
    city: 'Buenos Aires',
    country: 'AR',
    order: 0.0,
    branchOfficeId: 168,
    branchOfficeName: null,
    branchOfficeIATACode: 'EZE',
    availableReturnPlaces: null,
    pickupInstructions: null,
  },
  returnPlace: {
    id: 168,
    price: 0.0,
    name: 'Ministro Pistarini Airport',
    category: 'Airport',
    serviceType: 'MeetAndGreet',
    address: 'AU Tte. Gral. Pablo Riccheri Km 33,5',
    city: 'Buenos Aires',
    country: 'AR',
    order: 0.0,
    branchOfficeId: 168,
    branchOfficeName: null,
    branchOfficeIATACode: 'EZE',
    availableReturnPlaces: null,
    pickupInstructions: null,
  },
  totalDaysString: '4 Días',
  price: 383.33,
  franchise: 0.0,
  totalDays: 4.0,
  unlimitedKm: true,
  hasFranchiseModifiers: false,
  willLeaveCountry: null,
  averageDayPrice: 75.2,
  priceItems: [
    {
      id: 0,
      isBookingPrice: true,
      description: 'Reserva por 4 días (USD 75,20 x día)',
      price: 300.8,
      type: 0,
      typeId: 0,
      unitPrice: 75.2,
      quantity: 4,
    },
    {
      id: 0,
      isBookingPrice: false,
      description: 'Entrega en Aeropuerto Int. Ezeiza',
      price: 0.0,
      type: 3,
      typeId: 0,
      unitPrice: 0.0,
      quantity: 1,
    },
    {
      id: 0,
      isBookingPrice: false,
      description: 'Devolución en Aeropuerto Int. Ezeiza',
      price: 0.0,
      type: 11,
      typeId: 0,
      unitPrice: 0.0,
      quantity: 1,
    },
    {
      id: 0,
      isBookingPrice: false,
      description: '(1) Protección obligatoria (USD 4,00 por Día) x 4 Días',
      price: 16.0,
      type: 16,
      typeId: 16,
      unitPrice: 4.0,
      quantity: 4,
    },
    {
      id: 0,
      isBookingPrice: false,
      description: 'IVA (21,00 %)',
      price: 66.53,
      type: 15,
      typeId: 3,
      unitPrice: 66.53,
      quantity: 1,
    },
  ],
  availablePromotions: [],
  additional: [
    {
      additional: {
        id: 16,
        name: 'Protección obligatoria',
        description: 'Ptroección Básica Obligatoria',
        isPriceByDay: true,
        price: 909.09,
        maxQuantityPerBooking: 1,
        type: 'Insurance' as any,
      },
      quantity: 1,
    },
    {
      additional: {
        id: 60,
        name: 'Silla de bebé (1-3 años)',
        description:
          'Silla especial para bebés entre 1 y 3 años\r\nDisponible en todas las oficinas, el cliente deberá solicitar la silla acorde a la edad y altura de los niños. En caso que el cliente devuelva el vehículo en otra ciudad también se adicionara costo de drop off por este adicional',

        isPriceByDay: true,
        price: 1089.0,
        dailyPrice: 900.0,
        maxQuantityPerBooking: 5,
        type: 'Additional' as any,
      },
      quantity: 1,
    },
    {
      additional: {
        id: 31,
        name: 'Booster (4-10 años)',
        description: 'Silla especial para niños de 4 a 10 años',
        isPriceByDay: true,
        price: 1089.0,
        dailyPrice: 900.0,
        maxQuantityPerBooking: 3,
        type: 'Additional' as any,
      },
      quantity: 1,
    },
    {
      additional: {
        id: 32,
        name: 'Conductor Joven',
        description:
          'Si tenés entre 18 y 20 años podés alquilar un auto con Hertz. Este servicio tiene cargo adicional.',
        isPriceByDay: true,
        price: 2420.0,
        dailyPrice: 2000.0,
        maxQuantityPerBooking: 7,
        type: 'Additional' as any,
      },
      quantity: 1,
    },
  ],
  currency: 'USD',
  onlyQuote: false,
  maxAllowedDistance: 0,
  maxAllowedDistanceByDay: 0,
  franchiseDamage: 10000,
  franchiseRollover: 10000,
  franchiseTheft: 10000,
  currentStatus: 0,
  deliveryTransportationId: '',
  returnTransportationId: '',
  isCustomerOver25: true,
  externalSystemId: '',
  promotionCode: '',
  exchangeRate: 5.7,
  agentName: 'Carlos Perez',
  agentId: '1234',
  createdAt: '2016-06-30',
  priceInformation: mockPrices,
  commercialAgreement: 'Agreement 1',
  notes: 'Lorem ipsum dolor sit amet',
};

const bookingListResults = [
  item,
  { ...item, id: 1455, currentStatus: 2 },
  { ...item, id: 2553, currentStatus: 5 },
  { ...item, id: 2533, currentStatus: 4 },
  { ...item, id: 1553, currentStatus: 0 },
  { ...item, id: 8991, currentStatus: 1 },
  { ...item, id: 7125, currentStatus: 1 },
  { ...item, id: 7892, currentStatus: 1 },
  { ...item, id: 1277, currentStatus: 3 },
  { ...item, id: 6574, currentStatus: 3 },
] as BookingItem[];

export const mockBookingList = {
  limit: 12,
  offset: 0,
  total: 10,
  results: bookingListResults,
};

export const mockAdditionalList = [
  {
    id: 33,
    name: 'Conductor Adicional',
    description:
      'Persona autorizada para conducir el vehículo alquilado aparte del conductor principal. Disponible en todas las oficinas',
    isPriceByDay: true,
    price: 484.0,
    dailyPrice: 400.0,
    maxQuantityPerBooking: 7,
    type: 'Additional' as any,
    availableStock: 2147483647,
    currency: 'ARS',
    isRequired: false,
    isDefault: false,
    excludes: [],
  },
  {
    id: 58,
    name: 'Cargo de Aeropuerto',
    description: 'Cargo por retiro o devolución en Aeropuerto',
    isPriceByDay: false,
    price: 1548.91,
    dailyPrice: 1280.09,
    maxQuantityPerBooking: 1,
    type: 'Additional' as any,
    availableStock: 2147483647,
    currency: 'ARS',
    isRequired: true,
    isDefault: false,
    excludes: [],
  },
  {
    id: 119,
    name: 'PLUS COVER',
    description: 'Cobertura que reduce 100% la franquicia por daños de colisión',
    isPriceByDay: false,
    price: 1445.65,
    dailyPrice: 1194.75,
    maxQuantityPerBooking: 1,
    type: 'Insurance' as any,
    availableStock: 2147483647,
    currency: 'ARS',
    isRequired: false,
    isDefault: false,
    excludes: [23, 26, 28, 29, 30, 91, 92, 93, 94, 95, 96, 101, 107, 108, 110, 111, 120],
  },
  {
    id: 120,
    name: 'FULL COVER',
    description: 'Reduce 100% la franquicia por daños de colisión y vuelco',
    isPriceByDay: false,
    price: 2168.47,
    dailyPrice: 1792.12,
    maxQuantityPerBooking: 1,
    type: 'Insurance' as any,
    availableStock: 2147483647,
    currency: 'ARS',
    isRequired: false,
    isDefault: false,
    excludes: [23, 26, 28, 29, 30, 57, 91, 92, 93, 94, 95, 96, 101, 103, 107, 108, 110, 111, 119],
  },
  {
    id: 60,
    name: 'Silla de bebé (1-3 años)',
    description:
      'Silla especial para bebés entre 1 y 3 años\r\nDisponible en todas las oficinas, el cliente deberá solicitar la silla acorde a la edad y altura de los niños. En caso que el cliente devuelva el vehículo en otra ciudad también se adicionara costo de drop off por este adicional',

    isPriceByDay: true,
    price: 1089.0,
    dailyPrice: 900.0,
    maxQuantityPerBooking: 5,
    type: 'Additional' as any,
    availableStock: 2147483647,
    currency: 'ARS',
    isRequired: false,
    isDefault: false,
    excludes: [],
  },
  {
    id: 31,
    name: 'Booster (4-10 años)',
    description: 'Silla especial para niños de 4 a 10 años',
    isPriceByDay: true,
    price: 1089.0,
    dailyPrice: 900.0,
    maxQuantityPerBooking: 3,
    type: 'Additional' as any,
    availableStock: 2147483647,
    currency: 'ARS',
    isRequired: false,
    isDefault: true,
    excludes: [],
  },
  {
    id: 32,
    name: 'Conductor Joven',
    description:
      'Si tenés entre 18 y 20 años podés alquilar un auto con Hertz. Este servicio tiene cargo adicional.',
    isPriceByDay: true,
    price: 2420.0,
    dailyPrice: 2000.0,
    maxQuantityPerBooking: 7,
    type: 'Additional' as any,
    availableStock: 2147483647,
    currency: 'ARS',
    isRequired: false,
    isDefault: false,
    excludes: [],
  },
];

export const mockSearchBarResults = {
  bookings: [mockBookingList?.results?.[0]],
  customers: [mockBookingList?.results?.[0].customer],
};

const commissionItem = {
  id: 1,
  status: 0,
  date: new Date(),
  createdBy: 'Pedro Lopez',
  bookingId: 122,
  currency: 'USD',
  amount: 2200,
  commission: 200,
};

const commissionListResults = [
  commissionItem,
  { ...commissionItem, id: 2, status: 1 },
  { ...commissionItem, id: 3, status: 2 },
  { ...commissionItem, id: 4, status: 0 },
  { ...commissionItem, id: 5, status: 0 },
  { ...commissionItem, id: 6, status: 2 },
  { ...commissionItem, id: 7, status: 0 },
  { ...commissionItem, id: 8, status: 0 },
  { ...commissionItem, id: 9, status: 1 },
  { ...commissionItem, id: 10, status: 2 },
];

export const mockCommissionList = {
  limit: 12,
  offset: 0,
  total: 10,
  results: commissionListResults,
};

export const mockCommissionSummary = {
  historicalTotal: 143232,
  monthlyTotal: 24565,
  cashed: 111342,
  receivable: 13742,
  bookingsMonthlyTotal: 143,
};

const customers = [
  customer,
  { ...customer, id: 2 },
  { ...customer, id: 3 },
  { ...customer, id: 4 },
];

export const mockCustomerList = {
  limit: 12,
  offset: 0,
  total: 4,
  results: customers,
};

export const mockProfile = {
  globalId: '123',
  firstName: 'Jesica',
  lastName: 'Rodriguez',
  email: 'jrodriguez@rental.com',
  emailNotifications: false,
};

const operators = [
  operator,
  { ...operator, id: 2, status: 0, active: false },
  { ...operator, id: 3, status: 2, active: false },
  { ...operator, id: 4, status: 1, active: true },
  { ...operator, id: 5, status: 0, active: true },
];

export const mockOperatorList = {
  limit: 12,
  offset: 0,
  total: 4,
  results: operators,
};

export const mockBookingsSummary = {
  summary: [
    {
      month: 'Enero',
      total: 50,
    },
    {
      month: 'Febrero',
      total: 70,
    },
    {
      month: 'Marzo',
      total: 89,
    },
    {
      month: 'Abril',
      total: 350,
    },
    {
      month: 'Mayo',
      total: 500,
    },
    {
      month: 'Junio',
      total: 1240,
    },
    {
      month: 'Julio',
      total: 976,
    },
    {
      month: 'Agosto',
      total: 1050,
    },
    {
      month: 'Septiembre',
      total: 50,
    },
    {
      month: 'Octubre',
      total: 941,
    },
    {
      month: 'Noviembre',
      total: 563,
    },
    {
      month: 'Diciembre',
      total: 748,
    },
  ],
};
