import { AppBar, Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_SM, SCREEN_MD, SCREEN_LG, SCREEN_XL, SCREEN_XXL } from '../../constants/screenSize';

const StyledHeader = styled(AppBar)`
  &.MuiPaper-root {
    box-shadow: none;
    background: ${({ theme: { palette } }) => palette.background.paper};
    color: ${({ theme: { palette } }) => palette.text.primary};
    border-bottom: ${({ theme }) => `1px solid ${theme.extra.border}`};
    z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  }

  .MenuIcon {
    display: none;
  }

  .AppLogo {
    width: 140px;
    padding-top: 4px;
  }

  .Toolbar {
    width: 92%;
    margin: auto;
    padding-left: 65px;
    padding-right: 0;
    display: flex;
    justify-items: flex-end;
    justify-content: space-between;
  }

  .NotificationsWrapper {
    &:hover {
      background: none;
    }
  }

  .HoverIcon {
    margin-right: 5px;
    &:hover {
      color: ${({ theme: { palette } }) => palette.primary.main};
      background: none;
      transition: 0.7s;
    }
  }

  @media (max-width: ${SCREEN_SM}px) {
    .AppLogo {
      width: 100px;
      margin: 0 2% 0 2%;
      padding-top: 6%;
    }

    .Toolbar {
      min-height: 48px;
      width: 92%;
      margin: auto;
      padding-left: 50px;
      padding-right: 0px;
    }

    .MenuIcon {
      display: flex;
      position: absolute;
      left: 10px;
    }
  }
  @media (min-width: ${SCREEN_SM}px) and (max-width: ${SCREEN_MD}px) {
    .AppLogo {
      width: 135px;
    }

    .Toolbar {
      width: 90%;
      padding-left: 30px;
    }

    .MuiTextField-root {
      margin-left: 20px;
    }
  }

  @media (min-width: ${SCREEN_MD}px) and (max-width: ${SCREEN_LG}px) {
    .AppLogo {
      width: 140px;
      margin: 0px 12px 0px -9px;
    }
  }

  @media (min-width: ${SCREEN_XL}px) {
    .AppLogo {
      width: 150px;
    }
  }

  @media (min-width: ${SCREEN_XXL}px) {
    .Toolbar {
      width: 95%;
    }
  }
`;

const StyledRightMenuWrapper = styled(Box)`
  display: flex;
  align-items: center;
  text-align: center;

  .MuiButtonBase-root {
    padding: 0px;
  }

  @media (min-width: ${SCREEN_SM}px) and (max-width: ${SCREEN_MD}px) {
    margin-left: 20px;
  }
`;

export { StyledHeader, StyledRightMenuWrapper };
