import { AxiosError } from 'axios';
import { createContext, useState } from 'react';
import {
  handleGetCommissions,
  handleGetCommissionItem,
  handleGetCommissionSummary,
} from '../actions/actions.commission';
import { mockCommissionList, mockCommissionSummary } from './dummy';
import type { APIResponse, AscDesc, PaginatedData } from './types';

export enum CommissionStatusEnum {
  Failed = 0,
  Payed = 1,
  Process = 2,
}

export interface CommissionItem {
  id: number;
  status: CommissionStatusEnum;
  date: Date;
  createdBy: string;
  bookingId: number;
  currency: string;
  amount: number;
  commission: number;
}

export interface CommissionSummary {
  historicalTotal: number;
  monthlyTotal: number;
  bookingsMonthlyTotal: number;
  cashed: number;
  receivable: number;
}

export interface CommissionList extends PaginatedData {
  results: CommissionItem[];
}

interface ContextProps {
  commissionList: CommissionList | null;
  commissionSummary: CommissionSummary | null;
  getCommissions: (
    offset: number,
    limit: number,
    sortBy: string | null,
    sortDirection: AscDesc | null,
    fromDate: Date | null,
    toDate: Date | null,
    status: CommissionStatusEnum,
  ) => Promise<APIResponse>;
  getCommissionSummary: () => void;
  getCommissionItem: (id: string) => Promise<APIResponse>;
}

export const CommissionContext = createContext({} as ContextProps);

export const CommissionProvider = ({ children }: { children: JSX.Element }) => {
  const [commissionList, setCommissionList] = useState<CommissionList | null>(mockCommissionList);
  const [commissionSummary, setCommissionSummary] = useState<CommissionSummary | null>(
    mockCommissionSummary,
  );

  const getCommissions = async (
    offset: number,
    limit: number,
    sortBy: string | null,
    sortDirection: AscDesc | null,
    fromDate: Date | null,
    toDate: Date | null,
    status: CommissionStatusEnum,
  ): Promise<APIResponse> => {
    try {
      const response = (
        await handleGetCommissions(offset, limit, sortBy, sortDirection, fromDate, toDate, status)
      )?.data;
      if (response.error) return { error: response.error };
      else {
        setCommissionList(response.data);
        return { error: '' };
      }
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const getCommissionItem = async (id: string): Promise<APIResponse> => {
    try {
      const response = (await handleGetCommissionItem(id))?.data;
      if (response.error) return { error: response.error };
      else return { data: response.data, error: '' };
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const getCommissionSummary = async (): Promise<APIResponse> => {
    try {
      const response = (await handleGetCommissionSummary())?.data;
      if (response.error) return { error: response.error };
      else {
        setCommissionSummary(response.data);
        return { error: '' };
      }
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  return (
    <CommissionContext.Provider
      value={{
        commissionList,
        commissionSummary,
        getCommissions,
        getCommissionItem,
        getCommissionSummary,
      }}
    >
      {children}
    </CommissionContext.Provider>
  );
};
