import { CircularProgress } from '@mui/material';
import React from 'react';
import { StyledLoadingScreen } from './index.styled';

function LoadingScreen() {
  return (
    <StyledLoadingScreen>
      <CircularProgress />
    </StyledLoadingScreen>
  );
}
export default LoadingScreen;
