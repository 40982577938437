import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

import { StyledBookingError } from './index.styled';
import Card from '../../components/Card/index';
import SidebarLayout from '../../components/SidebarLayout';

function BookingErrorContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const message = queryParams.get('message');

  return (
    <SidebarLayout>
      <StyledBookingError>
        <Card padding="2.5rem 1.5rem 5rem">
          <Box className="TitleWrapper">
            <Typography>{t('bookingError.title')}</Typography>
          </Box>
          <Box className="SubtitleWrapper">
            <ErrorOutline />
            <Typography>{message || t('bookingError.message')}</Typography>
          </Box>
        </Card>
        <Box className="ButtonsContainer">
          <Button variant="contained" onClick={() => navigate('/')}>
            {t('bookingError.homeButton')}
          </Button>
        </Box>
      </StyledBookingError>
    </SidebarLayout>
  );
}

export default BookingErrorContainer;
