import { getAPI } from '../API';
import type { NewBookingItem, SearchParams, SelectedAdditional } from '../contexts/SearchContext';
import { parseParamsIntoString } from '../utils/searchUtils';
import { getAuthHeader } from '../utils/storageUtils';

export const fetchPrices = async (
  params: SearchParams,
  modelId: number,
  additional: SelectedAdditional[],
) => {
  const api = getAPI();
  const stringParams = parseParamsIntoString(params, modelId);

  let serializedAdditional = '';
  for (let i = 0; i < additional.length; i++) {
    const item = additional[i];
    serializedAdditional += `additionals[${i}].Additional.Id=${item.id}&additionals[${i}].Quantity=${item.number}&`;
  }

  return await api.get(
    `/booking/price?${stringParams}&${serializedAdditional}`,
    await getAuthHeader(),
  );
};

export const fetchAdditionalList = async (
  params: SearchParams,
  modelId: number,
  categoryId: number,
) => {
  const api = getAPI();
  const stringParams = parseParamsIntoString(params);

  return await api.get(
    `/booking/additional-list?${stringParams}&modelId=${modelId}&categoryId=${categoryId}`,
    await getAuthHeader(),
  );
};

export const getSearchResults = async (params: SearchParams) => {
  const api = getAPI();
  const stringParams = parseParamsIntoString(params);
  return await api.get(`/booking/search?${stringParams}`, await getAuthHeader());
};

export const createBooking = async (params: NewBookingItem) => {
  const api = getAPI();
  return await api.post('/booking/book', params, await getAuthHeader());
};

export const handleSearchCustomerByEmail = async (email: string) => {
  const api = getAPI();
  return await api.get(`/customer?email=${email}`, await getAuthHeader());
};

export const handleGetSearchBarResults = async (keyword: string) => {
  const api = getAPI();
  return await api.get(`/searchbar?keyword=${keyword}`, await getAuthHeader());
};
