import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableEmpty from './TableEmpty';
import TableError from './TableError';
import TableLoading from './TableLoading';
import TablePaginationActions from './TablePaginationActions';
import { StyledCustomTable } from './index.styled';
import type { TablePaginationActionsProps } from './TablePaginationActions/types';
import type { TableContainerProps } from './types';

function CustomTable({
  headers,
  sortColumn,
  sortDirection,
  emptyTable,
  loading,
  error,
  tableContent,
  count,
  rowsPerPage,
  page,
  emptyTableText,
  colSpan,
  onPageChange,
  onRowsPerPageChange,
  handleRetry,
  handleSortColumn,
}: TableContainerProps) {
  const { t } = useTranslation();

  const getTableContentComponent = () => {
    if (loading) return <TableLoading colSpan={colSpan} />;

    if (error) return <TableError colSpan={colSpan} error={error} onClick={handleRetry} />;

    if (emptyTable) {
      return <TableEmpty text={emptyTableText} colSpan={colSpan} />;
    }

    return tableContent;
  };

  return (
    <StyledCustomTable component={Paper}>
      <Table size="medium" padding="normal">
        <TableHead>
          <TableRow>
            {headers.map((item) => {
              return (
                <TableCell
                  className="TableHeaderCell"
                  key={item.id}
                  onClick={() => handleSortColumn(item.id)}
                >
                  {item.name}
                  <TableSortLabel active={sortColumn === item.id} direction={sortDirection} />
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{getTableContentComponent()}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              ActionsComponent={(props: TablePaginationActionsProps) => (
                <TablePaginationActions {...props} />
              )}
              rowsPerPageOptions={[5, 10, 25, { label: t('general.all'), value: -1 }]}
              colSpan={colSpan}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('general.rowsPerPage')}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows-per-page',
                },
                native: true,
              }}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledCustomTable>
  );
}
export default CustomTable;
