import { useContext } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useTheme } from 'styled-components';
import { CalendarMonth, LocalOfferOutlined } from '@mui/icons-material';
import SidebarLayout from '../../components/SidebarLayout';
import SearchForm from '../../components/SearchForm';
import FutureBookings from '../../components/FutureBookings';
import { HomeRightContainer, GridContainer } from './index.styled';
import { DASHBOARD_ROUTE, SEARCH_ROUTE } from '../../constants/routes';
import { SearchContext } from '../../contexts/SearchContext';
import { buildSearchRouteWithParams, setMoneyFormat } from '../../utils/dataUtils';
import DataCard from '../../components/DataCard';
import { CommissionContext } from '../../contexts/CommissionContext';

function HomeContainer() {
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { searchParams, handleSearch } = useContext(SearchContext);
  const { commissionSummary } = useContext(CommissionContext);

  const getSearchResults = async () => {
    await handleSearch(searchParams!);

    navigate({
      pathname: SEARCH_ROUTE.SEARCH,
      search: buildSearchRouteWithParams(searchParams!),
    });
  };

  return (
    <SidebarLayout>
      <GridContainer container spacing={5}>
        <Grid item lg={6} xl={4}>
          <Typography variant="h2" className="HomeTitle">
            {t('home.title')}
          </Typography>
          <SearchForm getSearchResults={getSearchResults} />
        </Grid>
        <HomeRightContainer item lg={6} xl={8}>
          <Typography variant="h2" className="HomeTitle">
            {t('home.secondTitle')}
          </Typography>
          <Grid container spacing={{ xs: 2, md: 4 }} className="SummaryWrapper">
            {commissionSummary?.monthlyTotal && (
              <Grid item xs={12} sm={12} md={6}>
                <DataCard
                  amount={setMoneyFormat(commissionSummary.monthlyTotal)}
                  title={t('monthlyOverview.totalCommissionsLabel')}
                  icon={<LocalOfferOutlined />}
                  iconColor={theme.palette.success.main}
                  link={DASHBOARD_ROUTE.COMMISSIONS}
                />
              </Grid>
            )}

            {commissionSummary?.bookingsMonthlyTotal && (
              <Grid item xs={12} sm={12} md={6}>
                <DataCard
                  amount={`${commissionSummary?.bookingsMonthlyTotal}`}
                  title={t('monthlyOverview.totalBookingsLabel')}
                  icon={<CalendarMonth />}
                  iconColor={theme.palette.primary.main}
                  link={DASHBOARD_ROUTE.BOOKINGS}
                />
              </Grid>
            )}
          </Grid>
          <FutureBookings />
        </HomeRightContainer>
      </GridContainer>
    </SidebarLayout>
  );
}
export default HomeContainer;
