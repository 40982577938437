import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_LG, SCREEN_MD } from '../../constants/screenSize';

const SummaryWrapper = styled((props) => <Grid {...props} />)`
  &.MuiGrid-root {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  @media (min-width: ${SCREEN_LG}px) {
    .SummaryItem {
      max-width: 300px;
    }
  }
`;

const TableWrapper = styled((props) => <Box {...props} />)`
  .CommissionValue {
    color: ${({ theme: { palette } }) => palette.primary.main};
  }

  .TableHeaderCell:first-child,
  .MuiTableCell-body:first-child {
    padding-left: 40px;
  }
`;

const FilterWrapper = styled((props) => <Box {...props} />)`
  margin-bottom: 1.5rem;

  .CardComponent {
    display: flex;
    padding: 1.4rem 3.5rem;
  }

  .StatusFilter {
    margin-top: 8px;
  }

  .FilterIcon {
    width: calc(3.5rem + 24px);
    display: flex;
    align-items: center;
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  @media (max-width: ${SCREEN_LG}px) {
    .FilterIcon {
      display: none;
    }
  }

  @media (max-width: ${SCREEN_MD}px) {
    .CardComponent {
      padding: 1.5rem 1.2rem;
    }
  }
`;

export { SummaryWrapper, TableWrapper, FilterWrapper };
