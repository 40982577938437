import { Grid } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_MD } from '../../constants/screenSize';

const GridContainer = styled((props) => <Grid {...props} />)`
  padding-bottom: 5rem;

  .HomeTitle {
    margin-bottom: 1.3rem;
  }
`;

const HomeRightContainer = styled((props) => <Grid {...props} />)`
  .SummaryWrapper {
    margin-bottom: 2rem;
  }

  @media (min-width: ${SCREEN_MD}px) {
    &.MuiGrid-root {
      max-width: 700px;
    }
  }

  @media (max-width: ${SCREEN_MD}px) {
    .SummaryWrapper {
      margin-bottom: 1rem;
    }
  }
`;

export { HomeRightContainer, GridContainer };
