import styled from 'styled-components';

const CustomMenuItem = styled((props) => <li {...props} />)`
  display: flex;

  .Item-Name {
    font-size: 1rem;
    color: ${({ theme: { palette } }) => palette.text.primary};
  }

  .Item-City {
    font-size: 0.8rem;
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }

  .MuiSvgIcon-root {
    color: ${({ theme: { palette } }) => palette.primary.main};
    margin-right: 15px;
    width: 20px;
  }
`;

export { CustomMenuItem };
