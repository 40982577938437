import dayjs from 'dayjs';
import { createSearchParams } from 'react-router-dom';
import { SearchParams } from '../contexts/SearchContext';

export const buildSearchRouteWithParams = (params: SearchParams) => {
  return createSearchParams({
    dateFrom: `${setDateFormat(params.dateFrom, 'DD-MM-YYYY')}`,
    dateTo: `${setDateFormat(params.dateTo, 'DD-MM-YYYY')}`,
    hourFrom: params.hourFrom,
    hourTo: params.hourTo,
    dropOffPlaceId: `${params.dropOffPlaceId}`,
    pickUpPlaceId: `${params.pickUpPlaceId}`,
    unlimitedKm: `${params.unlimitedKm}`,
    ...(params.driverAge && {
      driverAge: params.driverAge,
    }),
    ...(params.promotionCode && {
      promotionCode: params.promotionCode,
    }),
    ...(params.commercialAgreement && {
      commercialAgreement: params.commercialAgreement,
    }),
  }).toString();
};

export const convertToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const setDateFormat = (date: Date | string, format = 'DD/MM/YYYY') => {
  if (!date) return null;
  const lang = null; // TODO: handle user language
  const local = lang || 'en';
  return dayjs(date).locale(local).format(format);
};

export const setMoneyFormat = (price: number, decimals = 2) => {
  if (typeof price === 'number') {
    const int = 'en-US';
    const newPrice = price.toLocaleString(int, {
      minimumFractionDigits: decimals,
    });
    return '$' + newPrice;
  } else return '';
};

export const numberToString = (num?: number) => {
  if (typeof num === 'number') {
    return `${num}`;
  }
  return '';
};
