const Astronaut = () => (
  <svg xmlns="astronaut" viewBox="0 0 2204.11 809.55" className="#team-spaceman">
    <defs>
      <linearGradient
        id="b"
        x1={548.36}
        y1={346.38}
        x2={2205.23}
        y2={346.38}
        gradientTransform="matrix(1 0 0 -1 0 841.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dadae5" />
        <stop offset={0.26} stopColor="#aaaac1" />
        <stop offset={0.57} stopColor="#aaaac1" />
        <stop offset={1} stopColor="#dadae5" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={9789.17}
        y1={268.05}
        x2={9789.17}
        y2={454.47}
        gradientTransform="matrix(-1 0 0 1 10036.97 83.16)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={0.5} stopColor="#fff" stopOpacity={0.4} />
        <stop offset={0.98} stopColor="#fff" stopOpacity={0.1} />
      </linearGradient>
    </defs>
    <path
      d="m867.5 323.27-92.24 50.86L688 422.27a55.39 55.39 0 0 1-53.49-97L664.24 309 717 279.93l61.76-34.11-22.7-117.27a55.36 55.36 0 0 1 108.54-21.86l.15.77 30.48 156.81a55.49 55.49 0 0 1-27.73 59Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="m895.15 264.18-20.69-106.67c-.81 1.41-7.37 12.61-25.43 20.28 7.67 41.68 15.44 83 16.65 88.41 2.52 11 7.57 18.57-12.61 29.17-17.76 9.28-78.52 44.63-78.52 44.63s-10.09-18.17-23.72-31c-18.77-17.66-46.62-22-46.62-22l-2.83 1.51-4.44 2.42-4.23 2.36L664.25 309c6.26 16 20.59 32.5 35.42 38.25 19.88 7.67 52.28 19.07 75.69 26.84l92.24-50.83a55.43 55.43 0 0 0 27.55-59.08Z"
      style={{
        fill: '#aaaac1',
      }}
    />
    <path
      d="M883 78.49 861.63 86l12.92 71.45a.31.31 0 0 1-.1.2l-9.69-50.16a55.63 55.63 0 0 0-27-37.64c-9.39 4-6.76 8.88-5.35 16.65.81 4.64 8.78 48.24 16.75 91.33a92.56 92.56 0 0 1-25.73 6.56C783.67 189 766 178.9 766 178.9L743.81 58.4l143.47-39.55 31-8.58S934.81 58.61 883 78.49Z"
      style={{
        fill: '#aaaac1',
      }}
    />
    <path
      d="M883 78.49 861.63 86l12.92 71.45s-6.26 12.21-25.43 20.49c-8-43.19-16-86.79-16.75-91.33-1.61-9.08-5-14.13 11.2-18.67C885 57.34 881.41 36.65 879.9 30.6l7.57-11.6 31-8.58c-.21-.15 16.34 48.19-35.47 68.07Z"
      style={{
        fill: '#7e7e99',
      }}
    />
    <path
      d="M878.7 45.39a273.79 273.79 0 0 1-40.37 14.88C790.2 74.15 748 74 744 60.33c-3.78-13.06 28.41-33.71 73-47.84 0 0 1.21-.3 2.42-.71 1.51-.5 3-.91 4.54-1.31 48.14-13.93 90.42-13.93 94.36-.2 2.68 9.49-13.48 23.11-39.62 35.12Z"
      style={{
        fill: '#5f5f77',
      }}
    />
    <path
      d="M765.67 391.85c-.91-69.33-68.52-119.79-134.32-98.09-1.62.51 134.32 98.09 134.32 98.09Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="M761.13 497.71 532 594.9s-34.51-15.24-49.45-50.56c-22.4-52.88-25-134.63 7-149.26 11.1-5.15 22.81-3.33 34.92-8.58 49.15-21.39 104.65-43.09 149.16-54.4 46.12-11.71 91.84-.81 116.26 34.11a58.41 58.41 0 0 1 8.11 24.06c1.31 13.62 10.7 93.15 10.7 93.15Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M799.18 428s-13.73-31.29-52-20.08c-42.39 12.41-32.7 36.13-92 59.44-21.18 8.38-50.06 20.08-77.61 31.64v-.1c-22.71 9.69-29.57 3.73-35.62-4.64a238.41 238.41 0 0 1-13-20.28l-39.06 16s-11.71 18.87-4.14 37.54 20.69 33.1 20.69 33.1l15.14 20.08a43.7 43.7 0 0 0 2.72 3.73l8.17 10.5 228.68-97.19 47.43-14.23Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="M727.52 384.48a11.38 11.38 0 0 1-7.17 10.8l-242.2 99.41a7 7 0 0 1-9.69-6.36l-.2-34.31a6.71 6.71 0 0 0-2.93-5.55l-105.66-74.2a6.91 6.91 0 0 1 1.41-12.11L602.48 263a11.57 11.57 0 0 1 11 1.21l109 76.5a11.52 11.52 0 0 1 4.84 9.18Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M727.52 384.48a11.38 11.38 0 0 1-7.17 10.8L514 480l-26.84 11-1.31-58.84L518 419.09l193.42-79.42a12 12 0 0 1 11 1.21 11.81 11.81 0 0 1 4.84 9.18Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="m529 473.9-41.78 17.16a176.32 176.32 0 0 0-1.41-58.84L529 414.45Z"
      style={{
        fill: '#aaaac1',
      }}
    />
    <path
      d="M485.42 432.42a175.72 175.72 0 0 0-61.06-96.28l23.82-9.79c28.56 10.9 70.64 51.77 80.94 88.1Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="M1070.35 205.55s-66.61 186.1-72.16 199.11-14.43 24.83-46.93 35.42c-10.8 3.53-143.51 43.7-143.51 43.7l-14.62-79s-27.46-30.68-3.38-38.56L902 332.1l59.85-154.51s21.09-38.15 69.84-24a78.77 78.77 0 0 1 13.93 5.35c32.8 16.64 24.73 46.61 24.73 46.61Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M1175.61 127.54s-11.4 51.46-66.61 41.58l-22.4-4.74-26.14 68.52s-15.14 9.28-41.48 2.62a92.78 92.78 0 0 1-16.85-6.06c-36.73-16.75-46.83-34.72-46.83-34.72L999.1 79l133.62 36.73Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="M799 428c-9.69 17.36-7.67 44.1 8.28 55.91l1.31-.4L951.21 440c32.4-10.6 41.38-22.4 46.93-35.42s72.16-199.11 72.16-199.11.3-17.26-32.4-33.81c-15 43.5-63.07 174.39-68.63 194.07-6.36 22.51-13.83 23.92-21.19 26.24C943 393.66 799 428 799 428Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="M1175.61 127.54s-11.4 51.46-66.61 41.58l-22.4-4.74-26.14 68.52s-14.53 11-44.91 1.72c15.54-44.4 32.19-82.25 34.11-87.4 3.94-10.19 8.78-15.14 25.94-9.39s25.94 7.77 31-12.11c1.72-6.86 12.61-9.49 26-9.89Z"
      style={{
        fill: '#aaaac1',
      }}
    />
    <ellipse
      cx={1087.41}
      cy={103.09}
      rx={19.88}
      ry={91.63}
      transform="rotate(-74.93 1087.41 103.09)"
      style={{
        fill: '#7e7e99',
      }}
    />
    <path
      d="M589.47 698.85a43 43 0 0 1-28.86 27.75l-135.14 38.55-33.61 9.59c-3.94 1.11-36.63-78.52-23.62-82.25l102.44-29.22h.2l5.65-1.61-.1-.1-61.36-80-.1-.1c-14.23-18.67 3.63-26.84 22.4-41.18l37-20 102 130.83 6.46 8.28a42.9 42.9 0 0 1 6.64 39.46Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M302.15 711.56c-2.83.91-5 1.82-6.26 2.32-11.61 5.65-18.17 15.74-18.17 27.65a14.86 14.86 0 0 0 14.84 14.84 15.4 15.4 0 0 0 3-.3 14.83 14.83 0 0 0 11.81-14.53c1.24-1.27 1.54-32.1-5.22-29.98Z"
      style={{
        fill: '#aaaac1',
      }}
    />
    <path
      d="M425.47 765.15c-.1.2-.2.2-.2.2l-29.06 8.38c-20.79 5.85-22.51 13.52-22.51 20.39 0 7.17.3 11-9.39 14a29.1 29.1 0 0 1-9.08 1.31c-4.54 0-9.28-1.92-16.15-6.36-15.24-9.89-28.36-22.71-33.2-27.55-5.15-5.15-9.28-10.39-10.39-19.58a42 42 0 0 1-.3-5.25c0-5.25.2-6.76.2-16 0-8.07.91-16.75 6.66-23.21 3.23-3.53 7.87-6.46 14.73-8.28 14.13-3.63 33.71-8.28 67.52-15.54.3-.1.61-.1.91-.2 0 0 34.72 2.32 44.61 29.67a19.93 19.93 0 0 1 .81 2.62c8.06 27.74-3.44 43.25-5.16 45.4Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="M430.52 719.27c39.66-11.5 90-23.62 120.6-31.69 25.23-6.66 28.76-21.9 25.23-36.43l6.46 8.28a42.84 42.84 0 0 1-22.2 67.21l-135.14 38.51c-.1.2-1.31-2.12-1.31-2.12Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="M366.23 736.89c-11.4 3.23-19.21 8.18-19.21 19.58s.1 32.9.1 32.9-.71 18 8.07 20.18a29.52 29.52 0 0 0 9.08-1.31c9.69-3 9.39-6.76 9.39-14 0-6.86 1.61-14.53 22.51-20.39l29.06-8.38.2-.2c1.72-2.12 13.22-17.66 5.25-45.21a6.43 6.43 0 0 1-.2-.71Z"
      style={{
        fill: '#aaaac1',
      }}
    />
    <path
      d="m490 489.94-74.78 91.43c0 .1 61.36 80.13 61.36 80.13l13.12-4a14.48 14.48 0 0 0 7-23.11l-11.2-16c-11.1-15.84-27.25-40.47-13.32-57a25.64 25.64 0 0 1 12.21-7.87c9.18-2.83 16.75-.1 22.2 7.17 0 0 6.76-19.28 6.76-40.37C513.27 499 490 489.94 490 489.94ZM31.78 564.22a42.24 42.24 0 0 0 24.62 7.87 44.5 44.5 0 0 0 13.12-2 42.86 42.86 0 0 0 21.9-16l58.43-82.65L222.41 495l64.39 20.79a42.81 42.81 0 1 0 26.35-81.47l-166.82-54a42.76 42.76 0 0 0-48.14 16l-76.7 108.26a42.85 42.85 0 0 0 10.29 59.64Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="m154.81 435.45 101.73 31.08s-14.13 11.91-34.21 28.26l-72.57-23.52-27.45 39.06c0-2.22-.61-18-15.74-32.7 9.59-12.72 19.58-25.94 26.34-35a20.12 20.12 0 0 1 21.9-7.18Z"
      style={{
        fill: '#aaaac1',
      }}
    />
    <path
      d="M0 547.37v12.82c0 15.63 6.54 25.32 18 25.32h56.57a31.6 31.6 0 0 0 8.28-1.11c8.15-2.13 14.84-10.4 14.84-21.59 0-2.52-.4-4-.3-8 .1-6.66-.5-7.57 5.55-17.06a4.39 4.39 0 0 0 .4-.71s1.61-2.22 3.83-5.25l15.24-21.5c0-2.22-.61-18-15.74-32.7a62.38 62.38 0 0 0-9-7.27c-25.63-17.46-55.61 4-55.61 4C20.26 501.87 21.57 500 12.89 512 1.31 528 0 535.15 0 547.37Z"
      style={{
        fill: '#aaaac1',
      }}
    />
    <path
      d="M74.07 580.67c4.95 4.34 12.41 3.63 17.06-1 3.94-3.94 6.56-9.79 6.56-17.06 0-2.52-.4-3.94-.3-7.87.1-6.66-.5-7.47 5.55-17 .1-.2.3-.4.4-.61 0 0 1.61-2.22 3.83-5.25l15.24-21.5c0-2.22-.61-18-15.74-32.7-12.52 16.7-24.53 32.59-28.26 37.19-8.78 11-7.67 16.15-7.67 33.3-.2 12.1-4.34 25.74 3.33 32.5Z"
      style={{
        fill: '#7e7e99',
      }}
    />
    <path
      d="M123.12 567.15a13.2 13.2 0 0 0 10.5-11l1.11-8.07c0-.3.1-.61.1-1a34.54 34.54 0 0 0-10.81-27c-7.57-7.06-17.86-10-29-8.48a13.2 13.2 0 0 0 3.73 26.14c4.84-.71 6.76 1.11 7.37 1.61a8.44 8.44 0 0 1 2.52 5.55l-1.06 7.33a13.25 13.25 0 0 0 11.2 15 12.54 12.54 0 0 0 4.34-.08Z"
      style={{
        fill: '#7e7e99',
      }}
    />
    <path
      d="M446.56 475.81a153.79 153.79 0 0 1-81.14 135.46A165.1 165.1 0 0 1 196 356.73a152.57 152.57 0 0 1 96.88-34.51c84.76-.11 153.68 68.78 153.68 153.59Z"
      style={{
        fill: '#1a0029',
      }}
    />
    <path
      d="M292.86 300.82a177.07 177.07 0 0 0-42.29 5.18 165.16 165.16 0 0 1 182.17 274.7 174 174 0 0 0 35-105c.12-96.37-78.4-174.88-174.88-174.88Z"
      style={{
        fill: '#babacc',
      }}
    />
    <path
      d="M292.86 322.11A152.57 152.57 0 0 0 196 356.63a168.24 168.24 0 0 1 21.05-25.36 164.35 164.35 0 0 1 33.51-25.33 176.44 176.44 0 0 1 42.29-5.15c96.48 0 175 78.52 175 175a174 174 0 0 1-35 105 162 162 0 0 1-67.41 30.58 153.79 153.79 0 0 0 81.16-135.43c-.04-84.94-68.96-153.83-153.74-153.83Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="M183.47 452.27c0 78.41 73.27 146 144.11 146 28.36 0 55.3-4.44 77.91-18.17 38.65-45.31 44.1-85.18 40.07-123.53-3.83-25.73-15.84-71.85-66.91-108.29-46.73-31.79-98.4-30.17-136.14-17.76-38.51 27.12-59.04 71.32-59.04 121.75Z"
      style={{
        opacity: 0.2199999988079071,
        isolation: 'isolate',
        fill: 'url(#linear-gradient)',
      }}
    />
    <path
      d="M232.21 404s-11.3 52-14.73 26.84S224 395 224 395Z"
      style={{
        fill: '#1a0029',
      }}
    />
    <path
      d="M282.47 583a3.63 3.63 0 0 0 1.11.2 14.12 14.12 0 0 0 3 .1h.2a12.87 12.87 0 0 0 1.92-.1 3.79 3.79 0 0 0 1.11-.1c2.52-.2 5.55-.5 8.78-.91h.3l4-.61h.2c10.7-1.72 23.62-4.34 34-6.66 19.38-4.24 41.68-9.18 58.33-42.69.2-.3.3-.61.5-.91a10.84 10.84 0 0 0 .71-1.51c6-11.5 13.73-27.15 16-40.57a112.4 112.4 0 0 0 1.72-15.64c2.83-56-23.41-77.71-67.62-85-.4-.1-.81-.1-1.21-.2-7-1-14.33-1.82-22.3-2.32-19.78-1.11-53 81.64-58.23 140.38-.1.71-.1 1.51-.2 2.22-2 28.07 2.34 50.07 17.68 54.32Z"
      style={{
        fill: '#422c4f',
      }}
    />
    <path
      d="M215.26 506.69a14.41 14.41 0 0 0 .71 3.83c.61 1.72 1.21 3.63 2 5.75 4.24 11.91 11.2 29.57 18.67 40.37 16.75 24.22 31.49 25.73 46.93 26.54 1 0 2 .1 3 .1h.2a12.22 12.22 0 0 0 1.92-.1 3.79 3.79 0 0 0 1.11-.1q4.39-.3 8.78-.91h.3c1.31-.2 2.72-.5 4-.81h.2c40.57-8.07 75.49-41.78 83-86.08.3-1.82.61-3.63.81-5.45.3-2.22.5-4.54.61-6.86 2-38.25-14.33-72.86-40.67-93.55a10.69 10.69 0 0 1-1.21-1 91.59 91.59 0 0 0-50.86-19.07c-17.56-1-46.83 21.39-61.76 29.47-11.1 15.34-14.53 23-17.36 37.44-2.62 12.92 0 27.35 1.92 41.68.52 3.42-3.21 18.26-2.3 28.75Z"
      style={{
        fill: '#a56d8a',
      }}
    />
    <path
      d="M243.21 416.27c28.46 3.43 80.74.1 108.19-2.12a31.71 31.71 0 0 1 8.38.5c32.8 5.85 42.29 53.89 43 75.69.3 11.4 1 18.77-.5 28.86 21.39-26.14 33.3-45.31 28.86-83-6.86-57.83-49.25-79.42-101-81.74-1.21-.1-39.16-5.75-63.88-3.53a23.59 23.59 0 0 0-4.54.71c-16.85 3.73-34.11 13.42-39.66 29.27-5.06 14.67.16 32.43 21.15 35.36Z"
      style={{
        fill: '#1a0029',
      }}
    />
    <path
      d="M241.7 416.57c26.84 2.72 76 .1 101.93-1.61h.1a6.17 6.17 0 0 0 3.63-10.7c-4.54-4.24-10.7-7.17-16.15-9-9-3.13-24.73-9.39-26.34-18.67-2.22-12.82-13.73-18.47-39.06-19.17h-.61a42.52 42.52 0 0 0-5.15.4c-27.75 3.94-39.36 17.26-40.27 33.51-.49 10.61 7.79 24.94 21.92 25.24Z"
      style={{
        fill: '#3f3249',
      }}
    />
    <path
      data-name="eye 2 bis"
      d="M318.5 502a6.85 6.85 0 0 1-5.75 6.76 3.78 3.78 0 0 1-1.11.1 6.72 6.72 0 0 1-6.76-6.68V502a7 7 0 0 1 3.23-5.85 6.57 6.57 0 0 1 3.53-.91 6.72 6.72 0 0 1 6.86 6.58Z"
      style={{
        fill: '#1a0029',
      }}
    />
    <path
      data-name="eye 2"
      d="M316 502.86a6.87 6.87 0 0 1-3.33 5.85 3.78 3.78 0 0 1-1.11.1 6.72 6.72 0 0 1-6.76-6.68V502a7 7 0 0 1 3.2-5.9 3.78 3.78 0 0 1 1.11-.1 6.74 6.74 0 0 1 6.86 6.62Z"
      style={{
        fill: '#422c4f',
      }}
    />
    <path
      data-name="eye 1 bis"
      d="M241.09 483.18a6.72 6.72 0 0 1-6.68 6.76h-.68a6.83 6.83 0 0 1-6.26-6.86 6.66 6.66 0 0 1 4.14-6.36 6.24 6.24 0 0 1 2.72-.5 6.74 6.74 0 0 1 6.76 6.72Z"
      style={{
        fill: '#1a0029',
      }}
    />
    <path
      data-name="eye 1"
      d="M238 483.48a6.66 6.66 0 0 1-4.14 6.36 6.83 6.83 0 0 1-6.26-6.84 6.66 6.66 0 0 1 4.14-6.36 6.9 6.9 0 0 1 6.26 6.84Z"
      style={{
        fill: '#422c4f',
      }}
    />
    <path
      d="m261.78 478.27-16.85 34.21a2.08 2.08 0 0 0 1.31 3l33.51 9.08a2.13 2.13 0 0 0 2.52-2.83l-16.65-43.29a2 2 0 0 0-3.84-.17Z"
      style={{
        fill: '#824d72',
      }}
    />
    <path
      d="M244.62 513.66a14.28 14.28 0 0 0 11.3 8l23.82 2.61Z"
      style={{
        fill: '#663466',
      }}
    />
    <path
      d="M313.25 467.74a9.78 9.78 0 0 1 3.83-1.51 16.57 16.57 0 0 1 5.65-.4 4.18 4.18 0 0 1 1.61.2l1.72.3a30.82 30.82 0 0 1 3.23 1.31l1.61.81a8.54 8.54 0 0 1 1.41 1 17.69 17.69 0 0 1 2.42 2.32 8.4 8.4 0 0 1 1.72 2.62 10.71 10.71 0 0 1 1 2.62 11.32 11.32 0 0 1 .57 2.26 11.15 11.15 0 0 1 .1 1.61c0 .91-.81 1.11-1.51.5a5.79 5.79 0 0 0-.81-.91 21.06 21.06 0 0 1-1.61-1.41c-.5-.5-1.11-1-1.72-1.51a18.37 18.37 0 0 0-1.92-1.41q-1-.75-2-1.41c-.3-.2-.71-.4-1-.61l-1.11-.5a23.09 23.09 0 0 0-2.32-1l-2.22-.91a21.33 21.33 0 0 0-2.32-.81 18.84 18.84 0 0 0-2.22-.71c-1.41-.5-2.62-.81-3.43-1.11-.93-.08-1.19-.63-.68-1.34ZM238 454.27a8.4 8.4 0 0 0-2.32-1.51 10.76 10.76 0 0 0-3.83-.81h-1.11a4.14 4.14 0 0 0-1.11.1 9.85 9.85 0 0 0-2.32.61l-1.11.5a4.71 4.71 0 0 0-1 .5 16.1 16.1 0 0 0-1.92 1.61 8 8 0 0 0-1.51 1.82 9.55 9.55 0 0 0-.91 1.72c-.2.71-.5 1.21-.61 1.72a8.39 8.39 0 0 0-.2 1.31c-.1.61.4.91.91.5a3.77 3.77 0 0 0 .71-.61 10.57 10.57 0 0 1 1.11-.81 11.09 11.09 0 0 1 1.41-.91 7.49 7.49 0 0 1 1.51-.81 12 12 0 0 1 1.61-.81 2.53 2.53 0 0 0 .71-.4l.81-.3a17.89 17.89 0 0 1 1.72-.61c.61-.2 1.21-.3 1.72-.5a10.69 10.69 0 0 1 1.72-.4 11.14 11.14 0 0 1 1.61-.3c1-.2 1.92-.4 2.52-.5.45.05 1.05-.26-.12-1.11Z"
      style={{
        fill: '#1a0029',
      }}
    />
    <path
      d="M418.21 499.13a13 13 0 0 1-15.55 9.14c-7.17-1.61-11.61-8.28-10.09-14.84s16.15-11.4 23.31-11.91c6.56-.46 5.56 7.41 2.33 17.61Z"
      style={{
        fill: '#422c4f',
      }}
    />
    <path
      d="M218 516.38c4.24 11.91 11.2 29.57 18.67 40.37 16.75 24.22 31.49 25.73 46.93 26.54a14.12 14.12 0 0 0 3 .1h.2a12.87 12.87 0 0 0 1.92-.1 3.79 3.79 0 0 0 1.11-.1q4.39-.3 8.78-.91h.3l4-.61h.2c10.7-1.72 23.62-4.34 34-6.66 19.38-4.24 41.68-9.18 58.33-42.69-4.84-13.22 2.12-38.75 2.12-38.75l-9.56-2.72-1.11-.81-3.62-2.77s-5 21.19-10.19 26.34c-16.85 16.75-38.35 32.9-55.3 34.11-15.74 1-27.15-9.18-38-14.53-2.32-1.11-8.07-2.72-14.63-3.53-8.58-1.11-18.67-1.11-24.22 2.83-4.14 2.83-14.33 2.32-20.69-12.82a7.2 7.2 0 0 0-2.24-3.29Z"
      style={{
        fill: '#232338',
        opacity: 0.5,
        isolation: 'isolate',
      }}
    />
    <path
      d="M255.62 539.09c3.25 3.39 8.58 2.92 12.6 1.81 2.88-.8 6.83-.28 8.17 2.83 1.21 2.81-.73 5.64-3.36 6.63-5.31 2-13.45.5-18.25-2.37-2.05-1.23-5-3.41-5.92-5.72-1.55-3.97 4.43-4.48 6.76-3.18Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="m383.19 428.08 23.21 22.3-1.92 29.47-7.27 16.15a5.44 5.44 0 0 1-7.67 2.52L385 496a5.71 5.71 0 0 1-2.52-6.26l7.67-25.23c2-6.56.5-13.83-1.11-20.59Z"
      style={{
        fill: '#1a0029',
      }}
    />
    <path
      d="m388.23 405.27 17.56 51.57-20 22.4c2.72-13.42.1-19.68-2.83-32.7-7.67-34.11-27.35-32.7-27.35-32.7Z"
      style={{
        fill: '#1a0029',
      }}
    />
    <path
      d="M188.71 408.6c16.31-43.33 45.42-56 71.31-57.33 26.24-1.31 50.26 19 53 47.73 3.13 32.09-19.88 59.34-47.94 59.34-24 0-55.51 14.63-49.55 74.78.4 4.34-4.84 6.26-6.86 2.52-37.11-68.3-26.11-110.79-19.96-127.04Z"
      style={{
        opacity: 0.699999988079071,
        isolation: 'isolate',
        fill: 'url(#a)',
      }}
    />
    <path
      style={{
        fill: '#aaaac1',
      }}
      d="m624.69 375.19-22.91 9.39-49.85-43.6 22.6-9.38 50.16 43.59z"
    />
    <path
      d="M657.49 486.21s.4-3.53.5-5.65l.3-3v-.5l.1-.71.1-1.31a54.39 54.39 0 0 0-.61-10.7 42 42 0 0 0-3.13-10.5c-.4-.81-.81-1.72-1.21-2.52s-.91-1.61-1.31-2.52a16 16 0 0 0-1.51-2.42c-.6-.91-1.11-1.61-1.61-2.42a58.84 58.84 0 0 0-7.47-8.28 90.62 90.62 0 0 0-8.07-6.66 144.69 144.69 0 0 0-6.66-4.44l24.73-5.58c.5.3 1.11.61 1.61.91a68.3 68.3 0 0 1 13 9.89c1 1 2 2 3 3.13s1.92 2.22 2.83 3.33a31.87 31.87 0 0 1 2.52 3.63c.8 1.31 1.61 2.42 2.32 3.73a57.65 57.65 0 0 1 6.26 15.84c.3 1.31.5 2.62.81 4s.3 2.62.5 3.83a24.43 24.43 0 0 1 .2 3.83c0 1.31.1 2.52 0 3.73l-.1 1.82-.1.91v.66l-.1.3-.3 3Z"
      style={{
        fill: '#dadae5',
      }}
    />
    <path
      d="M2188.33 791.89c12-1.21 17.46-13.32 15.54-25-2.72-16.45-22.3-81.74-44.4-123.63-22.91-43.19-42.59-72.46-63-98.5-20.41-26.04-69.63-82.75-134.73-112.22s-136.24-43.4-214.45-44.4c-78.21-1-160.46 8.28-232.11 21.7s-151.38 33.3-204.87 45.92c-53.49 12.62-182.46 47.84-292.67 3.83-98.37-39.38-147.01-136.32-188.69-184.32-33.51-38.55-68.63-78.11-140.48-77.81-71.85.3-111.01 33.81-125.65 59.56s-18.37 62.07-13.32 80.53c0 0 3.73 7.27 16.15 4 11.61-3 8.88-9.79 8.88-9.79s-5.85-34.57 12.49-63.49 51.27-40.37 91.33-43.7c40.06-3.33 74.88 9 109.8 45.72 14.87 15.59 51.87 64.84 82.78 107.73 33.1 45.82 88.2 90.73 134.73 109 43 17 105.86 33.2 204.87 21 79.32-9.89 216.17-48.24 268.35-58 74.78-14 156.53-25.63 230-27.45 76.19-1.92 156.43 8.48 211.33 30.78 58.43 23.82 101.53 55.1 143.2 109.8 38.45 50.46 58.94 87.5 74.18 125.34 13.73 34.21 24.22 67.52 26.34 83.76 1.19 9.95 6.84 21.46 24.4 19.64Z"
      style={{
        fill: 'url(#b)',
      }}
    />
  </svg>
);

export default Astronaut;
