import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  SCREEN_XL,
  SCREEN_LG,
  SCREEN_XXL,
  SCREEN_SM,
  SCREEN_MD,
} from '../../../constants/screenSize';

const OverviewItem = styled((props) => <Box {...props} />)`
  .AmountWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .AmountText {
    font-size: 1.7rem;
    font-weight: bold;
  }

  .ArrowIcon {
    margin-right: 10px;
  }

  .ArrowForward {
    fill: ${({ theme: { palette } }) => palette.success.main};
  }

  .ArrowBack {
    fill: ${({ theme: { palette } }) => palette.primary.main};
  }

  .CarDatesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .CarDates {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 3% 0px;
  }

  .CarOfficeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
    margin-top: 5px;
  }

  .CarMenuContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .CarMenuRows {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    text-align: end;
  }

  .CarResumeTitles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .MuiTypography-subtitle1 {
      font-weight: 500;
    }
  }

  .CarImageWrapper {
    display: flex;
    justify-content: center;

    .NoCarPicture {
      height: auto;
      width: 50px;
      color: ${({ theme: { palette } }) => palette.text.secondary};
      opacity: 0.3;
    }
  }

  .CarImage {
    max-width: 200px;
    text-align: center;
  }

  @media (min-width: ${SCREEN_SM}px) and (max-width: ${SCREEN_MD}px) {
    .CarImage {
      max-width: 200px;
    }

    .CarOfficeContainer {
      text-align: end;
    }
    .CarResumeTitles {
      .MuiTypography-subtitle1 {
        text-align: end;
      }
    }

    .CarDates {
      font-size: 0.7rem;
    }
  }
  @media (min-width: ${SCREEN_MD}px) and (max-width: ${SCREEN_LG}px) {
    .CarDates {
      font-size: 1.2rem;
    }
    .CarMenuContainer {
      max-width: 80%;
      margin: auto;
      .MuiGrid-item {
        padding: 0;
      }
    }
  }

  @media (min-width: ${SCREEN_LG}px) and (max-width: ${SCREEN_XL}px) {
    .CarImage {
      max-width: 150px;
    }

    .CarDates {
      font-size: 0.7rem;
    }

    .CarResumeTitles {
      .MuiTypography-subtitle1 {
        font-size: 0.8rem;
      }
    }
  }

  @media (min-width: ${SCREEN_XL}px) and (max-width: ${SCREEN_XXL}px) {
    min-width: 205px;
  }
`;

const NewTabButton = styled(Link)`
  border: none;
  background: transparent;
  padding: 0;

  svg {
    fill: ${({ theme: { palette } }) => palette.info.main};
    margin-top: 3px;
  }

  &:hover {
    svg {
      fill: ${({ theme: { palette } }) => palette.info.dark};
    }
  }
`;

export { OverviewItem, NewTabButton };
