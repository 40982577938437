import { Box, Grid, Select } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_XS } from '../../constants/screenSize';

const HeaderWrapper = styled((props) => <Grid {...props} />)`
  align-items: center;
  margin-bottom: 2rem;

  .RightCol {
    display: flex;
    justify-content: flex-end;
  }

  button {
    min-width: 200px;
    margin: 0 7px;
    line-height: 1;
    margin: auto 0;
  }

  @media (max-width: ${SCREEN_XS}px) {
    h1 {
      margin-bottom: 1rem;
    }

    button {
      width: 100%;
    }
  }
`;

const TableWrapper = styled((props) => <Box {...props} />)`
  .TableHeaderCell:first-child,
  .MuiTableCell-body:first-child {
    padding-left: 40px;
  }

  .MuiTableBody-root {
    .MuiTableRow-root:hover {
      cursor: pointer;
    }
  }
`;

const ActiveToggle = styled((props) => <Select {...props} />)`
  min-width: 50%;
  border: none;
  div#operator-active-select {
    padding: 0px 5rem 0px 0px;
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }
  p {
    min-width: 90px;
    font-size: 0.95rem;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export { HeaderWrapper, TableWrapper, ActiveToggle };
