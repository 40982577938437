import { useContext, useState } from 'react';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ClearOutlined, OpenInNew } from '@mui/icons-material';
import {
  Button,
  Grid,
  Typography,
  Divider,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
} from '@mui/material';

import {
  HandshakeOutlined,
  DirectionsCarFilledOutlined,
  PlaceOutlined,
  InsertInvitation,
  Today,
  GppGoodOutlined,
  SegmentOutlined,
  PermIdentityOutlined,
  SecurityOutlined,
  FavoriteBorderOutlined,
  AddCircleOutline,
} from '@mui/icons-material';
import { BookingContext, BookingItem } from '../../contexts/BookingContext';
import {
  NewTabButton,
  BookingDetailsHeader,
  BookingDetailsContent,
  ErrorWrapper,
} from './index.styled';
import { CUSTOMERS_ROUTE, DASHBOARD_ROUTE } from '../../constants/routes';
import BookingStatus from '../../components/BookingStatus';
import SidebarLayout from '../../components/SidebarLayout';
import AdditionalItem from '../../components/AdditionalItem';
import { setDateFormat } from '../../utils/dataUtils';
import Card from '../../components/Card';
import PriceSummary from '../../components/PriceSummary';
import { getQueryParam } from '../../utils/windowUtils';
import { useEffectOnMount } from '../../hooks';

function BookingsDetailsContainer() {
  const { bookingList, getBookingItem, cancelBooking, changePayer } = useContext(BookingContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorAlert, setErrorAlert] = useState<string>('');
  const [bookingItem, setBookingItem] = useState<BookingItem | null>(null);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const queryBookingId = getQueryParam('id');
  const navigate = useNavigate();

  useEffectOnMount(() => {
    const handleGetBookingItem = async () => {
      setLoading(true);
      const response = await getBookingItem(queryBookingId!);
      // if (response.error) {
      //   setError(response.error);
      // } else {
      //   setBookingItem(response.data);
      // }

      // TODO: Uncomment above code once API is ready and remove next line setBookingItem(..)
      setBookingItem(bookingList?.results?.[0]);
      setLoading(false);
    };
    handleGetBookingItem();
  });

  const getCarInformation = () => {
    const plate = bookingItem?.car?.id ? `${bookingItem?.car?.id} - ` : '';
    const carName = bookingItem?.car?.model?.name || '';
    const categoryName = bookingItem?.car?.model?.category?.name || '';
    const dash = carName && categoryName ? ' - ' : '';

    return `${plate}${carName}${dash}${categoryName}`;
  };

  const handleAddDriver = () => {
    // TODO
  };

  const handleCancelBooking = async () => {
    setShowCancelModal(false);
    setLoading(true);
    const response = await cancelBooking(bookingItem!.id);
    if (response?.error) setErrorAlert(response.error);
    else setBookingItem(response.data);
    setLoading(false);
  };

  const onChangePayer = async (priceItemId: number, assigned: number) => {
    const response = await changePayer(bookingItem!.id, priceItemId, assigned);
    if (response?.error) setErrorAlert(response.error);
    else setBookingItem(response.data);
  };

  if (loading) {
    return (
      <SidebarLayout>
        <Box className="LoadingWrapper">
          <CircularProgress color="primary" />
        </Box>
      </SidebarLayout>
    );
  }

  if (error) {
    return (
      <SidebarLayout>
        <ErrorWrapper>
          <Typography variant="body1">{error}</Typography>
          <Button variant="contained" onClick={() => navigate(DASHBOARD_ROUTE.BOOKINGS)}>
            {t('general.back')}
          </Button>
        </ErrorWrapper>
      </SidebarLayout>
    );
  }

  if (!bookingItem) {
    return (
      <SidebarLayout>
        <Box />
      </SidebarLayout>
    );
  }

  return (
    <SidebarLayout>
      {/* HEADER */}
      <BookingDetailsHeader container>
        <Dialog
          open={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          aria-labelledby="alert-dialog-cancel-confirmation"
        >
          <DialogTitle>{t('bookingDetails.cancelModal.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('bookingDetails.cancelModal.subtitle')}</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: '16px 24px' }}>
            <Button
              sx={{ minWidth: '30%', padding: '5px 20px' }}
              variant="secondary-action"
              onClick={() => setShowCancelModal(false)}
            >
              {t('general.back')}
            </Button>
            <Button
              sx={{ minWidth: '30%', padding: '5px 20px' }}
              variant="contained"
              onClick={handleCancelBooking}
              autoFocus
            >
              {t('general.cancel')}
            </Button>
          </DialogActions>
        </Dialog>

        <Grid item md={12} lg={6} className="LeftMenu">
          <Box className="TitleWrapper">
            <Typography variant="h2" className="Title">
              {`${t('bookingDetails.booking')} #${bookingItem.id} (${bookingItem.totalDaysString})`}
            </Typography>
            <Typography variant="h2" className="Separator Title">
              -
            </Typography>
            <Box className="CustomerWrapper">
              <Typography variant="h2" className="Title">
                {`${bookingItem.customer.firstName} ${bookingItem.customer.lastName}`}
              </Typography>
              <NewTabButton
                to={`${CUSTOMERS_ROUTE.CUSTOMER_DETAILS}?id=${bookingItem.customer.id}`}
              >
                <OpenInNew />
              </NewTabButton>
            </Box>
            <BookingStatus status={bookingItem.currentStatus} />
          </Box>
          <Box>
            <Divider className="Divider" light />
            <Typography variant="body2" className="CreatedBy">
              {`${t('bookingDetails.createdBy')} ${bookingItem.agentName} el ${setDateFormat(
                new Date(bookingItem.createdAt!),
                'DD/MM/YYYY',
              )}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={12} lg={6} className="RightMenu">
          <Button className="CancelButton" onClick={() => setShowCancelModal(true)}>
            {t('bookingDetails.cancelButton')}
          </Button>
        </Grid>
      </BookingDetailsHeader>
      {/* CONTENT */}
      <BookingDetailsContent container spacing={3}>
        {errorAlert && (
          <Grid item md={12}>
            <Alert severity="error" onClose={() => setErrorAlert('')}>
              {errorAlert}
            </Alert>
          </Grid>
        )}
        <Grid item sm={12} md={7} lg={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card padding="1rem 1rem">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box className="Row TitleWrapper">
                      <Box className="Row">
                        <Today className="DeliveryIcon" />
                        <Typography>{t('bookingDetails.delivery')}</Typography>
                      </Box>
                      <Box className="Row DateInfo">
                        <Typography>{setDateFormat(bookingItem.fromDate, 'DD/MM/YYYY')}</Typography>
                        <Typography className="Bold">
                          {setDateFormat(bookingItem.fromDate, 'hh:mm')}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="Row LocationText">
                      <Typography>{bookingItem.deliveryPlace.name}</Typography>
                      <PlaceOutlined />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="Row TitleWrapper">
                      <Box className="Row">
                        <InsertInvitation className="ReturnIcon" />
                        <Typography>{t('bookingDetails.return')}</Typography>
                      </Box>
                      <Box className="Row DateInfo">
                        <Typography>{setDateFormat(bookingItem.toDate, 'DD/MM/YYYY')}</Typography>
                        <Typography className="Bold">
                          {setDateFormat(bookingItem.toDate, 'hh:mm')}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="Row LocationText">
                      <Typography>{bookingItem.deliveryPlace.name}</Typography>
                      <PlaceOutlined />
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={12} sm={6} className="BookingItem-Box">
                <Card padding="1rem 1rem">
                  <Box className="ContentCol">
                    <Box className="DriverBox-Header">
                      <Box className="Title">
                        <PermIdentityOutlined />
                        <Typography variant="subtitle1" className="Typography">
                          {t('bookingDetails.driver')}
                        </Typography>
                      </Box>

                      <IconButton onClick={handleAddDriver}>
                        <AddCircleOutline />
                      </IconButton>
                    </Box>

                    <Box>
                      <Box className="DriverBox-Item">
                        <Typography>
                          {`${bookingItem.customer.firstName} ${bookingItem.customer.lastName}`}
                        </Typography>
                        <IconButton onClick={() => {}}>
                          <ClearOutlined />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} className="BookingItem-Box">
                <Card padding="1rem 1rem">
                  <Box className="SubtitleWrapper">
                    <DirectionsCarFilledOutlined />
                    <Typography variant="subtitle1" className="Typography">
                      {t('bookingDetails.vehicle')}
                    </Typography>
                  </Box>
                  <Box className="BoxDescription Small">
                    <Typography>{getCarInformation()}</Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={12} sm={6} className="BookingItem-Box">
                <Card padding="1rem 1rem">
                  <Box className="SubtitleWrapper">
                    <HandshakeOutlined />
                    <Typography>{t('bookingDetails.commercialAgreements')}</Typography>
                  </Box>
                  <Box className="BoxDescription Small">
                    <Typography variant="subtitle1">
                      <Typography>{bookingItem.commercialAgreement}</Typography>
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} className="BookingItem-Box">
                <Card padding="1rem 1rem">
                  <Box className="SubtitleWrapper">
                    <SegmentOutlined />
                    <Typography variant="subtitle1" className="Typography">
                      {t('bookingDetails.notes')}
                    </Typography>
                  </Box>
                  <Box className="BoxDescription Small">
                    <Typography>{bookingItem.notes}</Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card padding="1rem 1rem 2rem 1rem">
                <Box className="SubtitleWrapper">
                  <GppGoodOutlined />
                  <Typography variant="subtitle1" className="Typography">
                    {t('bookingDetails.insurance&additional')}
                  </Typography>
                </Box>
                <Box className="BoxDescription">
                  <Grid container spacing={3}>
                    {bookingItem.additional.map((item) => {
                      const additional = {
                        ...item.additional,
                        availableStock: 0,
                        currency: '$',
                        dailyPrice: 0,
                        excludes: [],
                        isDefault: false,
                        isRequired: false,
                      };

                      const icon =
                        additional.type === 'Insurance' ? (
                          <SecurityOutlined />
                        ) : (
                          <FavoriteBorderOutlined />
                        );

                      return (
                        <Grid
                          key={item.additional.id}
                          item
                          sm={12}
                          md={6}
                          lg={4}
                          xl={3}
                          sx={{ width: '100%' }}
                        >
                          <AdditionalItem
                            item={additional}
                            icon={icon}
                            defaultQuantity={`${item.quantity}`}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={5} lg={4}>
          <PriceSummary
            data={bookingItem.priceInformation}
            disabled={false}
            showBalance
            onChangePayer={onChangePayer}
          />
        </Grid>
      </BookingDetailsContent>
      {/* HEADER */}
    </SidebarLayout>
  );
}

export default BookingsDetailsContainer;
