import { Box, Button, Typography } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { StyledBookingConfirmation } from './index.styled';
import Card from '../../components/Card/index';
import { DASHBOARD_ROUTE } from '../../constants/routes';
import SidebarLayout from '../../components/SidebarLayout';

function BookingConfirmationContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const bookingId = queryParams.get('bookingId');

  return (
    <SidebarLayout>
      <StyledBookingConfirmation>
        <Card padding="2.5rem 1.5rem 5rem">
          <Box className="TitleWrapper">
            <Typography>{t('bookingConfirmation.bookingTitle')}</Typography>

            <Link className="BookingId" to={`${DASHBOARD_ROUTE.BOOKINGS}?id=${bookingId}`}>
              {`#${bookingId}`}
            </Link>
          </Box>
          <Box className="SubtitleWrapper">
            <CheckCircleOutlinedIcon />
            <Typography>{t('bookingConfirmation.message')}</Typography>
          </Box>
        </Card>
        <Box className="ButtonsContainer">
          <Button variant="secondary-action" onClick={() => navigate('/')}>
            {t('bookingConfirmation.homeButton')}
          </Button>
          <Button variant="contained" onClick={() => navigate(DASHBOARD_ROUTE.BOOKINGS)}>
            {t('bookingConfirmation.booksButton')}
          </Button>
        </Box>
      </StyledBookingConfirmation>
    </SidebarLayout>
  );
}

export default BookingConfirmationContainer;
