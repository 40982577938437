import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_MD } from '../../constants/screenSize';

const ErrorWrapper = styled((props) => <Box {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  max-width: 525px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  p {
    color: ${({ theme: { palette } }) => palette.text.primary};
    margin-bottom: 20px;
  }

  button {
    min-width: 150px;
  }
`;

const ContentWrapper = styled((props) => <Box {...props} />)`
  max-width: 1600px;
  color: ${({ theme: { palette } }) => palette.text.secondary};
  font-size: 1rem;

  .ContactPanel {
    margin-bottom: 2rem;
  }

  .Subtitle {
    font-size: 1.3rem;
  }

  .AccordionTitle {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  .FilesWrapper {
    margin-bottom: 2rem;
  }

  .DatePicker {
    margin-top: 0;
  }

  .UploadFiles-Btn {
    margin-bottom: 1rem;
  }

  .AlertText {
    margin-bottom: 2rem;
  }
`;

const ActionButtons = styled((props) => <Box {...props} />)`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  button {
    min-width: 200px;
    margin-left: 10px;
  }

  @media (max-width: ${SCREEN_MD}px) {
    justify-content: center;
    flex-direction: column;

    button {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`;

export { ContentWrapper, ErrorWrapper, ActionButtons };
