import { Box, Typography } from '@mui/material';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { useTranslation } from 'react-i18next';
import { StyledCommercialAgreement } from './index.styled';
import Card from '../../Card';
import type { CommercialAgreementProps } from './type';

function CommercialAgreement({ agreement }: CommercialAgreementProps) {
  const { t } = useTranslation();

  return (
    <StyledCommercialAgreement>
      <Card padding="1.5rem">
        <Box className="AgreementsTitle">
          <HandshakeOutlinedIcon />
          <Typography>{t('checkout.sideBar.commercialAgreement.title')}</Typography>
        </Box>
        <Box className="AgreementItem">
          <Typography>{agreement || ''}</Typography>
        </Box>
      </Card>
    </StyledCommercialAgreement>
  );
}

export default CommercialAgreement;
