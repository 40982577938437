import { createContext, useState } from 'react';
import {
  handleGetAttentionSchedule,
  handleGetBookingConfiguration,
  handleGetCategoryList,
  handleGetCommercialAgreementList,
  handleGetDocumentTypes,
  handleGetFiscalConditionList,
  handleGetHolidays,
  handleGetPlaces,
  handleGetRentalConfiguration,
} from '../actions/actions.rental';
import { mockBookingConfiguration } from './dummy';

import type { CarCategory, NameAndId, Place } from './types';

export interface RentalConfig {
  logo: string;
  slogan: string;
}

export interface BookingConfig {
  driverAgeAllowedMin: number;
  driverAgeAllowedMax: number;
  minDays: number;
  maxDays: number;
  gasolineTankFractions: number;
  returnToleranceInMinutes: number;
  halfDayToleranceInHours: number;
  minDaysToCharge: number;
  driverAgeWithoutCautionMin: number;
  driverAgeWithoutCautionMax: number;
  requireDriverLicenseForDelivery: boolean; // Fix spelling 'Licence' to 'License'
  useDriverAgeAllowed: boolean;
  cutsByDistance: any[];
}

export interface CommercialAgreement {
  id: number;
  code: string;
  validFrom: string;
  validTo: string;
  branchOffices: Number[];
  notes: string;
}

export interface Holiday {
  date: Date | string;
  description: string;
  ids: number[];
}

export interface PlaceAttentionSchedule {
  id: number;
  schedule: Schedule;
  timezoneId: string;
  timezoneUTCOffset: string;
  gapForBookingTime: number;
  gap: number;
  rentlyEndpointName: string;
  allowAfterHours: boolean;
  afterHoursMaxMinutes: number;
  changeOfDayWithFixedSchedule: boolean;
  checkOutTime: number | null;
}

export interface Schedule {
  monday: BusinessHours[];
  tuesday: BusinessHours[];
  wednesday: BusinessHours[];
  thursday: BusinessHours[];
  friday: BusinessHours[];
  saturday: BusinessHours[];
  sunday: BusinessHours[];
  holiday: BusinessHours[];
  scheduleDates: ScheduleDate[];
  closedDays: (Date | string)[];
}

export interface BusinessHours {
  openedFrom: string;
  openedTo: string;
}

export interface ScheduleDate {
  date: Date;
  range: BusinessHours[];
}

interface ContextProps {
  rentalConfiguration: RentalConfig | null;
  bookingConfiguration: BookingConfig;
  holidays: Holiday[];
  attentionSchedule: PlaceAttentionSchedule[];
  places: Place[];
  commercialAgreementList: CommercialAgreement[];
  categoryList: CarCategory[];
  documentTypes: NameAndId[];
  fiscalConditionList: NameAndId[];
  getRentalConfiguration: () => void;
  getBookingSettings: () => void;
}

export const RentalContext = createContext({} as ContextProps);

export const RentalProvider = ({ children }: { children: JSX.Element }) => {
  const [rentalConfiguration, setRentalConfiguration] = useState<RentalConfig | null>(null);
  const [bookingConfiguration, setBookingConfiguration] =
    useState<BookingConfig>(mockBookingConfiguration);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [attentionSchedule, setAttentionSchedule] = useState<PlaceAttentionSchedule[]>([]);
  const [places, setPlaces] = useState<Place[]>([]);
  const [commercialAgreementList, setCommercialAgreementList] = useState<CommercialAgreement[]>([]);
  const [categoryList, setCategoryList] = useState<CarCategory[]>([]);
  const [documentTypes, setDocumentTypes] = useState<NameAndId[]>([]);
  const [fiscalConditionList, setFiscalConditionList] = useState<NameAndId[]>([]);

  const getBookingConfiguration = async () => {
    try {
      const response = (await handleGetBookingConfiguration())?.data;
      if (response.error) return { error: response.error };
      setBookingConfiguration(response);
    } catch (error) {
      return { error };
    }
  };

  const getHolidays = async () => {
    try {
      const response = (await handleGetHolidays())?.data;
      if (response.error) return { error: response.error };
      setHolidays(response);
    } catch (error) {
      return { error };
    }
  };

  const getAttentionSchedule = async () => {
    try {
      const response = (await handleGetAttentionSchedule())?.data;
      if (response.error) return { error: response.error };
      setAttentionSchedule(response);
    } catch (error) {
      return { error };
    }
  };

  const getPlaces = async () => {
    try {
      const response = (await handleGetPlaces())?.data;
      if (response.error) return { error: response.error };
      setPlaces(response);
    } catch (error) {
      return { error };
    }
  };

  const getCommercialAgreements = async () => {
    try {
      const response = (await handleGetCommercialAgreementList())?.data;
      if (response.error) return { error: response.error };
      setCommercialAgreementList(response);
    } catch (error) {
      return { error };
    }
  };

  const getCategories = async () => {
    try {
      const response = (await handleGetCategoryList())?.data;
      if (response.error) return { error: response.error };
      setCategoryList(response);
    } catch (error) {
      return { error };
    }
  };

  const getDocumentTypes = async () => {
    try {
      const response = (await handleGetDocumentTypes())?.data;
      if (response.error) return { error: response.error };
      setDocumentTypes(response);
    } catch (error) {
      return { error };
    }
  };

  const getFiscalConditionList = async () => {
    try {
      const response = (await handleGetFiscalConditionList())?.data;
      if (response.error) return { error: response.error };
      setFiscalConditionList(response);
    } catch (error) {
      return { error };
    }
  };

  const getRentalConfiguration = async () => {
    try {
      const response = (await handleGetRentalConfiguration())?.data;
      if (response.error) return { error: response.error };
      setRentalConfiguration(response);
    } catch (error) {
      return { error };
    }
  };

  const getBookingSettings = async () => {
    //  await getBookingConfiguration();
    await getHolidays();
    await getAttentionSchedule();
    await getPlaces();
    await getCommercialAgreements();
    await getCategories();
    await getDocumentTypes();
    await getFiscalConditionList();
  };

  return (
    <RentalContext.Provider
      value={{
        rentalConfiguration,
        bookingConfiguration,
        holidays,
        attentionSchedule,
        places,
        commercialAgreementList,
        categoryList,
        documentTypes,
        fiscalConditionList,
        getRentalConfiguration,
        getBookingSettings,
      }}
    >
      {children}
    </RentalContext.Provider>
  );
};
