import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledResetPasswordContainer = styled((props) => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .CardComponent {
    width: 100%;
    max-width: 670px;
    margin: auto;
    text-align: center;
  }

  .CompanyLogo {
    width: 180px;
    height: auto;
    margin-bottom: 2rem;
  }

  .InputsWrapper {
    margin-bottom: 1.2rem;
  }

  .MuiFormControl-root {
    margin-bottom: 0.7rem;
  }

  .ResetButton {
    margin-bottom: 2rem;
  }

  .ErrorWrapper {
    margin-bottom: 2rem;
  }
`;

export { StyledResetPasswordContainer };
