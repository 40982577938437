import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledCommissionStatus from './index.styled';
import type { CommissionStatusProps } from './types';

function CommissionStatus({ status }: CommissionStatusProps) {
  const { t } = useTranslation();

  return (
    <StyledCommissionStatus status={status} className="CommissionStatus">
      <span />
      <Typography>{t(`commissionStatus.${status}`)}</Typography>
    </StyledCommissionStatus>
  );
}

export default CommissionStatus;
