import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledPriceResume = styled((props) => <Box {...props} />)`
  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiPaper-root.MuiAccordion-root:before {
    background: transparent;
  }

  .AccordionHeader {
    background-color: ${({ theme: { palette } }) => palette.background.default};
    border-radius: 12px;
    min-height: 0 !important;
    margin-bottom: 10px;
    margin-top: 0;

    .MuiAccordionSummary-content {
      margin: 14px 0;
    }
  }

  .AccordionHeader-Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .MuiTypography-root {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    background: transparent;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .AccordionDetails {
    padding: 2px 16px 12px;
  }

  .AccordionDetails-Item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      color: ${({ theme: { palette } }) => palette.text.secondary};
      font-size: 0.9rem;
    }
  }

  .TotalHeader {
    background-color: #000;
    color: ${({ theme: { palette } }) => palette.background.paper};
    margin-bottom: 0;

    svg {
      fill: ${({ theme: { palette } }) => palette.background.paper};
    }
  }

  .Customer-Wrapper,
  .Agency-Wrapper {
    .Title-Wrapper,
    .PaymentDetails-Item p {
      color: ${({ theme: { palette } }) => palette.text.secondary};
    }
  }

  .Red {
    .Title-Wrapper,
    .PaymentDetails-Item p {
      color: ${({ theme: { palette } }) => palette.secondary.main};
    }

    .Title-Wrapper {
      border: 2px solid ${({ theme: { palette } }) => palette.secondary.main};
    }
  }

  .Green {
    .Title-Wrapper,
    .PaymentDetails-Item p {
      color: ${({ theme: { palette } }) => palette.success.main};
    }

    .Title-Wrapper {
      border: 2px solid ${({ theme: { palette } }) => palette.success.main};
    }
  }

  .TotalDetails {
    .Title-Wrapper {
      display: flex;
      justify-content: space-between;
      border: 2px solid;
      margin-top: 10px;
      border-radius: 12px;
      padding: 11px 10px;

      p {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  .Customer-Title,
  .TotalPrice-Title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  .TotalDetails {
    padding: 0;
  }

  .PaymentDetails-Wrapper {
    padding: 8px 16px 16px;
  }

  .PaymentDetails-Item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: ${({ theme: { palette } }) => palette.text.secondary};
      font-size: 0.9rem;
    }
  }

  .PayerAssignment-Wrapper {
    p {
      display: inline-block;
    }

    button {
      display: inline-block;
      padding: 0 5px;
      font-size: 0.9rem;
      font-weight: 400;

      &:hover {
        background: transparent;
        color: ${({ theme: { palette } }) => palette.info.dark};
      }
    }
  }
`;

export { StyledPriceResume };
