import { getAPI } from '../API';
import { getAuthHeader } from '../utils/storageUtils';

export const handleGetRentalConfiguration = async () => {
  const api = getAPI();
  return await api.get('/rental/configuration', await getAuthHeader());
};

export const handleGetBookingConfiguration = async () => {
  const api = getAPI();
  return await api.get('/rental/booking-configuration', await getAuthHeader());
};

export const handleGetHolidays = async () => {
  const api = getAPI();
  return await api.get('/rental/holidays', await getAuthHeader());
};

export const handleGetAttentionSchedule = async () => {
  const api = getAPI();
  return await api.get('/rental/attention-schedule', await getAuthHeader());
};

export const handleGetPlaces = async () => {
  const api = getAPI();
  return await api.get('/rental/places', await getAuthHeader());
};

export const handleGetCommercialAgreementList = async () => {
  const api = getAPI();
  return await api.get('/rental/commercial-agreements', await getAuthHeader());
};

export const handleGetCategoryList = async () => {
  const api = getAPI();
  return await api.get('/rental/categories', await getAuthHeader());
};

export const handleGetDocumentTypes = async () => {
  const api = getAPI();
  return await api.get('/rental/document-types', await getAuthHeader());
};

export const handleGetFiscalConditionList = async () => {
  const api = getAPI();
  return await api.get('/rental/fiscal-conditions', await getAuthHeader());
};
