import { useContext, useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import SidebarLayout from '../../components/SidebarLayout';
import AdditionalInfo from '../../components/AdditionalInfo';
import CustomerData from '../../components/CustomerData';
import InsuranceAndAdditional from '../../components/InsuranceAndAdditional';
import { CheckoutRightContainer, GridContainer, NoSearchParamsWrapper } from './index.styled';
import CarResume from '../../components/CheckoutSidebar/CarResume';
import CommercialAgreement from '../../components/CheckoutSidebar/CommercialAgreement';
import PriceSummary from '../../components/PriceSummary';
import { Agreements, ExtraInfo } from '../../contexts/BookingContext';
import { SCREEN_LG } from '../../constants/screenSize';
import { useWindowSize } from '../../utils/windowUtils';
import { SEARCH_ROUTE } from '../../constants/routes';
import { useNavigateParams } from '../../hooks';
import { isValidDocument } from '../../utils/validationUtils';
import { RentalContext } from '../../contexts/RentalContext';
import type { Customer, FormError } from '../../contexts/types';
import { NewBookingItem, SearchContext, SelectedAdditional } from '../../contexts/SearchContext';
import { AuthContext } from '../../contexts/AuthContext';

function CheckoutContainer() {
  const [customerInfo, setCustomerInfo] = useState<Customer | null>(null);
  const [agreements, setAgreements] = useState<Agreements | null>(null);
  const [selectedAdditionalList, setSelectedAdditionalList] = useState<SelectedAdditional[]>([]);
  const [extraInfo, setExtraInfo] = useState<ExtraInfo | null>(null);
  const [formErrors, setFormErrors] = useState<FormError | null>(null);
  const [expandCustomerAccordion, setExpandCustomerAccordion] = useState<boolean>(false);

  const { documentTypes } = useContext(RentalContext);
  const { searchParams, selectedResult, priceInformation, handleBook } = useContext(SearchContext);
  const { profile } = useContext(AuthContext);

  const { width } = useWindowSize();
  const navigate = useNavigate();
  const mobileView = width < SCREEN_LG;
  const { t } = useTranslation();
  const navigateSearch = useNavigateParams();

  yup.addMethod(yup.string, 'isValidCustomerDoc', function () {
    const errorMsg = t('errors.wrongDocumentFormat');

    return this.test('test-document-format', errorMsg, (documentId) => {
      return isValidDocument(documentTypes, customerInfo?.documentTypeId, documentId);
    });
  });

  yup.addMethod(yup.array, 'areFilesValid', function () {
    return this.test('company-files', t('companyFilesError'), (files) => {
      if (files?.length === 0 || files?.some((f) => !f.name || !f.file)) {
        return false;
      }
      return true;
    });
  });

  const newBookingInfoSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.firstName') })),
    lastName: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.lastName') })),
    taxPayer: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.taxPayer') })),
    documentId: (yup as any)
      .string()
      .isValidCustomerDoc()
      .required(t('errors.inputIsRequired', { field: t('customerData.documentId') })),
    documentTypeId: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.documentType') })),
    emailAddress: yup
      .string()
      .email(t('errors.validEmail'))
      .required(t('errors.inputIsRequired', { field: t('customerData.emailAddress') })),
    cellPhone: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.cellPhone') })),
    country: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.country') })),
    promotionType: yup.string().when('discountAmount', {
      is: (discountAmount: string) => Number(discountAmount) > 0,
      then: yup
        .string()
        .required(t('errors.inputIsRequired', { field: t('checkout.agreements.promotionType') })),
    }),
  });

  const submitBooking = () => {
    const bookingParams = {
      customer: customerInfo as Customer,
      additional: selectedAdditionalList,
      agencyGuid: profile?.globalId,
      modelId: selectedResult?.car?.model?.id || '',
      ...agreements,
      ...extraInfo,
      ...searchParams,
    } as NewBookingItem;
    newBookingInfoSchema
      .validate({ ...customerInfo, ...agreements, ...extraInfo }, { abortEarly: false })
      .then(async (valid) => {
        if (valid) {
          const response = await handleBook(bookingParams);
          if (response.error) {
            navigateSearch(SEARCH_ROUTE.BOOKING_ERROR, { message: response.error });
          } else {
            navigateSearch(SEARCH_ROUTE.BOOKING_CONFIRMATION, { bookingId: response.data.id });
          }
        }
      })
      .catch((err) => {
        const formErrors = err.inner.reduce((prevErrors: any, currentError: any) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        setExpandCustomerAccordion(true);
        setFormErrors(formErrors);
      });
  };

  const CheckOutButtons = () => {
    return (
      <Grid container item={mobileView}>
        <Grid item md={12} className="ButtonsContainer">
          <Button
            variant="secondary-action"
            className="BackButton"
            fullWidth={mobileView}
            onClick={() => navigate(-1)}
          >
            {t('general.back')}
          </Button>
          <Button variant="contained" fullWidth={mobileView} onClick={submitBooking}>
            {t('general.book')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  if (!searchParams || !selectedResult) {
    return (
      <SidebarLayout>
        <NoSearchParamsWrapper>
          <SearchOffIcon />
          <Typography>{t('checkout.noSearchParams.title')}</Typography>
          <Button variant="contained" onClick={() => navigate(-1)}>
            {t('checkout.noSearchParams.button')}
          </Button>
        </NoSearchParamsWrapper>
      </SidebarLayout>
    );
  }

  return (
    <SidebarLayout>
      <GridContainer container spacing={3}>
        <Grid item sm={12} md={7} lg={8}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InsuranceAndAdditional
                selectedAdditionalList={selectedAdditionalList}
                setSelectedAdditionalList={setSelectedAdditionalList}
              />
            </Grid>
            <Grid item md={12}>
              <CustomerData
                customerInfo={customerInfo}
                formErrors={formErrors}
                agreements={agreements}
                accordionExpanded={expandCustomerAccordion}
                handleAccordion={setExpandCustomerAccordion}
                setAgreements={setAgreements}
                setCustomerInfo={setCustomerInfo}
              />
            </Grid>
            <Grid item xs={12}>
              <AdditionalInfo extraInfo={extraInfo} setExtraInfo={setExtraInfo} />
            </Grid>
          </Grid>
          {!mobileView && <CheckOutButtons />}
        </Grid>
        <CheckoutRightContainer item sm={12} md={5} lg={4}>
          <Grid container spacing={3}>
            <Grid item sm={12} className="RightContainer-Item">
              <CarResume />
            </Grid>
            <Grid item sm={12} className="RightContainer-Item">
              <CommercialAgreement agreement={agreements?.commercialAgreement} />
            </Grid>
            <Grid item sm={12} className="RightContainer-Item">
              <PriceSummary
                data={priceInformation}
                agreements={agreements}
                disabled
                showBalance={false}
              />
            </Grid>
          </Grid>
        </CheckoutRightContainer>

        {mobileView && <CheckOutButtons />}
      </GridContainer>
    </SidebarLayout>
  );
}
export default CheckoutContainer;
