import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';
import { AUTH_ROUTE } from '../../constants/routes';
import { AuthContext } from '../../contexts/AuthContext';
import type { AuthRouteProps } from './types';

const AuthRoute = ({ children }: AuthRouteProps) => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={AUTH_ROUTE.LOGIN} state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export default AuthRoute;
