import type { CardProps } from './types';
import StyledCard from './index.styled';

const Card = ({ padding, children, className = '' }: CardProps) => {
  return (
    <StyledCard padding={padding} className={`CardComponent ${className}`}>
      {children}
    </StyledCard>
  );
};
export default Card;
