import { getAPI } from '../API';
import { Operator } from '../contexts/OperatorsContext';
import type { AscDesc } from '../contexts/types';
import { getAuthHeader } from '../utils/storageUtils';

export const handleGetOperators = async (
  offset: number,
  limit: number,
  sortBy: string | null,
  sortDirection: AscDesc | null,
) => {
  const api = getAPI();
  const sortByInfo = sortBy ? `?sortBy=${sortBy}` : '';
  const sortDirectionInfo = sortDirection ? `?sortDirection=${sortDirection}` : '';

  return await api.get(
    `/operators?offset=${offset}&limit=${limit}${sortByInfo}${sortDirectionInfo}`,
    await getAuthHeader(),
  );
};

export const handleGetOperator = async (id: string) => {
  const api = getAPI();
  return await api.get(`/operators?id=${id}`, await getAuthHeader());
};

export const handleChangeOperatorStatus = async (id: number, status: string) => {
  const api = getAPI();
  return await api.post(`/operators?id=${id}&active=${status}`);
};

export const handleAddOrUpdateOperator = async (operator: Operator) => {
  const api = getAPI();
  return await api.post('/operators', { operator }, await getAuthHeader());
};
