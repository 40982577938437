import { Box, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_SM } from '../../../constants/screenSize';

const UserName = styled.div`
  p {
    font-size: 0.9rem;
    align-items: center;
    white-space: nowrap;
    width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: ${SCREEN_SM}px) {
    display: none;
  }
`;

const StyledAccountMenu = styled(Box)`
  .AccountNotificationsWrapper {
    display: flex;
    align-items: center;
    color: ${({ theme: { palette } }) => palette.text.secondary};
    margin-right: 15%;
  }

  .HoverIcon {
    &:hover {
      color: ${({ theme: { palette } }) => palette.primary.main};
    }
  }

  .IconButton {
    &:hover {
      background: none;
    }
  }

  .MuiMenuItem-root {
    a {
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  min-width: 150px;

  a {
    color: ${({ theme: { palette } }) => palette.text.secondary};
    text-decoration: none;
  }
`;

export { UserName, StyledAccountMenu, StyledMenuItem };
