import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchOffIcon from '@mui/icons-material/SearchOff';

import SidebarLayout from '../../components/SidebarLayout';
import SearchForm from '../../components/SearchForm';
import SearchResultItem from '../../components/SearchResultItem';
import { GridContainer } from './index.styled';
import { SearchContext } from '../../contexts/SearchContext';
import { useWindowSize } from '../../utils/windowUtils';
import { SCREEN_LG } from '../../constants/screenSize';
import SearchFilters from '../../components/SearchFilters';
import MobileSearchForm from '../../components/MobileSearchForm';
import MobileSearchFilter from '../../components/MobileSearchFilter';
import ResultItemLoading from '../../components/ResultItemLoading';
import type { SearchParams } from '../../contexts/SearchContext';

function SearchContainer() {
  const { t } = useTranslation();
  const [showSearchFormDialog, setShowSearchFormDialog] = useState<boolean>(false);
  const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedKm, setSelectedKm] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const { searchResults, searchParams, handleSearch } = useContext(SearchContext);
  const { width } = useWindowSize();

  const mobileView = width < SCREEN_LG;

  const handleSelectCategory = (value: number) => {
    if (selectedCategories.includes(value)) {
      const newArray = selectedCategories.filter((i) => i !== value);
      setSelectedCategories(newArray);
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
    setShowFilterDialog(false);
  };

  const handleSelectKm = (value: number) => {
    if (value === selectedKm) {
      return setSelectedKm(-1);
    }
    setSelectedKm(value);
    setShowFilterDialog(false);
  };

  const getSearchResults = async (params?: SearchParams | null) => {
    setLoading(true);
    await handleSearch(params || searchParams!);
    setLoading(false);
    setShowSearchFormDialog(false);
  };

  const getFilteredResults = () => {
    let filteredResults = searchResults;

    if (selectedCategories.length > 0) {
      filteredResults = searchResults.filter((r) => selectedCategories.includes(r.category.id));
    }

    // Unlimited km selected
    if (selectedKm === 0) {
      return filteredResults.filter((i) => i.unlimitedKm);
    }

    // Daily km selected
    if (selectedKm === 1) {
      return filteredResults.filter((i) => !i.unlimitedKm);
    }

    return filteredResults;
  };

  const getSearchResultsComponent = () => {
    const filteredSearchResults = getFilteredResults();

    if (loading) {
      return [...Array(3)].map((i, idx) => {
        return <ResultItemLoading key={idx} />;
      });
    }

    if (filteredSearchResults.length === 0) {
      return (
        <Box className="NoSearchResults">
          <SearchOffIcon />
          <Typography>{t('search.noSearchResults')}</Typography>
        </Box>
      );
    }

    return filteredSearchResults.map((item, index) => {
      return <SearchResultItem item={item} key={index} setLoading={setLoading} />;
    });
  };

  return (
    <SidebarLayout>
      {/* SearchForm Dialog */}
      <Dialog
        open={showSearchFormDialog}
        onClose={() => setShowSearchFormDialog(false)}
        aria-labelledby="mobile-search-form"
        fullWidth
      >
        <DialogTitle>
          <IconButton aria-label="close" onClick={() => setShowSearchFormDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <SearchForm getSearchResults={getSearchResults} isDialog />
        </DialogContent>
      </Dialog>

      {/* Filters Dialog */}
      <Dialog
        open={showFilterDialog}
        onClose={() => setShowFilterDialog(false)}
        aria-labelledby="mobile-filters"
        fullWidth
      >
        <DialogTitle>
          <IconButton aria-label="close" onClick={() => setShowFilterDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <SearchFilters
            selectedCategories={selectedCategories}
            selectedKm={selectedKm}
            handleSelectCategory={handleSelectCategory}
            handleSelectKm={handleSelectKm}
          />
        </DialogContent>
      </Dialog>

      <GridContainer container columnSpacing={5} rowSpacing={2}>
        {!mobileView && (
          <Grid item md={12}>
            <Typography variant="h2" className="HomeTitle">
              {t('home.title')}
            </Typography>
          </Grid>
        )}

        {mobileView ? (
          <Grid item xs={12}>
            <MobileSearchForm
              searchParams={searchParams}
              handleChangeDates={() => setShowSearchFormDialog(true)}
            />
            <MobileSearchFilter handleAddFilter={() => setShowFilterDialog(true)} />
          </Grid>
        ) : (
          <Grid item md={5} xl={3.5}>
            <SearchForm getSearchResults={getSearchResults} />
            <SearchFilters
              selectedCategories={selectedCategories}
              selectedKm={selectedKm}
              handleSelectCategory={handleSelectCategory}
              handleSelectKm={handleSelectKm}
            />
          </Grid>
        )}

        {/* Search Results */}
        <Grid item xs={12} md={7} xl={8.5} className="ResultsWrapper">
          {getSearchResultsComponent()}
        </Grid>
      </GridContainer>
    </SidebarLayout>
  );
}
export default SearchContainer;
