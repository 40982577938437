import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SCREEN_MD, SCREEN_SM, SCREEN_XL } from '../../constants/screenSize';

const BookingDetailsHeader = styled((props) => <Grid {...props} />)`
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 1rem;

  .TitleWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .Title {
      font-weight: 500;
      white-space: nowrap;
    }

    .Separator {
      margin: 0 8px;
    }

    .CustomerWrapper {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .Title {
        margin-right: 6px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 1.2em;
        white-space: nowrap;
      }
    }
  }

  .CreatedBy {
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }

  .RightMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .CancelButton {
      min-width: 200px;
      margin-left: 3rem;
      color: white;
      line-height: 1.5;
      background-color: #fc6161;
    }
  }

  .Divider {
    margin: 10px 0;
    display: none;
  }

  @media (max-width: ${SCREEN_XL}px) {
    .RightMenu {
      flex-direction: row-reverse;
      margin-top: 15px;

      .CancelButton {
        margin-left: 0;
      }
    }
  }

  @media (max-width: ${SCREEN_MD}px) {
    .Divider {
      display: block;
    }

    .LeftMenu {
      width: 100%;
    }

    .RightMenu {
      .CancelButton {
        min-width: 0;
      }
    }
    .TitleWrapper {
      flex-direction: column;
      align-items: flex-start;

      .CustomerWrapper {
        width: 100%;

        .Title {
          max-width: 70%;
        }
      }

      .Separator {
        display: none;
      }
    }
  }

  @media (max-width: ${SCREEN_SM}px) {
    margin-top: 20px;
  }
`;

const NewTabButton = styled(Link)`
  border: none;
  background: transparent;
  padding: 0;
  height: 23px;

  svg {
    fill: ${({ theme: { palette } }) => palette.info.main};
  }

  &:hover {
    svg {
      fill: ${({ theme: { palette } }) => palette.info.dark};
    }
  }
`;

const BookingDetailsContent = styled((props) => <Grid {...props} />)`
  & > .MuiGrid-item {
    width: 100%;
  }

  .DeliveryIcon {
    color: ${({ theme: { palette } }) => palette.success.main};
  }
  .ReturnIcon {
    color: ${({ theme: { palette } }) => palette.primary.main};
  }

  .DateInfo {
    p {
      margin-left: 5px;
    }

    .Bold {
      font-weight: 500;
    }
  }

  .Row {
    display: flex;
    align-items: center;
  }

  .TitleWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    svg {
      margin-right: 8px;
    }
  }

  .LocationText {
    color: ${({ theme: { palette } }) => palette.info.main};

    p {
      font-size: 0.9rem;
    }

    svg {
      height: 15px;
    }
  }

  .BookingItem-Box {
    .CardComponent {
      height: 100%;
    }
  }

  .SubtitleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    svg {
      margin-right: 10px;
    }
  }

  .BoxDescription {
    &.Small {
      max-height: 150px;
      overflow: auto;
    }

    p {
      color: ${({ theme: { palette } }) => palette.text.secondary};
      font-size: 0.9rem;
    }
  }

  .DriverBox-Header {
    display: flex;
    justify-content: space-between;

    .Title {
      display: flex;
      svg {
        margin-right: 10px;
      }
    }
    button {
      top: -8px;
      right: -8px;
    }
  }

  .DriverBox-Item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: ${({ theme: { palette } }) => palette.text.secondary};
      text-decoration: none;
      font-size: 0.9rem;
    }

    button {
      right: -8px;
    }
  }
`;

const ErrorWrapper = styled((props) => <Box {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  max-width: 525px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  p {
    color: ${({ theme: { palette } }) => palette.text.primary};
    margin-bottom: 20px;
  }

  button {
    min-width: 150px;
  }
`;

export { NewTabButton, BookingDetailsHeader, BookingDetailsContent, ErrorWrapper };
