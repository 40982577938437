import { Box, Typography } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { StyledDataCard, NewTabButton } from './index.styled';
import Card from '../Card/index';
import type { DataCardProps } from './types';

function DataCard({ amount, title, icon, link, iconColor, className = '' }: DataCardProps) {
  return (
    <StyledDataCard iconColor={iconColor} className={className}>
      <Card>
        <Box className="LabelWrapper">
          {icon}
          <Typography variant="body1">{title}</Typography>
        </Box>
        <Box className="AmountWrapper">
          <Typography variant="h3" className="AmountText">
            {amount}
          </Typography>
          {link && (
            <NewTabButton to={link}>
              <OpenInNew />
            </NewTabButton>
          )}
        </Box>
      </Card>
    </StyledDataCard>
  );
}

export default DataCard;
