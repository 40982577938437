export const AirportNames = ['airport', 'aeropuertos', 'aeroparques', 'aeropuerto'];

export const OfficeNames = [
  'oficina',
  'oficinas',
  'sucursales',
  'sucursal',
  'branchoffice',
  'branch',
];

export const PointNames = ['delivery', 'punto', 'encuentro', 'domicilios'];

export const BusNames = ['terminales', 'terminal'];

export const PortNames = ['puerto', 'port', 'ferry'];
