import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_SM, SCREEN_XXL } from '../../constants/screenSize';

const StyledSignUp = styled((props) => <Box {...props} />)`
  max-width: 429px;
  margin: auto;
  margin-top: 15vh;

  .Title {
    max-width: 600px;
    font-size: 1.8rem;
    font-weight: 500;
    color: ${({ theme: { palette } }) => palette.text.primary};
  }

  @media (max-width: ${SCREEN_SM}px) {
    max-width: 300px;
  }
  @media (min-width: ${SCREEN_XXL}px) {
    max-width: 500px;
  }
`;

const SignUpForm = styled((props) => <Grid {...props} />)``;

export { StyledSignUp, SignUpForm };
