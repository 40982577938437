import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_LG } from '../../constants/screenSize';

const StyledResultItemLoading = styled((props) => <Box {...props} />)`
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;
  width: 100%;

  .MuiSkeleton-root {
    width: 100%;
  }

  .CarImage-Wrapper {
    display: flex;
  }

  .Title-Wrapper {
    width: calc(55% - 15px);
    margin-left: 15px;
    margin-top: 10px;
  }

  .CarImage {
    width: 100%;
    height: 125px;
  }

  .Title {
    font-size: 1.1rem;
    max-width: 300px;
  }

  .SubTitle {
    font-size: 0.9rem;
    max-width: 230px;
    margin-bottom: 10px;
  }

  .CarDetailsText {
    font-size: 0.7rem;
    margin: 5px 0;
  }

  .Price {
    font-size: 1.6rem;
    max-width: 100px;
    margin: auto;
    margin-bottom: 10px;
  }

  .PriceText {
    font-size: 0.7rem;
    max-width: 150px;
    margin: auto;
  }

  .Rectangle {
    border-radius: 10px;
  }

  @media (max-width: ${SCREEN_LG}px) {
    margin-bottom: 3rem;

    & > .MuiGrid-container {
      flex-direction: column;
      padding: 0;
    }

    .CarImage-Wrapper {
      margin-bottom: 10px;
    }

    .CarImage {
      width: 45%;
      height: 80px;
    }
  }
`;
export default StyledResultItemLoading;
