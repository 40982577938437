import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledCommercialAgreement = styled((props) => <Box {...props} />)`
  width: 100%;

  .AgreementsTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.2rem;

    svg {
      margin-right: 10px;
    }
  }

  .AgreementItem {
    font-size: 0.9rem;
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }
`;

export { StyledCommercialAgreement };
