import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_MD } from '../../constants/screenSize';

const StyledCard = styled((props) => <Box {...props} />)`
  background: ${({ theme: { palette } }) => palette.background.paper};
  padding: ${({ padding }) => padding || '1.5rem 1.8rem'};
  border: ${({ theme }) => `1px solid ${theme.extra.border}`};
  border-radius: 10px;

  @media (max-width: ${SCREEN_MD}px) {
    padding: ${({ padding }) => padding || '1.5rem 1rem'};
  }
`;
export default StyledCard;
