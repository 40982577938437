import { Button, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableErrorWrapper } from './index.styled';
import type { TableErrorProps } from './types';

function TableError({ error, colSpan, onClick }: TableErrorProps) {
  const { t } = useTranslation();
  return (
    <TableErrorWrapper>
      <TableCell colSpan={colSpan} align="center">
        <p>{error}</p>
        <Button onClick={onClick}>{t('general.retry')}</Button>
      </TableCell>
    </TableErrorWrapper>
  );
}
export default TableError;
