import { AxiosError } from 'axios';
import { createContext, useState } from 'react';
import {
  createBooking,
  fetchAdditionalList,
  fetchPrices,
  getSearchResults,
  handleGetSearchBarResults,
  handleSearchCustomerByEmail,
} from '../actions/actions.search';
import type {
  Additional,
  APIResponse,
  Car,
  CarCategory,
  Place,
  PriceItem,
  Customer,
  AdditionalListItem,
  PriceInformation,
  FileType,
} from './types';
import { CommercialAgreement } from './RentalContext';

export interface SearchResult {
  car: Car;
  category: CarCategory;
  fromDate: string;
  toDate: string;
  deliveryPlace: Place;
  returnPlace: Place;
  totalDaysString: string;
  price: number;
  franchise: number;
  totalDays: number;
  unlimitedKm: boolean;
  hasFranchiseModifiers: boolean;
  averageDayPrice: number;
  priceItems: PriceItem[];
  availablePromotions: any[];
  additional: AdditionalListItem[];
  currency: string;
  onlyQuote: boolean;
  maxAllowedDistance: number;
  maxAllowedDistanceByDay: number;
}

export interface SearchParams {
  dateFrom: Date;
  hourFrom: string;
  pickUpPlaceName: string;
  pickUpPlaceId: number | null;
  dateTo: Date;
  hourTo: string;
  dropOffPlaceName: string;
  dropOffPlaceId: number | null;
  unlimitedKm: boolean;
  commercialAgreement: string;
  promotionCode: string;
  driverAge: string;
}

export interface SelectedAdditional {
  id: number;
  number: number;
}

export interface NewBookingItem {
  customer: Customer;
  dateFrom: Date;
  hourFrom: string;
  pickUpPlaceName: string;
  pickUpPlaceId: number | null;
  dateTo: Date;
  hourTo: string;
  dropOffPlaceName: string;
  dropOffPlaceId: number | null;
  unlimitedKm: boolean;
  commercialAgreement: string;
  promotionType: string;
  discountAmount?: string;
  promotionCode?: string;
  driverAge: string;
  notes?: string;
  companyFiles?: FileType[];
  modelId: string;
  additional: SelectedAdditional[];
  agencyGuid: string;
}

interface ContextProps {
  searchResults: SearchResult[];
  searchParams: SearchParams | null;
  availableCommercialAgreementList: CommercialAgreement[];
  selectedResult: SearchResult | null;
  diffDeliveryPlace: boolean;
  betweenAllowedAge: boolean;
  priceInformation: PriceInformation | null;
  additionalList: Additional[];
  customerList: Customer[];
  handleSearch: (params: SearchParams) => Promise<APIResponse>;
  handleBook: (params: NewBookingItem) => Promise<APIResponse>;
  setSearchParams: (params: SearchParams) => void;
  setAvailableCommercialAgreementList: (CommercialAgreement: CommercialAgreement[]) => void;
  setSelectedResult: (result: SearchResult | null) => void;
  setDiffDeliveryPlace: (value: boolean) => void;
  setBetweenAllowedAge: (value: boolean) => void;
  getPrices: (additional?: SelectedAdditional[], modelId?: number) => void;
  getAdditionalList: (modelId: number, categoryId: number) => void;
  getCustomerByEmail: (email: string) => void;
  getSearchBarResults: (keyword: string) => Promise<APIResponse>;
}

export const SearchContext = createContext({} as ContextProps);

export const SearchProvider = ({ children }: { children: JSX.Element }) => {
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams | null>(null);
  const [availableCommercialAgreementList, setAvailableCommercialAgreementList] = useState<
    CommercialAgreement[]
  >([]);
  const [selectedResult, setSelectedResult] = useState<SearchResult | null>(null);
  const [diffDeliveryPlace, setDiffDeliveryPlace] = useState<boolean>(false);
  const [betweenAllowedAge, setBetweenAllowedAge] = useState<boolean>(true);
  const [priceInformation, setPriceInformation] = useState<PriceInformation | null>(null);
  const [additionalList, setAdditionalList] = useState<Additional[]>([]);
  const [customerList, setCustomerList] = useState<Customer[]>([]);

  const handleSearch = async (params: SearchParams): Promise<APIResponse> => {
    try {
      const response = (await getSearchResults(params))?.data;
      if (response.error) return { error: response.error };
      setSearchResults(response);
      return { error: '' };
    } catch (error: any) {
      return { error };
    }
  };

  const getAdditionalList = async (modelId: number, categoryId: number) => {
    const response = await fetchAdditionalList(searchParams!, modelId, categoryId);
    if (response.data) setAdditionalList(response.data);
  };

  const getPrices = async (additional = [] as SelectedAdditional[], modelId?: number) => {
    try {
      const carModelId = modelId || selectedResult!.car!.model!.id;
      const response = (await fetchPrices(searchParams!, carModelId, additional))?.data;
      setPriceInformation(response);
    } catch (error) {
      return console.log('Error getting prices: ', error);
    }
  };

  const handleBook = async (params: NewBookingItem): Promise<APIResponse> => {
    try {
      const response = (await createBooking(params))?.data;
      if (response.error) return { error: response.error };
      return { data: response.data, error: '' };
    } catch (error: any) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const getCustomerByEmail = async (email: string) => {
    try {
      const response = (await handleSearchCustomerByEmail(email))?.data;
      if (response.error) return;
      setCustomerList(response.data);
    } catch (error: any) {
      return console.log('Error getting customer list: ', error.message);
    }
  };

  const getSearchBarResults = async (keyword: string): Promise<APIResponse> => {
    try {
      const response = (await handleGetSearchBarResults(keyword))?.data;
      if (response.error) return { error: response.error };
      return { data: response.data, error: '' };
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  return (
    <SearchContext.Provider
      value={{
        searchResults,
        searchParams,
        availableCommercialAgreementList,
        selectedResult,
        diffDeliveryPlace,
        betweenAllowedAge,
        priceInformation,
        additionalList,
        customerList,
        handleSearch,
        handleBook,
        setSearchParams,
        setSelectedResult,
        setDiffDeliveryPlace,
        setBetweenAllowedAge,
        getPrices,
        getAdditionalList,
        getCustomerByEmail,
        getSearchBarResults,
        setAvailableCommercialAgreementList,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
