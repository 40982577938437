import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledOperatorStatus from './index.styled';
import { OperatorStatusProps } from './types';

function OperatorStatus({ status }: OperatorStatusProps) {
  const { t } = useTranslation();

  return (
    <StyledOperatorStatus status={status} className="OperatorStatus">
      <span />
      <Typography>{t(`operatorStatus.${status}`)}</Typography>
    </StyledOperatorStatus>
  );
}

export default OperatorStatus;
