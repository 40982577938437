import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_XS } from '../../constants/screenSize';

const StyledContainer = styled((props) => <Box {...props} />)`
  border-radius: 10px;

  .AccordionDetails {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .Agreements {
    margin-left: 1%;
    margin-top: 12%;
    margin-bottom: 12%;
    display: flex;
    align-items: center;
  }

  .IconWrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }

  .MuiTextField-root {
    width: 100%;
    margin: 0.5em;
  }

  .MuiAccordionDetails-root {
    margin-left: -17px;
  }

  .Select {
    width: 100%;
    margin: 8px;
  }

  .StyledCard {
    padding: 1rem 1rem;
    min-width: 195px;
  }

  .TextfieldDetails {
    margin-top: 3%;
  }

  .EmailInput {
    .MuiAutocomplete-popupIndicator {
      display: none;
    }

    .MuiInputBase-root {
      padding-right: 25px !important;
    }
  }

  .Typography {
    margin-left: 20px;

    @media (max-width: ${SCREEN_XS}px) {
      margin-left: 5px;
    }
  }

  .Typography2 {
    margin-left: 15px;
    @media (max-width: ${SCREEN_XS}px) {
      margin-left: 1px;
    }
  }
`;

export { StyledContainer };
