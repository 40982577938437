import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_MD, SCREEN_XS, SCREEN_XXL } from '../../constants/screenSize';

const StyledErrorPageContainer = styled((props) => <Box {...props} />)`
  background-color: ${({ theme: { palette } }) => palette.background.default};
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  svg {
    position: absolute;
    right: 20px;
    bottom: 14%;
    width: 70%;
    max-width: 1000px;
    animation: floating-spaceman 40s cubic-bezier(0.62, 0.08, 0.45, 0.85) infinite alternate;
  }

  @keyframes floating-spaceman {
    0% {
      transform: translate(0, 0) rotate(-5deg);
    }
    50% {
      transform: translate(-10%, 40%) rotate(0);
    }
    100% {
      transform: translate(0, 0) rotate(5deg);
    }
  }

  @media (max-width: ${SCREEN_XXL}px) {
    svg {
      width: 50%;
      max-width: 800px;
    }
  }

  @media (max-width: ${SCREEN_MD}px) and (orientation: portrait) {
    svg {
      width: 70%;
    }
  }
`;

const ErrorWrapper = styled((props) => <Box {...props} />)`
  max-width: 600px;
  margin: auto;
  margin-top: 35vh;
  text-align: center;
  color: ${({ theme: { palette } }) => palette.text.secondary};
  padding: 0 20px;

  .LogoWrapper {
    position: relative;
    margin-bottom: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 200px;
    }
  }

  .Title {
    position: absolute;
    font-size: 20rem;
    font-weight: 900;
    color: #858c99;
    opacity: 0.1;
    left: 0;
    right: 0;
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }

  .Subtitle {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 2rem;
    padding-bottom: 5px;
    margin: 0 auto;
  }

  .Paragraph {
    font-size: 1rem;
    max-width: 500px;
  }

  button {
    margin-top: 4rem;
    min-width: 200px;
  }

  @media (max-width: ${SCREEN_XXL}px) {
    zoom: 0.8;
  }

  @media (max-width: ${SCREEN_MD}px) {
    .Title {
      font-size: 14rem;
    }
  }

  @media (max-width: ${SCREEN_XS}px) {
    .Title {
      font-size: 10rem;
    }
  }
`;

export { StyledErrorPageContainer, ErrorWrapper };
