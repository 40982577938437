import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_MD } from '../../constants/screenSize';

const StyledBookingConfirmation = styled((props) => <Box {...props} />)`
  max-width: 540px;
  margin: auto;
  margin-top: 3vh;

  .TitleWrapper {
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    color: ${({ theme: { palette } }) => palette.success.main};

    p {
      color: ${({ theme: { palette } }) => palette.text.primary};
      margin-right: 8px;
    }

    .BookingId {
      text-decoration: none;
      color: ${({ theme: { palette } }) => palette.info.main};
    }
  }

  .SubtitleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    svg {
      color: ${({ theme: { palette } }) => palette.success.main};
      margin-right: 5px;
    }

    p {
      font-weight: bold;
    }
  }

  .ButtonsContainer {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    button {
      min-width: 200px;
      margin: 0 7px;
    }
  }

  @media (max-width: ${SCREEN_MD}px) {
    .ButtonsContainer {
      flex-direction: column;
      margin: 20px auto 0px auto;

      button {
        margin: 0;
        margin-bottom: 14px;
      }
    }
  }
`;

export { StyledBookingConfirmation };
