import { createContext, useEffect, useState } from 'react';
import { getUserConfig, setUserConfig } from '../utils/storageUtils';
import i18n from '../i18n';

interface ContextProps {
  lang: string;
  changeLanguage: (lang: string) => void;
}

export const LanguageContext = createContext({} as ContextProps);

export const LanguageProvider = ({ children }: { children: JSX.Element }) => {
  const [lang, setLang] = useState<string>('en-EN');

  useEffect(() => {
    const userLang = getUserConfig('language');
    if (userLang) setLang(userLang);
  }, []);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLang(lang);
    setUserConfig('language', lang);
  };

  return (
    <LanguageContext.Provider value={{ lang, changeLanguage }}>{children}</LanguageContext.Provider>
  );
};
