import { MouseEvent, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { UserName, StyledAccountMenu, StyledMenuItem } from './index.styled';
import { AuthContext } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { ACCOUNT_ROUTE } from '../../../constants/routes';
import { Typography } from '@mui/material';

function AccountMenu() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { profile, signOut } = useContext(AuthContext);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const firstName = `${profile?.firstName || ''}`;
  const lastName = `${profile?.lastName || ''}`;

  return (
    <StyledAccountMenu>
      <Box>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className="IconButton"
        >
          <Box className="AccountNotificationsWrapper">
            <AccountCircleOutlinedIcon className="HoverIcon" />
            <UserName>
              <Typography>{`${firstName} ${lastName}`} </Typography>
            </UserName>
            <ExpandMoreIcon />
          </Box>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        className="MenuPaperProps"
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <StyledMenuItem>
          <Link to={ACCOUNT_ROUTE.MY_ACCOUNT}>{t('accountMenu.myAccount')}</Link>
        </StyledMenuItem>
        <StyledMenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('accountMenu.logout')}
        </StyledMenuItem>
      </Menu>
    </StyledAccountMenu>
  );
}

export default AccountMenu;
