import { LoadingButton } from '@mui/lab';
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../components/Card';
import SidebarLayout from '../../components/SidebarLayout';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import type { FeedbackMsg } from '../../contexts/types';
import { isValidPassword } from '../../utils/validationUtils';
import { TitleWrapper, ContentWrapper } from './index.styled';

function MyAccountContainer() {
  const { t } = useTranslation();
  const { profile, changePassword } = useContext(AuthContext);
  const { lang, changeLanguage } = useContext(LanguageContext);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordValid, setNewPasswordValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [changePasswordFeedback, setChangePasswordFeedback] = useState<FeedbackMsg>({
    severity: 'success',
    text: '',
  });

  const languages = [
    {
      id: 'es-ES',
      name: t('general.spanish'),
    },
    {
      id: 'en-EN',
      name: t('general.english'),
    },
  ];

  const handlePasswordBlur = () => {
    const valid = isValidPassword(newPassword);
    setNewPasswordValid(valid);
  };

  const handleChangePassword = async () => {
    setLoading(true);
    setChangePasswordFeedback({ severity: 'success', text: '' });
    const response = await changePassword(oldPassword, newPassword);
    if (response.error) {
      setChangePasswordFeedback({ severity: 'error', text: response.error });
    } else {
      setChangePasswordFeedback({
        severity: 'success',
        text: t('myAccount.changePasswordSuccessfully'),
      });
      setTimeout(() => setChangePasswordFeedback({ severity: 'success', text: '' }), 2000);
    }
    setLoading(false);
  };

  return (
    <SidebarLayout>
      <TitleWrapper>
        <Typography variant="h1">{t('myAccount.title')}</Typography>
      </TitleWrapper>
      <ContentWrapper>
        {changePasswordFeedback.text && (
          <Alert className="AlertText" severity={changePasswordFeedback.severity}>
            {changePasswordFeedback.text}
          </Alert>
        )}
        <Card>
          <Box>
            <Typography variant="h2" className="Subtitle">
              {t('myAccount.information')}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField
                  value={profile?.email || ''}
                  label={t('myAccount.email')}
                  fullWidth
                  margin="dense"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl size="medium" className="Select" fullWidth>
                  <InputLabel id="city-label">{t('myAccount.language')}</InputLabel>
                  <Select
                    labelId="city-label"
                    value={lang}
                    onChange={({ target }) => changeLanguage(target.value)}
                    label={t('myAccount.language')}
                  >
                    {languages.map((language, index) => {
                      return (
                        <MenuItem key={index} value={language.id}>
                          {language.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography variant="h2" className="Subtitle">
              {t('myAccount.changePassword')}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField
                  value={oldPassword}
                  label={t('myAccount.oldPassword')}
                  onChange={({ target }) => setOldPassword(target.value)}
                  fullWidth
                  margin="dense"
                  type="password"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={newPassword}
                  label={t('myAccount.newPassword')}
                  onChange={({ target }) => setNewPassword(target.value)}
                  onBlur={handlePasswordBlur}
                  fullWidth
                  margin="dense"
                  type="password"
                  error={!newPasswordValid}
                  helperText={!newPasswordValid && t('errors.wrongPassword')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <LoadingButton
                  variant="contained"
                  className="ChangePassword-Btn"
                  disabled={!oldPassword || !newPassword || !newPasswordValid}
                  onClick={() => handleChangePassword()}
                  loading={loading}
                >
                  {t('myAccount.changePassword')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </ContentWrapper>
    </SidebarLayout>
  );
}
export default MyAccountContainer;
