import { useContext, useState } from 'react';
import { Route, Routes as RoutesWrapper } from 'react-router-dom';
import {
  ACCOUNT_ROUTE,
  AUTH_ROUTE,
  BOOKINGS_ROUTE,
  CUSTOMERS_ROUTE,
  DASHBOARD_ROUTE,
  OPERATORS_ROUTE,
  SEARCH_ROUTE,
} from '../../constants/routes';
import AddCustomerContainer from '../../containers/AddCustomer';
import BookingConfirmationContainer from '../../containers/BookingConfirmation';
import BookingsDetailsContainer from '../../containers/BookingDetails';
import BookingsContainer from '../../containers/Bookings';
import CheckoutContainer from '../../containers/Checkout';
import CommissionsContainer from '../../containers/Commissions';
import CustomerDetailsContainer from '../../containers/CustomerDetails';
import CustomersContainer from '../../containers/Customers';
import ErrorPageContainer from '../../containers/ErrorPage';
import ForgetPasswordContainer from '../../containers/ForgetPassword';
import HomeContainer from '../../containers/Home';
import LoginContainer from '../../containers/Login';
import MyAccountContainer from '../../containers/MyAccount';
import OperatorsContainer from '../../containers/Operators';
import NewOperatorContainer from '../../containers/Operators/NewOperator';
import ResetPasswordContainer from '../../containers/ResetPassword';
import SearchContainer from '../../containers/Search';
import SignUpContainer from '../../containers/SignUp';
import { RentalContext } from '../../contexts/RentalContext';
import { useEffectOnMount } from '../../hooks';
import i18n from '../../i18n';
import { getUserConfig } from '../../utils/storageUtils';
import AuthRoute from '../AuthRoute';
import LoadingScreen from '../LoadingScreen';
import { AuthContext } from '../../contexts/AuthContext';
import BookingErrorContainer from '../../containers/BookingError';

function Routes() {
  const { attentionSchedule, places, getRentalConfiguration, getBookingSettings } =
    useContext(RentalContext);
  const { isAuthenticated } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(true);

  useEffectOnMount(() => {
    const getConfig = async () => {
      setLoading(true);
      await getRentalConfiguration();

      if (isAuthenticated && (!attentionSchedule.length || !places.length)) {
        await getBookingSettings();
      }
      setLoading(false);
    };

    const setLanguage = () => {
      const lang = getUserConfig('language');
      if (lang) i18n.changeLanguage(lang);
    };

    getConfig();
    setLanguage();
  });

  if (loading) return <LoadingScreen />;

  return (
    <RoutesWrapper>
      <Route path={AUTH_ROUTE.LOGIN} element={<LoginContainer />} />
      <Route path={AUTH_ROUTE.SIGN_UP} element={<SignUpContainer />} />
      <Route path={AUTH_ROUTE.FORGOT_PASSWORD} element={<ForgetPasswordContainer />} />
      <Route path={AUTH_ROUTE.RESET_PASSWORD} element={<ResetPasswordContainer />} />
      <Route
        path="/"
        element={
          <AuthRoute>
            <HomeContainer />
          </AuthRoute>
        }
      />
      <Route
        path={DASHBOARD_ROUTE.BOOKINGS}
        element={
          <AuthRoute>
            <BookingsContainer />
          </AuthRoute>
        }
      />
      <Route
        path={SEARCH_ROUTE.CHECKOUT}
        element={
          <AuthRoute>
            <CheckoutContainer />
          </AuthRoute>
        }
      />
      <Route
        path={SEARCH_ROUTE.BOOKING_CONFIRMATION}
        element={
          <AuthRoute>
            <BookingConfirmationContainer />
          </AuthRoute>
        }
      />
      <Route
        path={SEARCH_ROUTE.BOOKING_ERROR}
        element={
          <AuthRoute>
            <BookingErrorContainer />
          </AuthRoute>
        }
      />
      <Route
        path={BOOKINGS_ROUTE.BOOKING_DETAILS}
        element={
          <AuthRoute>
            <BookingsDetailsContainer />
          </AuthRoute>
        }
      />
      <Route
        path={SEARCH_ROUTE.SEARCH}
        element={
          <AuthRoute>
            <SearchContainer />
          </AuthRoute>
        }
      />
      <Route
        path={DASHBOARD_ROUTE.CUSTOMERS}
        element={
          <AuthRoute>
            <CustomersContainer />
          </AuthRoute>
        }
      />
      <Route
        path={DASHBOARD_ROUTE.OPERATORS}
        element={
          <AuthRoute>
            <OperatorsContainer />
          </AuthRoute>
        }
      />
      <Route
        path={OPERATORS_ROUTE.ADD_OPERATOR}
        element={
          <AuthRoute>
            <NewOperatorContainer />
          </AuthRoute>
        }
      />
      <Route
        path={OPERATORS_ROUTE.EDIT_OPERATOR}
        element={
          <AuthRoute>
            <NewOperatorContainer />
          </AuthRoute>
        }
      />
      <Route
        path={CUSTOMERS_ROUTE.CUSTOMER_DETAILS}
        element={
          <AuthRoute>
            <CustomerDetailsContainer />
          </AuthRoute>
        }
      />
      <Route
        path={CUSTOMERS_ROUTE.ADD_CUSTOMER}
        element={
          <AuthRoute>
            <AddCustomerContainer />
          </AuthRoute>
        }
      />
      <Route
        path={CUSTOMERS_ROUTE.EDIT_CUSTOMER}
        element={
          <AuthRoute>
            <AddCustomerContainer />
          </AuthRoute>
        }
      />
      <Route
        path={DASHBOARD_ROUTE.COMMISSIONS}
        element={
          <AuthRoute>
            <CommissionsContainer />
          </AuthRoute>
        }
      />
      <Route
        path={ACCOUNT_ROUTE.MY_ACCOUNT}
        element={
          <AuthRoute>
            <MyAccountContainer />
          </AuthRoute>
        }
      />
      <Route path="*" element={<ErrorPageContainer />} />
    </RoutesWrapper>
  );
}
export default Routes;
