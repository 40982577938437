import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {
  AddCircleOutline,
  ChevronLeft,
  ChevronRight,
  ContactsOutlined,
  InsertInvitation,
} from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { Drawer as MobileDrawer } from '@mui/material';

import Header from '../Header';
import {
  Drawer,
  DrawerHeader,
  StyledItemButton,
  StyledContainer,
  StyledListItemText,
  StyledListItemButton,
  StyledListItemIcon,
  StyledDrawerBox,
  StyledDrawerContent,
} from './index.styled';
import type { SidebarLayoutProps } from './types';
import { DASHBOARD_ROUTE } from '../../constants/routes';
import { SCREEN_SM } from '../../constants/screenSize';
import { useWindowSize } from '../../utils/windowUtils';

const drawerWidth = 240;

function SidebarLayout({ children }: SidebarLayoutProps) {
  const [open, setOpen] = useState(false);

  const { width } = useWindowSize();

  const isMobileScreen = width < SCREEN_SM;
  const container = document !== undefined ? document.body : undefined;

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const { t } = useTranslation();
  const MenuTitles = [
    {
      text: t('sidebarLayout.SideMenuBookings'),
      link: DASHBOARD_ROUTE.BOOKINGS,
      icon: <InsertInvitation />,
    },
    {
      text: t('sidebarLayout.SideMenuCustomers'),
      link: DASHBOARD_ROUTE.CUSTOMERS,
      icon: <ContactsOutlined />,
    },
    {
      text: t('sidebarLayout.SideMenuCommissions'),
      link: DASHBOARD_ROUTE.COMMISSIONS,
      icon: <PaidOutlinedIcon />,
    },
    {
      text: t('sidebarLayout.SideMenuOperators'),
      link: DASHBOARD_ROUTE.OPERATORS,
      icon: <ManageAccountsOutlinedIcon />,
    },
  ];

  const drawerContent = (
    <StyledDrawerContent>
      <List className="List">
        <Link to={'/'}>
          <StyledItemButton className="ListItemButton">
            <AddCircleOutline color="primary" />
          </StyledItemButton>
        </Link>
        {MenuTitles.map((item, index) => (
          <ListItem key={index} disablePadding className="ListItem">
            <NavLink
              to={item.link}
              className={({ isActive }) => `Link ${isActive ? 'Active' : ''}`}
            >
              <StyledListItemButton open={open} className="ListItemButton">
                <StyledListItemIcon open={open} className="ListItemIcon">
                  {item.icon}
                </StyledListItemIcon>
                <StyledListItemText open={open} primary={item.text} className="ListItemText" />
              </StyledListItemButton>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </StyledDrawerContent>
  );

  const mobileLayout = (
    <>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <StyledDrawerBox component="nav" aria-label="mailbox folders">
        <MobileDrawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <Box>{drawerContent}</Box>
        </MobileDrawer>
      </StyledDrawerBox>
      <Box component="main" className="MobileLayoutMain">
        {children}
      </Box>
    </>
  );

  const desktopLayout = (
    <>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader open={open} className="SideBar-Toggle">
          <IconButton onClick={handleDrawerToggle}>
            {!open ? <ChevronRight /> : <ChevronLeft className="ChevronLeftIcon" />}
          </IconButton>
        </DrawerHeader>
        <Divider className="SideBar-Divider" />
        {drawerContent}
      </Drawer>
      <Box component="main" className="DesktopLayoutMain">
        {children}
      </Box>
    </>
  );

  return (
    <StyledContainer>
      <Header handleDrawerToggle={handleDrawerToggle} />

      {isMobileScreen ? mobileLayout : desktopLayout}
    </StyledContainer>
  );
}

export default SidebarLayout;
