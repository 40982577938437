import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AuthProvider } from '../../contexts/AuthContext';
import { BookingProvider } from '../../contexts/BookingContext';
import GlobalStyles from '../../themes/globalStyles';

import theme from '../../themes/theme';
import { SearchProvider } from '../../contexts/SearchContext';
import { RentalProvider } from '../../contexts/RentalContext';
import { CommissionProvider } from '../../contexts/CommissionContext';
import { CustomerProvider } from '../../contexts/CustomerContext';
import { OperatorProvider } from '../../contexts/OperatorsContext';
import { LanguageProvider } from '../../contexts/LanguageContext';

import Routes from '../../components/Routes';

function AppContainer() {
  return (
    <AppState>
      <ThemeProvider theme={theme}>
        <SCThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Router>
            <Routes />
          </Router>
        </SCThemeProvider>
      </ThemeProvider>
    </AppState>
  );
}

const AppState = ({ children }: any) => {
  return (
    <LanguageProvider>
      <AuthProvider>
        <RentalProvider>
          <SearchProvider>
            <BookingProvider>
              <CommissionProvider>
                <CustomerProvider>
                  <OperatorProvider>{children}</OperatorProvider>
                </CustomerProvider>
              </CommissionProvider>
            </BookingProvider>
          </SearchProvider>
        </RentalProvider>
      </AuthProvider>
    </LanguageProvider>
  );
};

export default AppContainer;
