import { createTheme, lighten, ThemeOptions } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'secondary-action': true;
  }
}

const theme = createTheme({
  typography: {
    h2: {
      fontSize: '1.3rem',
      color: '#858C99',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '1.2rem',
      color: '#2E2D35',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#FFA400',
      contrastText: '#fff',
      dark: '#ffac17',
    },
    secondary: {
      main: '#E80C4D',
      contrastText: '#fff',
    },
    info: {
      main: '#1A73E8',
    },
    success: {
      main: '#2BCE81',
    },
    text: {
      primary: '#2E2D35',
      secondary: '#858C99',
      disabled: '#696969',
    },
    background: {
      paper: '#FFF',
      default: '#F5F7FA',
    },
  },
  extra: {
    border: '#D8DCE2',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: 8,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          padding: '0.7rem 1rem',
          textTransform: 'none',
          fontSize: '1rem',

          ...(ownerState.variant === 'text' && {
            color: theme.palette.info.main,
            '&:hover': {
              backgroundColor: lighten(theme.palette.info.main, 0.96),
            },
          }),
        }),
      },
      variants: [
        {
          props: { variant: 'secondary-action' },
          style: ({ theme: { palette } }) => ({
            backgroundColor: '#DBDBDB',
            color: palette.text.primary,

            '&:hover': {
              backgroundColor: lighten('#DBDBDB', 0.2),
            },
          }),
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        root: () => ({
          borderRadius: 8,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: () => ({
          borderRadius: 8,
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.secondary,
          border: '1px solid #D8DCE2',
          padding: '1rem 1rem',
          borderRadius: 10,
          fontSize: '0.9rem',
          fontWeight: 400,
          maxWidth: 220,
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'h1' && {
            fontSize: '1.5rem',
            fontWeight: 400,
            color: theme.palette.text.primary,
          }),
          ...(ownerState.variant === 'h2' && {
            fontSize: '1.5rem',
            fontWeight: 400,
            color: theme.palette.text.primary,
          }),
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: () => ({
          boxShadow: 'none',
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.severity === 'success' && {
            border: `1px solid ${theme.palette.success.main}3d`,
          }),
          ...(ownerState.severity === 'error' && {
            border: `1px solid ${theme.palette.secondary.main}21`,
          }),
        }),
      },
    },
  },
} as ThemeOptions);

export default theme;
