import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SCREEN_SM } from '../../constants/screenSize';

const StyledDataCard = styled(({ iconColor, ...otherProps }) => <Box {...otherProps} />)`
  min-width: 230px;

  .CardComponent {
    padding: 1.5rem;
  }

  .AmountWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .AmountText {
    font-size: 1.7rem;
    font-weight: bold;
  }

  svg {
    color: ${({ iconColor }) => iconColor};
  }

  .LabelWrapper {
    display: flex;
    align-items: center;
    justify-items: center;

    p {
      color: ${({ theme: { palette } }) => palette.text.secondary};
      padding-left: 0.5rem;
    }
  }

  @media (max-width: ${SCREEN_SM}px) {
    .AmountWrapper {
      margin-top: 10px;
    }

    .AmountText {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .CardComponent {
      padding: 0.8rem 1rem;
    }

    svg {
      width: 20px;
    }
  }
`;

const NewTabButton = styled(Link)`
  border: none;
  background: transparent;
  padding: 0;

  svg {
    fill: ${({ theme: { palette } }) => palette.info.main};
    margin-top: 3px;
  }

  &:hover {
    svg {
      fill: ${({ theme: { palette } }) => palette.info.dark};
    }
  }
`;

export { StyledDataCard, NewTabButton };
