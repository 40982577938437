import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_MD } from '../../constants/screenSize';

const StyledSearchFilters = styled((props) => <Box {...props} />)`
  .CardComponent {
    margin-top: 2rem;
  }

  .MuiFormGroup-root {
    max-height: 336px;
    flex-wrap: nowrap;
    overflow: auto;
  }

  .Filter-Title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  @media (max-width: ${SCREEN_MD}px) {
    .CardComponent {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
`;
export default StyledSearchFilters;
