import { useContext, useState } from 'react';
import {
  Box,
  FormControl,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import * as yup from 'yup';
import Card from '../../../components/Card/index';
import SidebarLayout from '../../../components/SidebarLayout';
import { ActionButtons, ErrorWrapper, StyledContainer } from './index.styled';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FeedbackMsg, FormError } from '../../../contexts/types';
import { OperatorContext, Operator } from '../../../contexts/OperatorsContext';
import { getQueryParam } from '../../../utils/windowUtils';
import { useEffectOnMount } from '../../../hooks';

const NewOperatorContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { operatorList, getOperatorItem, addOrUpdateOperator } = useContext(OperatorContext);
  const [getOperatorError, setGetOperatorError] = useState<string>('');
  const [formErrors, setFormErrors] = useState<FormError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [operator, setOperator] = useState<Operator | null>(null);
  const [newOperatorInfo, setNewOperatorInfo] = useState<Operator | null>(null);
  const [notification, setNotification] = useState<boolean>(false);
  const queryOperatorId = getQueryParam('id');
  const statusOptions = [
    {
      id: 'active',
      name: t('general.activate'),
    },
    {
      id: 'inactive',
      name: t('general.deactivate'),
    },
  ];

  const [saveActionFeedback, setSaveActionFeedback] = useState<FeedbackMsg>({
    severity: 'success',
    text: '',
  });

  useEffectOnMount(() => {
    const handleGetOperator = async () => {
      if (!queryOperatorId) return;
      setLoading(true);
      const response = await getOperatorItem(queryOperatorId!);
      // if (response.error) {
      //   setGetOperatorError(response.error);
      // } else {
      //   setOperator(response.data);
      //   setNewOperatorInfo(response.data)
      // }

      // TODO: Uncomment above code once API is ready and remove next line setOperator(..)
      setOperator(operatorList!.results[0]);
      setNewOperatorInfo(operatorList!.results[0]);
      setLoading(false);
    };
    handleGetOperator();
  });

  if (loading) {
    return (
      <SidebarLayout>
        <Box className="LoadingWrapper">
          <CircularProgress color="primary" />
        </Box>
      </SidebarLayout>
    );
  }

  if (getOperatorError) {
    return (
      <SidebarLayout>
        <ErrorWrapper>
          <Typography variant="body1">{getOperatorError}</Typography>
          <Button variant="contained" onClick={() => navigate(-1)}>
            {t('general.back')}
          </Button>
        </ErrorWrapper>
      </SidebarLayout>
    );
  }

  const operatorInfoSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('operators.addOperator.name') })),
    lastName: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('operators.addOperator.lastName') })),
    emailAddress: yup
      .string()
      .email(t('errors.validEmail'))
      .required(t('errors.inputIsRequired', { field: t('operators.addOperator.email') })),
    status: yup.string().required(t('errors.inputIsRequired', { field: t('general.state') })),
  });

  const handleChange = (key: string, value: string | number | null) => {
    setNewOperatorInfo({ ...newOperatorInfo, [key]: value } as Operator);
  };

  const handleSaveOrUpdateOperator = async () => {
    setSaveActionFeedback({ severity: 'success', text: '' });
    operatorInfoSchema
      .validate(newOperatorInfo, { abortEarly: false })
      .then(async (valid) => {
        if (valid) {
          const response = await addOrUpdateOperator(newOperatorInfo!);
          if (response.error) {
            setSaveActionFeedback({ severity: 'error', text: response.error });
          } else {
            setOperator(response.data);
            setNewOperatorInfo(response.data);

            setSaveActionFeedback({
              severity: 'success',
              text: newOperatorInfo?.id
                ? t('addOperator.updateSuccess')
                : t('addOperator.createSuccess'),
            });

            setTimeout(() => setSaveActionFeedback({ severity: 'success', text: '' }), 2000);
          }
        }
      })
      .catch((err) => {
        const formErrors = err.inner.reduce((prevErrors: any, currentError: any) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        setFormErrors(formErrors);
      });
  };

  return (
    <SidebarLayout>
      <StyledContainer>
        <Grid>
          {saveActionFeedback.text && (
            <Alert className="AlertText" severity={saveActionFeedback.severity}>
              {saveActionFeedback.text}
            </Alert>
          )}
          <Card padding="1.5rem">
            <Grid item md={12}>
              <Box className="IconWrapper">
                <ManageAccountsOutlinedIcon />
                <Typography variant="subtitle1" className="Typography">
                  {operator
                    ? `${operator.firstName} ${operator.lastName}`
                    : t('operators.addOperator.title')}
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={newOperatorInfo?.firstName || ''}
                      label={t('operators.addOperator.name')}
                      onChange={({ target }) => handleChange('firstName', target.value)}
                      error={!!formErrors?.firstName}
                      helperText={formErrors?.firstName}
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl size="medium" fullWidth>
                      <TextField
                        value={newOperatorInfo?.lastName || ''}
                        label={t('operators.addOperator.lastName')}
                        onChange={({ target }) => handleChange('lastName', target.value)}
                        error={!!formErrors?.lastName}
                        helperText={formErrors?.lastName}
                        fullWidth
                        margin="dense"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl size="medium" fullWidth>
                      <TextField
                        value={newOperatorInfo?.emailAddress || ''}
                        onChange={({ target }) => handleChange('emailAddress', target.value)}
                        error={!!formErrors?.emailAddress}
                        helperText={formErrors?.emailAddress}
                        label={t('operators.addOperator.email')}
                        fullWidth
                        margin="dense"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl size="medium" className="Select" fullWidth>
                      <InputLabel id="status-label">{t('general.state')}</InputLabel>
                      <Select
                        labelId="status-label"
                        value={`${newOperatorInfo?.status}` || ''}
                        defaultValue=""
                        label={t('general.state')}
                        onChange={({ target }) => handleChange('status', target.value)}
                        error={!!formErrors?.status}
                      >
                        {statusOptions.map((status, index) => {
                          return (
                            <MenuItem key={index} value={status.id}>
                              {status.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="Checkbox"
                          value={notification}
                          onChange={() => setNotification(!notification)}
                        />
                      }
                      label={t('general.notification')}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Card>
        </Grid>
        <ActionButtons>
          <Button variant="secondary-action" onClick={() => navigate(-1)}>
            {t('general.back')}
          </Button>
          <Button
            variant="contained"
            disabled={
              !newOperatorInfo?.firstName ||
              !newOperatorInfo?.lastName ||
              !newOperatorInfo?.emailAddress ||
              !newOperatorInfo?.status
            }
            onClick={handleSaveOrUpdateOperator}
          >
            {t('general.save')}
          </Button>
        </ActionButtons>
      </StyledContainer>
    </SidebarLayout>
  );
};

export default NewOperatorContainer;
