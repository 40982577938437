import { getAPI } from '../API';
import { getAuthHeader } from '../utils/storageUtils';

export const handleSignIn = async (email: string, password: string, remember: boolean) => {
  const api = getAPI();
  return await api.post('/sign-in', { email, password, remember });
};

export const handleSignUp = async (agencyGuid: string, password: string) => {
  const api = getAPI();
  return await api.post('/sign-up', { agencyGuid, password });
};

export const handleGetProfile = async () => {
  const api = getAPI();
  return await api.get('/profile', await getAuthHeader());
};

export const handleChangePassword = async (oldPassword: string, newPassword: string) => {
  const api = getAPI();
  return await api.post('/change-password', { oldPassword, newPassword });
};

export const handleSendResetPasswordRequest = async (email: string) => {
  const api = getAPI();
  return await api.post('/reset-password-request', { email });
};

export const handleResetPassword = async (token: string, password: string) => {
  const api = getAPI();
  return await api.post('/reset-password', { token, password });
};
