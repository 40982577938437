import { Typography } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { StyledDateHandler, DateHandlerBtn, BackToTodayBtn } from './index.styles';
import type { DatesHandlerProps } from './types';
import { setDateFormat } from '../../utils/dataUtils';
import { useTranslation } from 'react-i18next';

const DateHandler = ({
  date,
  isCurrentDate,
  className = '',
  getCurrentDate,
  handlePrev,
  handleNext,
}: DatesHandlerProps) => {
  const { t } = useTranslation();

  return (
    <StyledDateHandler className={className}>
      <DateHandlerBtn onClick={handlePrev}>
        <ChevronLeftRoundedIcon />
      </DateHandlerBtn>

      <Typography className="Date">
        {!isCurrentDate && (
          <BackToTodayBtn variant="outlined" onClick={getCurrentDate}>
            {t('home.futureBookings.today')}
          </BackToTodayBtn>
        )}
        {setDateFormat(date)}
      </Typography>
      <DateHandlerBtn onClick={handleNext}>
        <ChevronRightRoundedIcon />
      </DateHandlerBtn>
    </StyledDateHandler>
  );
};
export default DateHandler;
