import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_MD } from '../../../constants/screenSize';

const StyledContainer = styled((props) => <Box {...props} />)`
  max-width: 1200px;
  border-radius: 10px;

  .IconWrapper {
    display: flex;
    justify-content: start;
    margin: 20px 0;
    h6 {
      margin-left: 20px;
    }
  }

  .Select {
    margin-top: 8px;
  }
`;

const ActionButtons = styled((props) => <Box {...props} />)`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  button {
    min-width: 200px;
    margin-left: 10px;
  }

  @media (max-width: ${SCREEN_MD}px) {
    justify-content: center;
    flex-direction: column;

    button {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`;

const ErrorWrapper = styled((props) => <Box {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  max-width: 525px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  p {
    color: ${({ theme: { palette } }) => palette.text.primary};
    margin-bottom: 20px;
  }

  button {
    min-width: 150px;
  }
`;

export { ActionButtons, StyledContainer, ErrorWrapper };
