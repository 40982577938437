import type { NameAndId } from '../contexts/types';

/* eslint-disable no-useless-escape */
export function isValidEmail(email: string) {
  const mailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return mailRegex.test(email);
}

export function isValidPassword(password: string) {
  var passwordRegex = new RegExp('^(?=.{8,})');
  return passwordRegex.test(password);
}

export function areEqualPasswords(password: string, repeatPassword: string) {
  let equalPasswordsValidation = false;
  if (password === repeatPassword) {
    equalPasswordsValidation = true;
  }
  return equalPasswordsValidation;
}

const findDocumentType = (
  documentTypes: NameAndId[],
  docTypeId?: number,
): NameAndId | undefined => {
  if (!documentTypes || !docTypeId) return;
  return documentTypes.find((type) => type.id === docTypeId);
};

const isValidCUIT = (cuit: string) => {
  if (!cuit) return false;
  if (cuit.length !== 11) {
    return false;
  }

  let total = 0;
  let digits = cuit.split('') as string[];
  let digit = parseInt(digits.pop() as string);

  for (let i = 0; i < digits.length; i++) {
    total += (digits as [])[9 - i] * (2 + (i % 6));
  }

  let verify = 11 - (total % 11);
  if (verify === 11) {
    verify = 0;
  } else if (verify === 10) {
    verify = 9;
  }

  return digit === verify;
};

const isValidDNI = (dni: string) => {
  const regex = /^\d+$/;

  if (regex.test(dni)) {
    if (dni.length >= 6 && dni.length <= 8) return true;
  }

  return false;
};

export const cleanRUT = (rut: string) => {
  return typeof rut === 'string' ? rut.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() : '';
};

const isValidRUT = (rut: string) => {
  if (!rut || rut.length <= 1) return false;

  let value = cleanRUT(rut);

  // Divide el valor ingresado en dígito verificador y resto del RUT.
  const body = value.slice(0, -1);
  let verifyDigit = value.slice(-1).toUpperCase();

  // Si no cumple con el mínimo ej. (n.nnn.nnn)
  if (body.length < 7) {
    return false;
  }

  // Calcular Dígito Verificador "Método del Módulo 11"
  let sum = 0;
  let multiple = 2;

  // Para cada dígito del Cuerpo
  for (let i = 1; i <= body.length; i++) {
    // Obtener su Producto con el Múltiplo Correspondiente

    let index = multiple * Number(value.charAt(body.length - i));

    // Sumar al Contador General
    sum = sum + index;

    // Consolidar Múltiplo dentro del rango [2,7]
    if (multiple < 7) {
      multiple = multiple + 1;
    } else {
      multiple = 2;
    }
  }

  // Calcular Dígito Verificador en base al Módulo 11
  let expectedDigit = `${11 - (sum % 11)}`;

  // Casos Especiales (0 y K)
  verifyDigit = verifyDigit === 'K' ? '10' : verifyDigit;
  verifyDigit = verifyDigit === '0' ? '11' : verifyDigit;

  // Validar que el Cuerpo coincide con su Dígito Verificador

  if (expectedDigit !== verifyDigit) {
    return false;
  } else {
    return true;
  }
};

export const isValidDocument = (documentTypes: NameAndId[], docTypeId?: number, docId?: string) => {
  const doc = findDocumentType(documentTypes, docTypeId);
  if (!doc) return true;
  if (!docId) return false;

  const documentString = doc.name.toUpperCase();

  switch (documentString) {
    case 'CUIT':
    case 'CUIL':
      return isValidCUIT(docId);
    case 'DNI':
      return isValidDNI(docId);
    case 'RUT':
      return isValidRUT(docId);
    default:
      return true;
  }
};
