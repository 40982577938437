import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledSearchForm = styled(({ isDialog, ...otherProps }) => <Box {...otherProps} />)`
  .CardComponent {
    ${({ isDialog }) => (isDialog ? 'border: none;' : '')}
    ${({ isDialog }) => (isDialog ? 'padding: 0;' : '')}
  }

  .FormSelect {
    margin-top: 8px;
  }

  .DriverAge {
    margin: 0.4rem 0 01rem;
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  .CommercialAgreement-Select {
    margin-bottom: 4px;
  }

  @media (max-width: 360px) {
    .DateTime-Col {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
`;
export default StyledSearchForm;
