import { getAPI } from '../API';
import type { CommissionStatusEnum } from '../contexts/CommissionContext';
import type { AscDesc } from '../contexts/types';
import { getAuthHeader } from '../utils/storageUtils';

export const handleGetCommissions = async (
  offset: number,
  limit: number,
  sortBy: string | null,
  sortDirection: AscDesc | null,
  fromDate: Date | null,
  toDate: Date | null,
  status: CommissionStatusEnum,
) => {
  const api = getAPI();
  const sortByInfo = sortBy ? `?sortBy=${sortBy}` : '';
  const sortDirectionInfo = sortDirection ? `?sortDirection=${sortDirection}` : '';
  const from = sortBy ? `?from=${fromDate}` : '';
  const to = sortBy ? `?to=${toDate}` : '';

  return await api.get(
    `/commissions?offset=${offset}&limit=${limit}${sortByInfo}${sortDirectionInfo}${from}${to}&status=${status}`,
    await getAuthHeader(),
  );
};

export const handleGetCommissionItem = async (id: string) => {
  const api = getAPI();
  return await api.get(`/commissions?id=${id}`, await getAuthHeader());
};

export const handleGetCommissionSummary = async () => {
  const api = getAPI();
  return await api.get(`/commissions/summary`, await getAuthHeader());
};
