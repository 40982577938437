import Header from '../Header';
import { StyledContainer } from './index.styled';
import type { MainLayoutProps } from './types';

function MainLayout({ children }: MainLayoutProps) {
  return (
    <StyledContainer>
      <Header />
      {children}
    </StyledContainer>
  );
}

export default MainLayout;
