import dayjs, { Dayjs } from 'dayjs';
import type {
  BusinessHours,
  Holiday,
  PlaceAttentionSchedule,
  Schedule,
} from '../contexts/RentalContext';
import type { Place } from '../contexts/types';

const DEFAULT_GAP = 0;
const DEFAULT_GAP_FOR_BOOKING_TIME = 15;
const AFTER_HOURS_MAX_MINUTES = 2147483647;
export const DEFAULT_MIN_DAYS = 0;

export const getSchedule = (placeSchedules: PlaceAttentionSchedule[], branchOfficeId?: number) => {
  if (!branchOfficeId) return null;
  const schedulesForBranch = placeSchedules.find((s) => s.id === branchOfficeId);
  return schedulesForBranch || null;
};

const areSchedulesConfigured = (scheduleConf: PlaceAttentionSchedule | null) => {
  if (!scheduleConf) return false;
  const schedules = scheduleConf.schedule as Schedule;

  for (const day in schedules) {
    if (schedules[day as keyof Schedule].length > 0) return true;
  }

  return false;
};

const getScheduleForDay = (
  date: Date,
  schedulesConf: PlaceAttentionSchedule | null,
  holidays: Holiday[],
) => {
  if (!schedulesConf || !date) return null;
  const schedules = schedulesConf.schedule;
  const scheduledDates = schedules.scheduleDates;

  if (scheduledDates) {
    const specificScheduleForDay = scheduledDates.find((sch) =>
      dayjs(sch.date).isSame(date, 'day'),
    );
    if (specificScheduleForDay) return specificScheduleForDay.range;
  }

  const branchOfficeId = schedulesConf.id;
  const holiday = holidays.find(
    (h) => h.date === dayjs(date).format('YYYY-MM-DD') && h.ids.includes(branchOfficeId),
  );
  if (holiday) return schedules['holiday'];

  return schedules[dayjs(date).format('dddd').toLowerCase() as keyof Schedule];
};

const stillHasAvailableTime = (from: Dayjs, to: Dayjs) => {
  return from.isBefore(to, 'minute');
};

const getHoursFromDay = (
  type: 'pickUp' | 'dropOff',
  hourlyFrom: string,
  hourlyTo: string,
  gap: number,
  afterHoursMaxMinutes: number | null,
) => {
  const hourFrom = dayjs()
    .hour(Number(hourlyFrom.split(':')[0]))
    .minute(Number(hourlyFrom.split(':')[1]));
  const hourTo = dayjs()
    .hour(Number(hourlyTo.split(':')[0]))
    .minute(Number(hourlyTo.split(':')[1]));

  let finalFrom, finalTo;

  if (afterHoursMaxMinutes === AFTER_HOURS_MAX_MINUTES) {
    finalFrom = dayjs().hour(0).minute(0);
    finalTo = dayjs().hour(23).minute(45);
  } else if (afterHoursMaxMinutes) {
    const modifiedFrom =
      type === 'dropOff' ? hourFrom.clone().add(afterHoursMaxMinutes * -1, 'm') : hourFrom.clone();
    const modifiedTo = hourTo.clone().add(afterHoursMaxMinutes, 'm');

    finalFrom = modifiedFrom.isBefore(hourFrom, 'd')
      ? hourFrom.clone().hour(0).minute(0)
      : modifiedFrom;
    finalTo = modifiedTo.isAfter(hourTo, 'd') ? hourTo.clone().hour(23).minute(45) : modifiedTo;
  } else {
    finalFrom = hourFrom;
    finalTo = hourTo;
  }

  const hours = [];
  hours.push(
    `${String(finalFrom.hour()).padStart(2, '0')}:${String(finalFrom.minute()).padStart(2, '0')}`,
  );

  while (stillHasAvailableTime(finalFrom, finalTo)) {
    finalFrom = finalFrom.add(gap, 'm');
    if (finalFrom.isAfter(finalTo, 'day')) continue;
    hours.push(
      `${String(finalFrom.hour()).padStart(2, '0')}:${String(finalFrom.minute()).padStart(2, '0')}`,
    );
  }

  hours.push(
    `${String(finalTo.hour()).padStart(2, '0')}:${String(finalTo.minute()).padStart(2, '0')}`,
  );
  return hours.sort();
};

const getTimeZoneDiff = (localOffset: string, branchOffset: string) => {
  const local = `${localOffset.split(':')[0]}.${localOffset.split(':')[1]}`;
  const branch = `${branchOffset.split(':')[0]}.${branchOffset.split(':')[1]}`;
  const result = Number(branch) - Number(local);

  if (Number.isInteger(result)) return result;

  // used to deal with timezones like 09:30,  09:45.
  const splitResult = result.toFixed(2).toString().split('.');
  const decimalToMin = Number(splitResult[1]) / 60;

  return Number(splitResult[0] + '.' + decimalToMin.toString().split('.')[1]);
};

const getPickUpTime = (
  pickUpIsToday: boolean,
  openedFrom: string,
  branchUtcOffset: string,
  minutesBetween: number,
  hoursFromNow: number,
  afterHoursMaxMinutes: number | null,
) => {
  if (!pickUpIsToday && !afterHoursMaxMinutes) return openedFrom;

  const utcOffsetDiff = getTimeZoneDiff(dayjs().format('Z'), branchUtcOffset);

  if (!pickUpIsToday && afterHoursMaxMinutes) {
    const dummyDate = dayjs()
      .hour(Number(openedFrom.split(':')[0]))
      .minute(Number(openedFrom.split(':')[1]));
    const modifiedTime = dummyDate.clone().add(utcOffsetDiff / 60 + afterHoursMaxMinutes * -1, 'm');

    const time = modifiedTime.isBefore(dummyDate, 'd')
      ? dummyDate.clone().hour(0).minute(0)
      : modifiedTime;
    const minsDiff = minutesBetween - (time.minute() % minutesBetween);

    return dayjs(time).add(minsDiff, 'minutes').format('HH:mm');
  }

  const tentativeHour = dayjs().add(utcOffsetDiff + hoursFromNow, 'h');
  const minOpenFrom = openedFrom.split(':')[0];
  const minsDiff = minutesBetween - (tentativeHour.minute() % minutesBetween);
  const tentativeFrom = dayjs(tentativeHour).add(minsDiff, 'minutes').format('HH:mm');

  return dayjs(tentativeHour).format('HH') >= minOpenFrom ? tentativeFrom : openedFrom;
};

interface CalculateHours {
  pickUpDate: Date;
  dropOffDate: Date;
  pickUpBranchOfficeId?: number;
  dropOffBranchOfficeId?: number;
  schedules: PlaceAttentionSchedule[];
  holidays: Holiday[];
}

export const getPickDropHours = ({
  pickUpDate,
  dropOffDate,
  pickUpBranchOfficeId,
  dropOffBranchOfficeId,
  schedules,
  holidays,
}: CalculateHours) => {
  let pickUpHours = [] as string[];
  let dropOffHours = [] as string[];

  if (pickUpDate && dropOffDate) {
    const pickUpSchedules = getSchedule(schedules, pickUpBranchOfficeId);
    const dropOffSchedules = getSchedule(schedules, dropOffBranchOfficeId);

    const pickUpSchedulesConfigured = areSchedulesConfigured(pickUpSchedules);
    const dropOffSchedulesConfigured = areSchedulesConfigured(dropOffSchedules);

    if (pickUpSchedulesConfigured) {
      const pickUpRanges = getScheduleForDay(pickUpDate, pickUpSchedules, holidays);
      const pickUpIsToday = dayjs(pickUpDate).isSame(dayjs(), 'day');
      const afterHoursConfig = pickUpSchedules?.allowAfterHours
        ? pickUpSchedules.afterHoursMaxMinutes
        : null;
      const hoursGap = pickUpSchedules?.gap || DEFAULT_GAP;
      const minutesBetween = pickUpSchedules?.gapForBookingTime || DEFAULT_GAP_FOR_BOOKING_TIME;

      pickUpHours =
        pickUpRanges
          ?.map((timeRange) => {
            const pickUpTime = getPickUpTime(
              pickUpIsToday,
              (timeRange as BusinessHours).openedFrom,
              pickUpSchedules?.timezoneUTCOffset || '00:00:00',
              minutesBetween,
              hoursGap,
              afterHoursConfig,
            );

            const hoursFromDay = getHoursFromDay(
              'pickUp',
              pickUpTime,
              (timeRange as BusinessHours).openedTo,
              minutesBetween,
              afterHoursConfig,
            );

            return hoursFromDay;
          })
          .flat() || [];
    }

    if (dropOffSchedulesConfigured) {
      const dropOffRanges = getScheduleForDay(dropOffDate, dropOffSchedules, holidays);
      const afterHoursConfig = dropOffSchedules?.allowAfterHours
        ? dropOffSchedules.afterHoursMaxMinutes
        : null;
      dropOffHours =
        dropOffRanges
          ?.map((timeRange) =>
            getHoursFromDay(
              'dropOff',
              (timeRange as BusinessHours).openedFrom,
              (timeRange as BusinessHours).openedTo,
              dropOffSchedules ? dropOffSchedules.gapForBookingTime : DEFAULT_GAP_FOR_BOOKING_TIME,
              afterHoursConfig,
            ),
          )
          .flat() || [];
    }
  }

  // removal of possible duplicates
  pickUpHours = [...new Set(pickUpHours)];
  dropOffHours = [...new Set(dropOffHours)];

  return { pickUpHours, dropOffHours };
};

export const getCloseDatesByBranch = (
  holidays: Holiday[],
  attentionSchedule: PlaceAttentionSchedule[] | null,
  branchOfficeId?: number,
) => {
  if (branchOfficeId && attentionSchedule) {
    const schedule = getSchedule(attentionSchedule, branchOfficeId)?.schedule;
    const closeDays = schedule?.closedDays.map((d) => new Date(d)) || [];

    const holidaysByBranch = holidays
      .filter((h) => h.ids.length === 0 || h.ids.includes(branchOfficeId))
      ?.map((h) => new Date(h.date));
    const holidayDays = schedule?.holiday.length === 0 ? holidaysByBranch : [];

    return [...closeDays, ...holidayDays];
  }
  return [];
};

export const isDayOfTheWeekAvailable = (schedule: Schedule, day: Date) => {
  if (!day) return false;

  const dayOfTheWeek = dayjs(day).day();
  switch (dayOfTheWeek) {
    case 0:
      return schedule.sunday.length > 0;
    case 1:
      return schedule.monday.length > 0;
    case 2:
      return schedule.tuesday.length > 0;
    case 3:
      return schedule.wednesday.length > 0;
    case 4:
      return schedule.thursday.length > 0;
    case 5:
      return schedule.friday.length > 0;
    case 6:
      return schedule.saturday.length > 0;
    default:
      return true;
  }
};

const getLastOpenedForDay = (daySchedules: BusinessHours[], afterHoursConf: number) => {
  let originalOpenedTo = daySchedules[daySchedules.length - 1]['openedTo']
    .slice(0, -3)
    .replace(':', '');
  if (!afterHoursConf) return originalOpenedTo;

  const openedToHour = originalOpenedTo.slice(0, -2);
  const openedToMinutes = originalOpenedTo.slice(2);

  const dummyDate = dayjs().hour(Number(openedToHour)).minute(Number(openedToMinutes));
  const dummyDateModified = dummyDate.clone().add(afterHoursConf, 'm');

  if (dummyDateModified.isAfter(dummyDate, 'day')) return '2359';
  return dummyDateModified.format('HHmm');
};

const isSameDay = (day: Date) => {
  return dayjs().isSame(day, 'day');
};

export const isTimeAvailable = (
  day: Date,
  daySchedules: BusinessHours[],
  originalGap: number,
  type: 'start' | 'end',
  scheduleConf: PlaceAttentionSchedule,
) => {
  if (daySchedules.length === 0 || !day) return false;
  let limitHour;
  let hoursToAdd = originalGap;
  const sameDay = isSameDay(day);
  const afterHoursConf = scheduleConf.allowAfterHours ? scheduleConf.afterHoursMaxMinutes / 60 : 0;

  switch (type) {
    case 'start':
      if (afterHoursConf !== 0 && !sameDay) hoursToAdd = Number(afterHoursConf) * -1;
      const modifiedDay = sameDay ? dayjs(day).add(hoursToAdd, 'h') : day;
      // if date was changed after adding the hoursToAdd, day is not available.
      if (dayjs(modifiedDay).isAfter(day, 'day')) return false;
      limitHour = Number(dayjs(modifiedDay).format('HHmm'));
      break;
    case 'end':
      // while end date is not today we want to check using morning hours. the first one found in the schedule.
      const firstOpenedFrom = daySchedules[0]['openedFrom'].split(':')[0];
      limitHour = isSameDay(day) ? dayjs(day).format('HHmm') : firstOpenedFrom;
      break;
  }

  const lastOpenedTo = getLastOpenedForDay(daySchedules, scheduleConf.afterHoursMaxMinutes);
  return Number(limitHour) > Number(lastOpenedTo) ? false : true;
};

export const isDayAvailable = (
  day: Date,
  holidays: Holiday[],
  attentionSchedule: PlaceAttentionSchedule[] | null,
  branchOfficeId?: number,
) => {
  if (!attentionSchedule || !day) return false;

  const closeDates = getCloseDatesByBranch(holidays, attentionSchedule, branchOfficeId).map((d) =>
    dayjs(d).format('DD/MM/YY'),
  );
  const scheduleConfig = getSchedule(attentionSchedule, branchOfficeId);
  const dayOfTheWeekOpen =
    scheduleConfig && isDayOfTheWeekAvailable(scheduleConfig.schedule, dayjs(day).toDate());

  if (!dayOfTheWeekOpen || closeDates.includes(dayjs(day).format('DD/MM/YY'))) {
    return false;
  }

  return true;
};

export const getNextAvailableDate = (
  date: Date,
  holidays: Holiday[],
  attentionSchedule: PlaceAttentionSchedule[] | null,
  branchOfficeId?: number,
) => {
  if (!attentionSchedule || !date) return date;

  let day = dayjs(date).toDate();
  let dayAvailable = isDayAvailable(day, holidays, attentionSchedule, branchOfficeId);

  while (!dayAvailable) {
    day = dayjs(day).add(1, 'd').toDate();
    dayAvailable = isDayAvailable(day, holidays, attentionSchedule, branchOfficeId);
  }

  return day;
};

export const getBranchOfficeByPlaceId = (places: Place[], id?: number | null) => {
  return places.find((p) => p.id === id)?.branchOfficeId;
};

export const getBookingDuration = (
  dateFrom: Date,
  hourFrom: string,
  dateTo: Date,
  hourTo: string,
) => {
  if (!dateFrom || !dateTo) return 0;

  const from = dayjs({
    year: dayjs(dateFrom).year(),
    month: dayjs(dateFrom).month(),
    day: dayjs(dateFrom).date(),
    hour: Number(hourFrom.split(':')[0]),
    minute: Number(hourFrom.split(':')[1]),
  });
  const to = dayjs({
    year: dayjs(dateTo).year(),
    month: dayjs(dateTo).month(),
    day: dayjs(dateTo).date(),
    hour: Number(hourTo.split(':')[0]),
    minute: Number(hourTo.split(':')[1]),
  });

  return to.diff(from, 'day', true);
};

export const getDefaultEndHour = (dateFrom: Date, hourFrom: string, minDays = DEFAULT_MIN_DAYS) => {
  if (!dateFrom || !hourFrom) return '';

  const pickUpMoment = dayjs({
    year: dayjs(dateFrom).year(),
    month: dayjs(dateFrom).month(),
    day: dayjs(dateFrom).date(),
    hour: Number(hourFrom.split(':')[0]),
    minute: Number(hourFrom.split(':')[1]),
  });

  return pickUpMoment.add(minDays, 'd').format('HH:mm');
};
