import { AttachFile } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { StyledUploadFileBtn } from './index.styles';
import type { UploadFileBtnProps } from './types';

function UploadFileBtn({ text, className = '', onClick }: UploadFileBtnProps) {
  const { t } = useTranslation();

  return (
    <StyledUploadFileBtn variant="text" startIcon={<AttachFile />} className={className}>
      {text || t('general.uploadFiles')}
      <input
        accept="image/*"
        type="file"
        multiple
        onChange={({ target }) => onClick(target.files)}
      />
    </StyledUploadFileBtn>
  );
}
export default UploadFileBtn;
