import { ForwardedRef, forwardRef, useContext, useEffect } from 'react';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { InputAdornment, TextField } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import StyledDatePicker from './index.styled';
import type { CustomDatePickerProps } from './types';
import { LanguageContext } from '../../contexts/LanguageContext';
registerLocale('es-ES', es);
registerLocale('en-EN', en);

const CustomDatePicker = forwardRef(
  (props: CustomDatePickerProps, ref: ForwardedRef<ReactDatePicker<string, undefined>>) => {
    const { lang } = useContext(LanguageContext);

    useEffect(() => {
      if (lang) {
        setDefaultLocale(lang);
      }
    }, [lang]);

    return (
      <StyledDatePicker>
        <DatePicker
          customInput={
            <TextField
              id="outlined-name"
              label={props.label}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayOutlined />
                  </InputAdornment>
                ),
              }}
              margin="dense"
            />
          }
          dateFormat={props.dateFormat || 'dd MMM'}
          ref={ref}
          {...props}
        />
      </StyledDatePicker>
    );
  },
);

export default CustomDatePicker;
