import { Box, Palette } from '@mui/material';
import styled from 'styled-components';
import { OperatorStatusEnum } from '../../contexts/OperatorsContext';

const getStatusColor = (status: OperatorStatusEnum, palette: Palette) => {
  switch (status) {
    case 0: // Confirmed
      return `${palette.success.main}`;
    case 1: // Canceled
      return '#DE0050';
    case 2: // Process
      return `${palette.primary.main}`;
  }
  return `${palette.text.secondary}`;
};

const StyledOperatorStatus = styled((props) => <Box {...props} />)`
  display: inline-flex;
  align-items: center;
  width: 100%;
  color: ${({ theme: { palette } }) => palette.text.secondary};

  span {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    display: inline-block;
    background-color: ${({ status, theme: { palette } }) => getStatusColor(status, palette)};
    margin-right: 10px;
  }

  p {
    font-size: 0.9rem;
  }
`;

export default StyledOperatorStatus;
