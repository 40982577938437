import { useContext, useEffect, useState } from 'react';
import { Typography, Box, ListItem, ListItemButton, CircularProgress, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { StyledBadge, TableHeader, CustomList, CustomListBox } from './index.styled';
import Card from '../Card/index';
import { BookingContext } from '../../contexts/BookingContext';
import { setDateFormat, setMoneyFormat } from '../../utils/dataUtils';
import DateHandler from '../DateHandler';
import { BOOKINGS_ROUTE } from '../../constants/routes';
import { useNavigateParams } from '../../hooks';
dayjs.extend(isToday);

function FutureBookings() {
  const [date, setDate] = useState(dayjs().toDate());
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { bookingsByDay, getBookingsByDay } = useContext(BookingContext);
  const { t } = useTranslation();
  const navigateSearch = useNavigateParams();

  const getBookings = async () => {
    setError('');
    setLoading(true);
    const response = await getBookingsByDay(date);
    //if (response.error) setError(response.error); TODO: Uncommented this line to show errors
    setLoading(false);
  };

  useEffect(() => {
    getBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleNextDay = () => {
    const nextDay = dayjs(date).add(1, 'day').toDate();
    setDate(nextDay);
  };

  const handlePrevDay = () => {
    const prevDay = dayjs(date).subtract(1, 'day').toDate();
    setDate(prevDay);
  };

  const getCurrentDate = () => {
    setDate(dayjs().toDate());
  };

  const getListComponent = () => {
    if (loading) {
      return (
        <Box className="CenterContent">
          <CircularProgress size={20} />
        </Box>
      );
    }

    if (error) {
      return (
        <Box className="CenterContent">
          <Typography>{error}</Typography>
          <Button onClick={getBookings}>{t('general.retry')}</Button>
        </Box>
      );
    }

    if (bookingsByDay?.length === 0) {
      return (
        <Box className="CenterContent">
          <Typography className="NoBookings-Text">{t('home.futureBookings.noBookings')}</Typography>
        </Box>
      );
    }

    return bookingsByDay.map(({ id, deliveryPlace, fromDate, customer, price, totalDays }) => (
      <ListItem key={id} disablePadding>
        <ListItemButton
          onClick={() => navigateSearch(BOOKINGS_ROUTE.BOOKING_DETAILS, { id: `${id}` })}
        >
          <CustomListBox className="BookingId">
            <Typography>#{id}</Typography>
          </CustomListBox>
          <Box className="BookingDetails">
            <CustomListBox>
              <Typography className="Title">{deliveryPlace.name}</Typography>
              <Typography>{setDateFormat(fromDate, 'hh:mm')}</Typography>
            </CustomListBox>
            <CustomListBox>
              <Typography
                className="Title"
                noWrap
              >{`${customer.firstName} ${customer.lastName}`}</Typography>
              <Typography noWrap className="CustomerPhone">
                {customer.cellPhone}
              </Typography>
            </CustomListBox>
            <CustomListBox className="BookingPrice">
              <Typography className="Title">{setMoneyFormat(price)}</Typography>
              <Typography>{t('home.futureBookings.days', { count: totalDays })}</Typography>
            </CustomListBox>
          </Box>
        </ListItemButton>
      </ListItem>
    ));
  };

  return (
    <Box>
      <Card padding="2rem 1.6rem">
        <TableHeader>
          <Box className="TableHeader-Title">
            <StyledBadge>
              <Typography>{bookingsByDay?.length || 0}</Typography>
            </StyledBadge>
            <Typography variant="subtitle2">{t('home.futureBookings.title')}</Typography>
          </Box>
          <DateHandler
            date={date}
            isCurrentDate={dayjs(date).isToday()}
            handleNext={handleNextDay}
            handlePrev={handlePrevDay}
            getCurrentDate={getCurrentDate}
          />
        </TableHeader>
        <CustomList>{getListComponent()}</CustomList>
      </Card>
    </Box>
  );
}

export default FutureBookings;
