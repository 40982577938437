import { TableCell } from '@mui/material';
import { TableEmptyWrapper } from './index.styled';
import { TableEmptyProps } from './types';

function TableEmpty({ colSpan, text }: TableEmptyProps) {
  return (
    <TableEmptyWrapper>
      <TableCell colSpan={colSpan} align="center">
        <p>{text}</p>
      </TableCell>
    </TableEmptyWrapper>
  );
}
export default TableEmpty;
