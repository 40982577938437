import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import type { CustomButtonProps } from './types';
import { StyledWrapper, StyledButton, StyledCard } from './index.styled';
import { setMoneyFormat } from '../../utils/dataUtils';
import { useEffectOnMount } from '../../hooks';

function AdditionalItem({
  item,
  excludes,
  selected,
  icon,
  defaultQuantity,
  onClick,
}: CustomButtonProps) {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState<string>(defaultQuantity || '');

  useEffectOnMount(() => {
    if (item.isDefault || item.isRequired) {
      setQuantity('1');
    }
  });

  const showCounter = item.maxQuantityPerBooking > 1;
  const isSelected = selected?.some((i) => i.id === item.id) || item.isRequired;
  const excluded = excludes?.includes(item.id);
  const max = Math.min(item.maxQuantityPerBooking, item.availableStock);

  const handleOnClick = () => {
    if (!onClick || excluded || showCounter) return;
    onClick('1');
  };

  const handleChangeCounter = (value: string) => {
    setQuantity(value);
    onClick && onClick(value);
  };

  return (
    <StyledWrapper>
      <Box className="Item-Description">
        <Tooltip disableFocusListener placement="bottom-end" title={item.description}>
          <InfoOutlinedIcon />
        </Tooltip>
      </Box>
      <StyledButton
        onClick={handleOnClick}
        disabled={excluded || (item.isRequired && !showCounter) || !onClick}
      >
        <StyledCard className={isSelected ? 'Selected' : ''}>
          <Box className="Item-Icon">{icon}</Box>
          <Box>
            <Typography className="Item-Title" align="center" variant="subtitle2" color="initial">
              {item.name}
            </Typography>
          </Box>
          <Box>
            <Typography className="Item-Subtitle" variant="body1" color="initial">
              {`${setMoneyFormat(Math.ceil(item.price), 0)} ${t(
                `checkout.pricePer.${item.isPriceByDay ? 'day' : 'booking'}`,
              )}`}
            </Typography>
            {showCounter && (
              <TextField
                type="number"
                className="Item-Counter"
                onChange={({ target }) => handleChangeCounter(target.value)}
                InputProps={{ inputProps: { min: 0, max } }}
                disabled={excluded || (item.isRequired && quantity === '1')}
                value={quantity}
              />
            )}
          </Box>
        </StyledCard>
      </StyledButton>
    </StyledWrapper>
  );
}

export default AdditionalItem;
