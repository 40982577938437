import { useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import type { CustomAutocompleteProps } from './types';
import { StyledCustomAutocomplete } from './index.styled';

function CustomAutocomplete({
  disablePortal,
  disableClearable,
  placeholder,
  value,
  options,
  noOptionsText,
  startAdornment,
  className = '',
  onChange,
  renderOption,
  getOptionLabel,
}: CustomAutocompleteProps) {
  const [open, setOpen] = useState<boolean>(false);

  const getRenderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        label={placeholder}
        margin="dense"
        InputProps={{
          ...params.InputProps,
          startAdornment,
          endAdornment: (
            <>
              {open ? (
                <ExpandLess className="EndAdornment" />
              ) : (
                <ExpandMore className="EndAdornment" />
              )}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  };

  return (
    <StyledCustomAutocomplete fullWidth className={className}>
      <Autocomplete
        disablePortal={disablePortal}
        disableClearable={disableClearable}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        options={options}
        renderInput={(params) => getRenderInput(params)}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        noOptionsText={noOptionsText}
      />
    </StyledCustomAutocomplete>
  );
}

export default CustomAutocomplete;
