import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Button, Grid, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/CustomTable';
import SidebarLayout from '../../components/SidebarLayout';
import { CustomerContext } from '../../contexts/CustomerContext';
import type { AscDesc } from '../../contexts/types';
import { HeaderWrapper, TableWrapper } from './index.styled';
import { CUSTOMERS_ROUTE } from '../../constants/routes';

function CustomersContainer() {
  const { customerList, getCustomers } = useContext(CustomerContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<AscDesc>('asc');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const headers = [
    { id: 'name', name: t('customers.table.customer') },
    { id: 'emailAddress', name: t('customers.table.email') },
    { id: 'cellPhone', name: t('customers.table.cellphone') },
    { id: 'documentId', name: t('customers.table.document') },
  ];

  const handleGetCustomers = async () => {
    setError('');
    setLoading(true);
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    const response = await getCustomers(offset, limit, sortColumn, sortDirection);
    //   if (response.error) setError(response.error); TODO: uncommented this line to show error
    setLoading(false);
  };

  useEffect(() => {
    handleGetCustomers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sortColumn, sortDirection]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortColumn = (id: string) => {
    if (!sortColumn || sortColumn !== id) {
      setSortColumn(id);
      setSortDirection('asc');
    } else if (sortColumn === id && sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortColumn(null);
      setSortDirection('asc');
    }
  };

  const getTableContent = () => {
    return customerList?.results?.map(
      ({ firstName, lastName, emailAddress, cellPhone, documentId, id }) => (
        <TableRow
          hover
          key={id}
          onClick={() => navigate(`${CUSTOMERS_ROUTE.CUSTOMER_DETAILS}?id=${id}`)}
        >
          <TableCell component="th" scope="row">{`${firstName} ${lastName}`}</TableCell>
          <TableCell component="th" scope="row">
            {emailAddress}
          </TableCell>
          <TableCell component="th" scope="row">
            {cellPhone}
          </TableCell>
          <TableCell component="th" scope="row">
            {documentId}
          </TableCell>
        </TableRow>
      ),
    );
  };

  return (
    <SidebarLayout>
      <HeaderWrapper container>
        <Grid item xs={12} md={6}>
          <Typography variant="h1">{t('customers.title')}</Typography>
        </Grid>
        <Grid item xs={12} md={6} className="RightCol">
          <Button variant="contained" onClick={() => navigate(CUSTOMERS_ROUTE.ADD_CUSTOMER)}>
            {t('customers.newCustomer')}
          </Button>
        </Grid>
      </HeaderWrapper>

      <TableWrapper>
        <CustomTable
          headers={headers}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          emptyTable={!customerList || customerList.results?.length === 0}
          emptyTableText={t('customers.table.noData')}
          loading={loading}
          error={error}
          colSpan={4}
          count={customerList?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          tableContent={getTableContent()}
          handleRetry={handleGetCustomers}
          handleSortColumn={handleSortColumn}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableWrapper>
    </SidebarLayout>
  );
}
export default CustomersContainer;
