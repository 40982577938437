import { Box, ButtonBase } from '@mui/material';
import styled from 'styled-components';

const StyledWrapper = styled((props) => <Box {...props} />)`
  width: 100%;
  position: relative;

  .Item-Description {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;

    svg {
      fill: ${({ theme: { palette } }) => palette.info.main};
      font-size: 1.2rem;
    }
  }
`;

const StyledButton = styled(ButtonBase)(({ disabled }) => ({
  width: '100%',
  cursor: disabled ? 'default' : 'pointer',

  '.Selected': {
    backgroundColor: 'rgb(19, 225, 119, 0.3)',
    border: '1 px solid green',
  },
  '.MuiTypography-root': {
    margin: '5% 0',
    width: '100%',
  },
}));

const StyledCard = styled((props) => <Box {...props} />)`
  background: ${({ theme: { palette } }) => palette.background.default};
  padding: 0.9rem;
  border: ${({ theme }) => `1px solid ${theme.extra.border}`};
  border-radius: 10px;
  min-height: 181px;
  width: 100%;

  .Item-Title {
    font-size: 0.9rem;
    margin: 0;
    margin-top: 4px;
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .Item-Subtitle {
    font-size: 0.8rem;
    color: ${({ theme: { palette } }) => palette.text.secondary};
    margin: 4px 0 6px;
  }

  .Item-Counter {
    width: 70px;

    input {
      padding: 10px 14px;
      height: fit-content;
      text-align: center;
    }
  }

  .Item-Icon svg {
    color: ${({ theme: { palette } }) => palette.text.secondary};
    width: 25px;
    height: auto;
  }
`;

export { StyledWrapper, StyledCard, StyledButton };
