import axios from 'axios';
import axiosRetry from 'axios-retry';

export const getBaseUrl = (): string => {
  let url = process.env.REACT_APP_BACKEND_API;

  if (process.env.REACT_APP_PUBLIC_ID) {
    url += process.env.REACT_APP_PUBLIC_ID;
  }

  return url || '';
};

export const getAPI = (retries = 0) => {
  const client = axios.create({
    baseURL: getBaseUrl(),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
  axiosRetry(client, {
    retries,
    retryDelay: (retryCount) => retryCount * 2000,
  });
  return client;
};
