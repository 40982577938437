import { Grid } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_LG } from '../../constants/screenSize';

const GridContainer = styled((props) => <Grid {...props} />)`
  padding-bottom: 5rem;

  .HomeTitle {
    margin-bottom: 0.3rem;
  }
  .Mobile-SearchForm {
    padding: 0;
    border: none;
  }

  .NoSearchResults {
    max-width: 500px;
    margin: auto;
    text-align: center;
    margin-top: 20%;

    svg {
      width: 54px;
      height: auto;
      color: ${({ theme: { palette } }) => palette.text.secondary};
      opacity: 0.4;
      margin-bottom: 20px;
    }

    p {
      color: ${({ theme: { palette } }) => palette.text.secondary};
    }
  }

  @media (min-width: 900px) and (max-width: ${SCREEN_LG}px) {
    .ResultsWrapper {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
`;

export { GridContainer };
