import { Box, List } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_SM, SCREEN_XS } from '../../constants/screenSize';

const StyledBadge = styled(Box)`
  height: 26px;
  width: 26px;
  border-radius: 100px;
  background-color: ${({ theme: { palette } }) => palette.success.main};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 15px;

  p {
    font-size: 0.9rem;
    font-weight: bold;
  }
`;

const TableHeader = styled((props) => <Box {...props} />)`
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;

  .TableHeader-Title {
    display: flex;
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
  }
`;

const CustomList = styled(List)`
  height: 215px;
  overflow: auto;

  .BookingId {
    margin-right: 20px;

    p {
      padding-top: 2px;
      color: ${({ theme: { palette } }) => palette.info.main};
    }
  }

  .MuiButtonBase-root {
    display: flex;
    align-items: flex-start;
    border-radius: 10px;
    padding: 10px 15px;
  }

  .BookingDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & div {
      padding: 0 5px;
    }
  }

  .CenterContent {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      color: ${({ theme: { palette } }) => palette.text.secondary};
      margin: 10px 0;
    }
  }

  @media screen and (max-width: ${SCREEN_SM}px) {
    max-height: 300px;

    .BookingDetails {
      flex-direction: column;

      & > div {
        display: flex;
      }

      p {
        margin-right: 10px;
        margin-bottom: 4px;
      }
    }
  }

  @media screen and (max-width: ${SCREEN_XS}px) {
    .MuiListItemButton-root {
      flex-direction: column;
    }
  }
`;

const CustomListBox = styled(Box)`
  p {
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }

  &.BookingPrice p {
    text-align: right;
  }

  .CustomerPhone {
    color: ${({ theme: { palette } }) => palette.info.main};
  }

  .Title {
    font-weight: 500;
  }
`;

export { StyledBadge, TableHeader, CustomList, CustomListBox };
