import dayjs from 'dayjs';
import { CommercialAgreement, Holiday, PlaceAttentionSchedule } from '../contexts/RentalContext';
import { SearchParams } from '../contexts/SearchContext';
import { Place } from '../contexts/types';
import { DEFAULT_MIN_DAYS, getNextAvailableDate } from './branchOfficeUtils';
import { setDateFormat } from './dataUtils';

const parseDate = (date: string | null) => {
  if (!date) return null;
  return dayjs(date, 'DD-MM-YYYY').toDate();
};

export const getSearchQueryParams = (queryParams: URLSearchParams) => {
  if (!queryParams) return null;

  const dateFrom = parseDate(queryParams.get('dateFrom'));
  const hourFrom = queryParams.get('hourFrom');
  const pickUpPlaceName = queryParams.get('pickUpPlaceName');
  const pickUpPlaceId = queryParams.get('pickUpPlaceId')
    ? Number(queryParams.get('pickUpPlaceId'))
    : null;
  const dateTo = parseDate(queryParams.get('dateTo'));
  const hourTo = queryParams.get('hourTo');
  const dropOffPlaceName = queryParams.get('dropOffPlaceName');
  const dropOffPlaceId = queryParams.get('dropOffPlaceId')
    ? Number(queryParams.get('dropOffPlaceId'))
    : null;
  const unlimitedKm = queryParams.get('unlimitedKm')
    ? JSON.parse(queryParams.get('unlimitedKm') as string)
    : '';
  const commercialAgreement = queryParams.get('commercialAgreement');
  const promotionCode = queryParams.get('promotionCode');
  const driverAge = queryParams.get('driverAge');

  return {
    dateFrom,
    hourFrom,
    pickUpPlaceName,
    pickUpPlaceId,
    dateTo,
    hourTo,
    dropOffPlaceName,
    dropOffPlaceId,
    unlimitedKm,
    commercialAgreement,
    promotionCode,
    driverAge,
  };
};

export const getDefaultSearchParams = (
  queryParams: any,
  contextParams: SearchParams | null,
  places: Place[],
  holidays: Holiday[],
  attentionSchedule: PlaceAttentionSchedule[],
  minDays = DEFAULT_MIN_DAYS,
) => {
  const qp = queryParams;
  const cp = contextParams;
  const defaultPlace = places?.[0];

  const defaultDateFrom = getNextAvailableDate(
    dayjs().toDate(),
    holidays,
    attentionSchedule,
    defaultPlace?.branchOfficeId,
  );

  const defaultDateTo = getNextAvailableDate(
    dayjs(defaultDateFrom).add(minDays, 'd').toDate(),
    holidays,
    attentionSchedule,
    defaultPlace?.branchOfficeId,
  );

  return {
    dateFrom: qp?.dateFrom || cp?.dateFrom || defaultDateFrom,
    hourFrom: qp?.hourFrom || cp?.hourFrom || '',
    pickUpPlaceName:
      qp?.pickUpPlaceName ||
      cp?.pickUpPlaceName ||
      defaultPlace?.name ||
      defaultPlace.branchOfficeName,
    pickUpPlaceId: qp?.pickUpPlaceId || cp?.pickUpPlaceId || defaultPlace?.id || defaultPlace.id,
    dateTo: qp?.dateTo || cp?.dateTo || defaultDateTo,
    hourTo: qp?.hourTo || cp?.hourTo || '',
    dropOffPlaceName: qp?.dropOffPlaceName || cp?.dropOffPlaceName || defaultPlace?.name || '',
    dropOffPlaceId: qp?.dropOffPlaceId || cp?.dropOffPlaceId || defaultPlace?.id || null,
    unlimitedKm: qp?.unlimitedKm || cp?.unlimitedKm || false,
    commercialAgreement: qp?.commercialAgreement || cp?.commercialAgreement || '',
    promotionCode: qp?.promotionCode || cp?.promotionCode || '',
    driverAge: qp?.driverAge || cp?.driverAge || '',
  };
};

export const parseParamsIntoString = (params: SearchParams, modelId?: number) => {
  const from = `${setDateFormat(params.dateFrom, 'YYYY-MM-DD')} ${params.hourFrom}`;
  const to = `${setDateFormat(params.dateTo, 'YYYY-MM-DD')} ${params.hourTo}`;
  const pickUpId = params.pickUpPlaceId;
  const dropOffId = params.dropOffPlaceId;
  const unlimitedKm = params.unlimitedKm;
  const commercialAgreement = params.commercialAgreement
    ? `&commercialAgreement=${params.commercialAgreement}`
    : '';
  const promotion = params.promotionCode;
  const driverAge = params.driverAge ? `&driverAge=${params.driverAge}` : '';
  const model = modelId ? `&modelId=${modelId}` : '';

  return `from=${from}&to=${to}&fromPlace=${pickUpId}&toPlace=${dropOffId}${model}&unlimitedKm=${unlimitedKm}&promotion=${promotion}${commercialAgreement}${driverAge}`;
};

export function getAvailableCommercialAgreementList(
  params: SearchParams | null,
  agreementList: CommercialAgreement[],
) {
  if (!agreementList || !params?.pickUpPlaceId || !params?.dateFrom || !params?.dateTo) {
    return [];
  }

  return agreementList.filter((agreement) => {
    const isValidOffice =
      !agreement.branchOffices?.length || agreement.branchOffices.includes(params.pickUpPlaceId!);
    const isValidDate = dayjs(params.dateFrom).isBetween(
      agreement.validFrom,
      agreement.validTo,
      'day',
      '[)',
    );
    return isValidOffice && isValidDate;
  });
}
