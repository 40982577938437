import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import OperatorStatus from '../../components/OperatorStatus';
import CustomTable from '../../components/CustomTable';
import SidebarLayout from '../../components/SidebarLayout';
import { OPERATORS_ROUTE } from '../../constants/routes';
import { OperatorContext } from '../../contexts/OperatorsContext';
import { AscDesc } from '../../contexts/types';
import { HeaderWrapper, ActiveToggle, TableWrapper } from './index.styled';

function OperatorsContainer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { operatorList, getOperators, changeOperatorStatus } = useContext(OperatorContext);

  const headers = [
    { id: 'customer', name: t('operators.table.name') },
    { id: 'mail', name: t('operators.table.email') },
    { id: 'state', name: t('general.state') },
    { id: 'active', name: t('general.active') },
  ];

  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<AscDesc>('asc');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const handleGetOperators = async () => {
    setError('');
    setLoading(true);
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    const response = await getOperators(offset, limit, sortColumn, sortDirection);
    //if (response.error) setError(response.error); TODO: uncommented this line to show error
    setLoading(false);
  };

  useEffect(() => {
    handleGetOperators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sortColumn, sortDirection]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortColumn = (id: string) => {
    if (!sortColumn || sortColumn !== id) {
      setSortColumn(id);
      setSortDirection('asc');
    } else if (sortColumn === id && sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortColumn(null);
      setSortDirection('asc');
    }
  };

  const handleChange = async (value: string, id: number) => {
    setLoading(true);
    setError('');
    const response = await changeOperatorStatus(id, value);
    //if (response.error) setError(response.error); TODO: uncommented this line to show error
    setLoading(false);
  };

  const getTableContent = () => {
    return operatorList?.results?.map(
      ({ id, firstName, lastName, emailAddress, status, active }) => (
        <TableRow
          hover
          key={id}
          onClick={() => navigate(`${OPERATORS_ROUTE.EDIT_OPERATOR}?id=${id}`)}
        >
          <TableCell component="th" scope="row">
            {`${firstName} ${lastName}`}
          </TableCell>
          <TableCell component="th" scope="row">
            {emailAddress}
          </TableCell>
          <TableCell component="th" scope="row">
            <OperatorStatus status={status} />
          </TableCell>
          <TableCell component="th" scope="row">
            <FormControl>
              <ActiveToggle
                key={id}
                id="operator-active-select"
                labelId="operator-active-select-label"
                defaultValue={active}
                autoWidth
                value={`${active}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, id: number) =>
                  handleChange(event.target.value, id)
                }
              >
                <MenuItem value="true">
                  <Typography>{t('general.activate')}</Typography>
                </MenuItem>
                <MenuItem value="false">
                  <Typography>{t('general.deactivate')}</Typography>
                </MenuItem>
              </ActiveToggle>
            </FormControl>
          </TableCell>
        </TableRow>
      ),
    );
  };

  return (
    <SidebarLayout>
      <HeaderWrapper container>
        <Grid item xs={12} md={6}>
          <Typography variant="h1">{t('operators.title')}</Typography>
        </Grid>
        <Grid item xs={12} md={6} className="RightCol">
          <Button variant="contained" onClick={() => navigate(OPERATORS_ROUTE.ADD_OPERATOR)}>
            {t('operators.newOperatorButton')}
          </Button>
        </Grid>
      </HeaderWrapper>
      <TableWrapper>
        <CustomTable
          headers={headers}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          emptyTable={!operatorList || operatorList?.results?.length === 0}
          emptyTableText={t('operators.table.noData')}
          loading={loading}
          error={error}
          colSpan={4}
          count={operatorList?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          tableContent={getTableContent()}
          handleRetry={handleGetOperators}
          handleSortColumn={handleSortColumn}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableWrapper>
    </SidebarLayout>
  );
}

export default OperatorsContainer;
