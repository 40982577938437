import { Alert, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Card from '../../components/Card';
import MainLayout from '../../components/MainLayout';
import { AuthContext } from '../../contexts/AuthContext';
import { areEqualPasswords, isValidPassword } from '../../utils/validationUtils';
import { SignUpForm, StyledSignUp } from './index.styled';

function SignUpContainer() {
  const { t } = useTranslation();
  const { signUp } = useContext(AuthContext);
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState<boolean>(false);
  const [signUpError, setSignUpError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const disabledButton =
    !password || !repeatPassword || passwordError || repeatPasswordError || loading;

  const [queryParams] = useSearchParams();
  const agencyGuid = queryParams.get('agencyGuid');
  const email = queryParams.get('email');

  const handlePasswordOnBlur = () => {
    setPasswordError(!isValidPassword(password));
  };

  const handleRepeatPasswordOnBlur = () => {
    setRepeatPasswordError(!areEqualPasswords(password, repeatPassword));
  };

  const handleSubmit = async (e: MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    const { error } = await signUp(email!, agencyGuid!, password);
    if (error) setSignUpError(error);
    setLoading(false);
  };

  return (
    <MainLayout>
      <StyledSignUp>
        <Card>
          <SignUpForm component="form" container rowSpacing={3} onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <Typography variant="h2" color="initial" className="Title">
                {t('signUp.title')}
              </Typography>
            </Grid>
            {signUpError && (
              <Grid item xs={12} className="ErrorWrapper">
                <Alert severity="error">{signUpError}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                error={passwordError}
                label={t('signUp.password')}
                fullWidth
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                type="password"
                onBlur={handlePasswordOnBlur}
                helperText={passwordError && t('errors.wrongPassword')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={repeatPasswordError}
                label={t('signUp.passwordRepeat')}
                fullWidth
                value={repeatPassword}
                onChange={({ target }) => setRepeatPassword(target.value)}
                type="password"
                onBlur={handleRepeatPasswordOnBlur}
                helperText={repeatPasswordError && t('errors.unmatchedPasswords')}
              />
            </Grid>
            <Grid item xs={12} className="SaveButton">
              <LoadingButton
                variant="contained"
                fullWidth
                type="submit"
                disabled={disabledButton}
                loading={loading}
              >
                {t('general.save')}
              </LoadingButton>
            </Grid>
          </SignUpForm>
        </Card>
      </StyledSignUp>
    </MainLayout>
  );
}

export default SignUpContainer;
