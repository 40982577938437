import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_XS } from '../../constants/screenSize';

const StyledContainer = styled((props) => <Box {...props} />)`
  border-radius: 10px;

  .Typography {
    margin-left: 20px;

    @media (max-width: ${SCREEN_XS}px) {
      margin-left: 5px;
    }
  }

  .Typography2 {
    margin-left: 15px;
    @media (max-width: ${SCREEN_XS}px) {
      margin-left: 1px;
    }
  }

  .AccordionDetails {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .TextfieldDetails {
    margin-top: 3%;
  }

  .AccordionItemsWrapper {
    box-shadow: none;
  }

  .IconWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .StyledCard {
    padding: 1rem 1rem;
    min-width: 195px;
  }

  .UploadFiles-Button {
    padding: 0;
    transition: all 0.3s;

    &:hover,
    &:focus {
      background: none;
      color: ${({ theme: { palette } }) => palette.info.dark};
    }
  }
`;

const FilesWrapper = styled((props) => <Box {...props} />)`
  width: 100%;

  .MuiListItemText-primary {
    color: ${({ theme: { palette } }) => palette.text.secondary};
    font-size: 0.9rem;
  }
`;

export { StyledContainer, FilesWrapper };
