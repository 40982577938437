import { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { StyledPriceResume } from './index.styled';
import Card from '../Card';
import { setMoneyFormat } from '../../utils/dataUtils';
import type { PriceItem, PriceItemAssignment } from '../../contexts/types';
import type { PriceSummaryProps } from './types';

function PriceSummary({
  data,
  agreements,
  disabled,
  showBalance,
  onChangePayer,
}: PriceSummaryProps) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<PriceItem | null>(null);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const prices = data?.priceItems || [];

  const ceilNumber = (num?: number) => {
    if (!num) return 0;
    return Math.ceil(num);
  };

  const handleOpenModal = (item: PriceItem, assignedText: string) => {
    setEditItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditItem(null);
  };

  const getAssignedName = (assigned: PriceItemAssignment) => {
    if (assigned === 0) return t('checkout.sideBar.priceResume.agency');
    return t('checkout.sideBar.priceResume.client');
  };

  const getClassColor = (balance: number) => {
    if (showBalance) {
      if (balance > 0) return 'Red';
      return 'Green';
    }
    return '';
  };

  const getPayerTypeComponent = (item: PriceItem) => {
    const assignedText = getAssignedName(item.assigned);
    return (
      <Box className="PayerAssignment-Wrapper">
        <Typography>
          {`${assignedText}`}
          {!disabled && ' -'}
        </Typography>
        {!disabled && (
          <Button onClick={() => handleOpenModal(item, assignedText)}>
            <>{t('checkout.sideBar.priceResume.changePayer')}</>
          </Button>
        )}
      </Box>
    );
  };

  const getRentAccordion = () => {
    // Type Booking is 0
    const totalPrice = _.sumBy(prices, (i) => {
      if (i.type === 0) return i.price;
      return 0;
    });

    const rentPriceItems = prices.filter((i) => i.type === 0);
    if (rentPriceItems.length === 0) return null;

    return (
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="rent-price-content"
          id="rent-price-header"
          className="AccordionHeader"
        >
          <Box className="AccordionHeader-Content">
            <Typography>{t('checkout.sideBar.priceResume.rent')}</Typography>
            <Typography>{setMoneyFormat(Math.ceil(totalPrice), 0)}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="AccordionDetails">
          {rentPriceItems.map((item, index) => {
            return (
              <Box key={index} className="AccordionDetails-Item">
                <Box>
                  <Typography variant="body1" color="initial">
                    {item.description}
                  </Typography>
                  {getPayerTypeComponent(item)}
                </Box>
                <Typography variant="body1" color="initial">
                  {setMoneyFormat(Math.ceil(item.price), 0)}
                </Typography>
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const getInsuranceAccordion = () => {
    // Type Insurance is 16
    const totalPrice = _.sumBy(prices, (i) => {
      if (i.type === 16) return i.price;
      return 0;
    });

    const insurancePriceItems = prices.filter((i) => i.type === 16);

    if (insurancePriceItems.length === 0) return null;

    return (
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="insurance-price-content"
          id="insurance-price-header"
          className="AccordionHeader"
        >
          <Box className="AccordionHeader-Content">
            <Typography>{t('checkout.sideBar.priceResume.insurance')}</Typography>
            <Typography>{setMoneyFormat(Math.ceil(totalPrice), 0)}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="AccordionDetails">
          {insurancePriceItems.map((item, index) => {
            return (
              <Box key={index} className="AccordionDetails-Item">
                <Box>
                  <Typography variant="body1" color="initial">
                    {item.description}
                  </Typography>
                  {getPayerTypeComponent(item)}
                </Box>
                <Typography variant="body1" color="initial">
                  {setMoneyFormat(Math.ceil(item.price), 0)}
                </Typography>
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const getAdditionalAccordion = () => {
    // Type Additional is 1
    const totalPrice = _.sumBy(prices, (i) => {
      if (i.type === 1) return i.price;
      return 0;
    });

    const additionalPriceItems = prices.filter((i) => i.type === 1);

    if (additionalPriceItems.length === 0) return null;

    return (
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="additional-price-content"
          id="additional-price-header"
          className="AccordionHeader"
        >
          <Box className="AccordionHeader-Content">
            <Typography>{t('checkout.sideBar.priceResume.additional')}</Typography>
            <Typography>{setMoneyFormat(Math.ceil(totalPrice), 0)}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="AccordionDetails">
          {additionalPriceItems.map((item, index) => {
            return (
              <Box key={index} className="AccordionDetails-Item">
                <Box>
                  <Typography variant="body1" color="initial">
                    {item.description}
                  </Typography>
                  {getPayerTypeComponent(item)}
                </Box>
                <Typography variant="body1" color="initial">
                  {setMoneyFormat(Math.ceil(item.price), 0)}
                </Typography>
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const getTaxesAccordion = () => {
    // Type Tax is 15
    const totalPrice = _.sumBy(prices, (i) => {
      if (i.type === 15) return i.price;
      return 0;
    });
    const taxPriceItems = prices.filter((i) => i.type === 15);

    if (taxPriceItems.length === 0) return null;

    return (
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="taxes-price-content"
          id="taxes-price-header"
          className="AccordionHeader"
        >
          <Box className="AccordionHeader-Content">
            <Typography>{t('checkout.sideBar.priceResume.tax')}</Typography>
            <Typography>{setMoneyFormat(Math.ceil(totalPrice), 0)}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="AccordionDetails">
          {taxPriceItems.map((item, index) => {
            return (
              <Box key={index} className="AccordionDetails-Item">
                <Box>
                  <Typography variant="body1" color="initial">
                    {item.description}
                  </Typography>
                  {getPayerTypeComponent(item)}
                </Box>
                <Typography variant="body1" color="initial">
                  {setMoneyFormat(Math.ceil(item.price), 0)}
                </Typography>
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const getTotalAccordion = () => {
    return (
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="total-price-content"
          id="total-price-header"
          className="AccordionHeader TotalHeader"
        >
          <Box className="AccordionHeader-Content">
            <Box className="TotalPrice-Title">
              <ErrorOutlineIcon />
              <Typography>{t('checkout.sideBar.priceResume.total.total')}</Typography>
            </Box>
            <Typography>{setMoneyFormat(ceilNumber(data?.total), 0)}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="AccordionDetails TotalDetails">
          <Box className={`Customer-Wrapper ${getClassColor(data!.customer.balance)}`}>
            <Box className={`Title-Wrapper ${getClassColor(data!.customer.balance)}`}>
              <Box className="Customer-Title">
                <ErrorOutlineIcon />
                <Typography>{t('checkout.sideBar.priceResume.total.customer')}</Typography>
              </Box>
              <Typography>{setMoneyFormat(ceilNumber(data?.customer?.total), 0)}</Typography>
            </Box>
            <Box className={`PaymentDetails-Wrapper ${getClassColor(data!.customer.balance)}`}>
              {!showBalance && (
                <Box className="PaymentDetails-Item">
                  <Typography variant="body1" color="initial">
                    {t('checkout.sideBar.priceResume.total.discount')}
                  </Typography>
                  <Typography variant="body1" color="initial">
                    {setMoneyFormat(ceilNumber(Number(agreements?.discountAmount!)), 0)}
                  </Typography>
                </Box>
              )}
              {showBalance && (
                <Box
                  className={
                    data!.customer.balance > 0 ? 'PaymentDetails-Item Red' : 'PaymentDetails-Item'
                  }
                >
                  <Typography variant="body1" color="initial">
                    {t('checkout.sideBar.priceResume.total.balance')}
                  </Typography>
                  <Typography variant="body1" color="initial">
                    {setMoneyFormat(ceilNumber(data?.customer?.balance), 0)}
                  </Typography>
                </Box>
              )}
              <Box className="PaymentDetails-Item">
                <Typography variant="body1" color="initial">
                  {t('checkout.sideBar.priceResume.total.paid')}
                </Typography>
                <Typography variant="body1" color="initial">
                  {setMoneyFormat(ceilNumber(data?.customer?.payed), 0)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className={`Agency-Wrapper ${getClassColor(data!.agency.balance)}`}>
            <Box className={`Title-Wrapper ${getClassColor(data!.agency.balance)}`}>
              <Box className="Customer-Title">
                <ErrorOutlineIcon />
                <Typography>{t('checkout.sideBar.priceResume.total.agency')}</Typography>
              </Box>
              <Typography>{setMoneyFormat(ceilNumber(data?.agency?.total), 0)}</Typography>
            </Box>
            <Box className="PaymentDetails-Wrapper">
              {showBalance && (
                <Box className="PaymentDetails-Item">
                  <Typography variant="body1" color="initial">
                    {t('checkout.sideBar.priceResume.total.balance')}
                  </Typography>
                  <Typography variant="body1" color="initial">
                    {setMoneyFormat(ceilNumber(data?.agency?.balance), 0)}
                  </Typography>
                </Box>
              )}
              {!showBalance && (
                <Box className="PaymentDetails-Item">
                  <Typography variant="body1" color="initial">
                    {t('checkout.sideBar.priceResume.total.discount')}
                  </Typography>
                  <Typography variant="body1" color="initial">
                    {setMoneyFormat(ceilNumber(Number(agreements?.discountAmount!)), 0)}
                  </Typography>
                </Box>
              )}
              <Box className="PaymentDetails-Item">
                <Typography variant="body1" color="initial">
                  {t('checkout.sideBar.priceResume.total.paid')}
                </Typography>
                <Typography variant="body1" color="initial">
                  {setMoneyFormat(ceilNumber(data?.agency?.payed), 0)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  const handleChangePayer = async () => {
    setModalLoading(true);
    const newAssigned = editItem!.assigned === 0 ? 1 : 0;
    await onChangePayer!(editItem!.id, newAssigned);
    setModalLoading(false);
    handleCloseModal();
  };

  return (
    <StyledPriceResume>
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-change-payer"
      >
        <DialogTitle>{t('checkout.sideBar.priceResume.changePayer')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('checkout.sideBar.priceResume.cancelConfirmation', {
              description: editItem?.description,
              price: editItem && setMoneyFormat(editItem.price),
              assign: getAssignedName(editItem?.assigned === 0 ? 1 : 0),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Button
            sx={{ minWidth: '30%', padding: '5px 20px' }}
            variant="secondary-action"
            onClick={handleCloseModal}
            disabled={modalLoading}
          >
            {t('general.cancel')}
          </Button>
          <LoadingButton
            sx={{ minWidth: '30%', padding: '5px 20px' }}
            variant="contained"
            onClick={handleChangePayer}
            autoFocus
            loading={modalLoading}
          >
            {t('general.accept')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Card padding="1.5rem">
        {getRentAccordion()}

        {getInsuranceAccordion()}

        {getAdditionalAccordion()}

        {getTaxesAccordion()}

        {getTotalAccordion()}
      </Card>
    </StyledPriceResume>
  );
}

export default PriceSummary;
