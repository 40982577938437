export const countries = [
  {
    name: 'Afganistán',
    isoCode3166: 'AF',
  },
  {
    name: 'Albania',
    isoCode3166: 'AL',
  },
  {
    name: 'Alemania',
    isoCode3166: 'DE',
  },
  {
    name: 'Andorra',
    isoCode3166: 'AD',
  },
  {
    name: 'Andorra',
    isoCode3166: 'AD',
  },
  {
    name: 'Angola',
    isoCode3166: 'AO',
  },
  {
    name: 'Anguila',
    isoCode3166: 'AI',
  },
  {
    name: 'Antártida',
    isoCode3166: 'AQ',
  },
  {
    name: 'Antigua y Barbuda',
    isoCode3166: 'AG',
  },
  {
    name: 'Arabia Saudita',
    isoCode3166: 'SA',
  },
  {
    name: 'Argelia',
    isoCode3166: 'DZ',
  },
  {
    name: 'Argentina',
    isoCode3166: 'AR',
  },
  {
    name: 'Armenia',
    isoCode3166: 'AM',
  },
  {
    name: 'Aruba',
    isoCode3166: 'AW',
  },
  {
    name: 'Australia',
    isoCode3166: 'AU',
  },
  {
    name: 'Austria',
    isoCode3166: 'AT',
  },
  {
    name: 'Azerbaiyán',
    isoCode3166: 'AZ',
  },
  {
    name: 'Bahamas',
    isoCode3166: 'BS',
  },
  {
    name: 'Bahréin',
    isoCode3166: 'BH',
  },
  {
    name: 'Bangladesh',
    isoCode3166: 'BD',
  },
  {
    name: 'Barbados',
    isoCode3166: 'BB',
  },
  {
    name: 'Bélgica',
    isoCode3166: 'BE',
  },
  {
    name: 'Belice',
    isoCode3166: 'BZ',
  },
  {
    name: 'Benin',
    isoCode3166: 'BJ',
  },
  {
    name: 'Bielorrusia',
    isoCode3166: 'BY',
  },
  {
    name: 'Bolivia (Estado Plurinacional de)',
    isoCode3166: 'BO',
  },
  {
    name: 'Bonaire, Sint Eustatius y Saba',
    isoCode3166: 'BQ',
  },
  {
    name: 'Bosnia y Herzegovina',
    isoCode3166: 'BA',
  },
  {
    name: 'Botswana',
    isoCode3166: 'BW',
  },
  {
    name: 'Brasil',
    isoCode3166: 'BR',
  },
  {
    name: 'Brunei Darussalam',
    isoCode3166: 'BN',
  },
  {
    name: 'Bulgaria',
    isoCode3166: 'BG',
  },
  {
    name: 'Burkina Faso',
    isoCode3166: 'BF',
  },
  {
    name: 'Burundi',
    isoCode3166: 'BI',
  },
  {
    name: 'Bután',
    isoCode3166: 'BT',
  },
  {
    name: 'Cabo Verde',
    isoCode3166: 'CV',
  },
  {
    name: 'Camboya',
    isoCode3166: 'KH',
  },
  {
    name: 'Camerún',
    isoCode3166: 'CM',
  },
  {
    name: 'Canadá',
    isoCode3166: 'CA',
  },
  {
    name: 'Chad',
    isoCode3166: 'TD',
  },
  {
    name: 'Chequia',
    isoCode3166: 'CZ',
  },
  {
    name: 'Chile',
    isoCode3166: 'CL',
  },
  {
    name: 'China',
    isoCode3166: 'CN',
  },
  {
    name: 'Chipre',
    isoCode3166: 'CY',
  },
  {
    name: 'Colombia',
    isoCode3166: 'CO',
  },
  {
    name: 'Comoras',
    isoCode3166: 'KM',
  },
  {
    name: 'Congo',
    isoCode3166: 'CG',
  },
  {
    name: 'Congo, República Democrática del',
    isoCode3166: 'CD',
  },
  {
    name: 'Corea, república de',
    isoCode3166: 'KR',
  },
  {
    name: 'Costa Rica',
    isoCode3166: 'CR',
  },
  {
    name: "Côte d'Ivoire",
    isoCode3166: 'CI',
  },
  {
    name: 'Croacia',
    isoCode3166: 'HR',
  },
  {
    name: 'Cuba',
    isoCode3166: 'CU',
  },
  {
    name: 'Curazao',
    isoCode3166: 'CW',
  },
  {
    name: 'Dinamarca',
    isoCode3166: 'DK',
  },
  {
    name: 'Djibouti',
    isoCode3166: 'DJ',
  },
  {
    name: 'Dominica',
    isoCode3166: 'DM',
  },
  {
    name: 'Ecuador',
    isoCode3166: 'EC',
  },
  {
    name: 'Egipto',
    isoCode3166: 'EG',
  },
  {
    name: 'El Salvador',
    isoCode3166: 'SV',
  },
  {
    name: 'Emiratos Árabes Unidos',
    isoCode3166: 'AE',
  },
  {
    name: 'Eritrea',
    isoCode3166: 'ER',
  },
  {
    name: 'Eslovaquia',
    isoCode3166: 'SK',
  },
  {
    name: 'Eslovenia',
    isoCode3166: 'SI',
  },
  {
    name: 'España',
    isoCode3166: 'ES',
  },
  {
    name: 'Estados Unidos de America',
    isoCode3166: 'US',
  },
  {
    name: 'Estonia',
    isoCode3166: 'EE',
  },
  {
    name: 'Eswatini',
    isoCode3166: 'SZ',
  },
  {
    name: 'Etiopía',
    isoCode3166: 'ET',
  },
  {
    name: 'Federación Rusa',
    isoCode3166: 'RU',
  },
  {
    name: 'Filipinas',
    isoCode3166: 'PH',
  },
  {
    name: 'Finlandia',
    isoCode3166: 'FI',
  },
  {
    name: 'Fiyi',
    isoCode3166: 'FJ',
  },
  {
    name: 'Francia',
    isoCode3166: 'FR',
  },
  {
    name: 'Gabón',
    isoCode3166: 'GA',
  },
  {
    name: 'Gambia',
    isoCode3166: 'GM',
  },
  {
    name: 'Georgia',
    isoCode3166: 'GE',
  },
  {
    name: 'Georgia del sur y las islas Sandwich del sur',
    isoCode3166: 'GS',
  },
  {
    name: 'Ghana',
    isoCode3166: 'GH',
  },
  {
    name: 'Gibraltar',
    isoCode3166: 'GI',
  },
  {
    name: 'Granada',
    isoCode3166: 'GD',
  },
  {
    name: 'Grecia',
    isoCode3166: 'GR',
  },
  {
    name: 'Groenlandia',
    isoCode3166: 'GL',
  },
  {
    name: 'Guadalupe',
    isoCode3166: 'GP',
  },
  {
    name: 'Guam',
    isoCode3166: 'GU',
  },
  {
    name: 'Guatemala',
    isoCode3166: 'GT',
  },
  {
    name: 'Guayana',
    isoCode3166: 'GY',
  },
  {
    name: 'Guayana Francesa',
    isoCode3166: 'GF',
  },
  {
    name: 'Guernsey',
    isoCode3166: 'GG',
  },
  {
    name: 'Guinea',
    isoCode3166: 'GN',
  },
  {
    name: 'Guinea Ecuatorial',
    isoCode3166: 'GQ',
  },
  {
    name: 'Guinea-Bissau',
    isoCode3166: 'GW',
  },
  {
    name: 'Haití',
    isoCode3166: 'HT',
  },
  {
    name: 'Honduras',
    isoCode3166: 'HN',
  },
  {
    name: 'Hong Kong',
    isoCode3166: 'HK',
  },
  {
    name: 'Hungría',
    isoCode3166: 'HU',
  },
  {
    name: 'India',
    isoCode3166: 'IN',
  },
  {
    name: 'Indonesia',
    isoCode3166: 'ID',
  },
  {
    name: 'Ir',
    isoCode3166: 'TG',
  },
  {
    name: 'Irak',
    isoCode3166: 'IQ',
  },
  {
    name: 'Irán (República Islámica de)',
    isoCode3166: 'IR',
  },
  {
    name: 'Irlanda',
    isoCode3166: 'IE',
  },
  {
    name: 'Isla Bouvet',
    isoCode3166: 'BV',
  },
  {
    name: 'Isla de Navidad',
    isoCode3166: 'CX',
  },
  {
    name: 'Isla del hombre',
    isoCode3166: 'IM',
  },
  {
    name: 'Isla Norfolk',
    isoCode3166: 'NF',
  },
  {
    name: 'Islandia',
    isoCode3166: 'IS',
  },
  {
    name: 'Islas Aland',
    isoCode3166: 'AX',
  },
  {
    name: 'islas Bermudas',
    isoCode3166: 'BM',
  },
  {
    name: 'Islas Caimán',
    isoCode3166: 'KY',
  },
  {
    name: 'Islas Cocos (Keeling)',
    isoCode3166: 'CC',
  },
  {
    name: 'Islas Cook',
    isoCode3166: 'CK',
  },
  {
    name: 'Islas Falkland (Malvinas)',
    isoCode3166: 'FK',
  },
  {
    name: 'Islas Faroe',
    isoCode3166: 'FO',
  },
  {
    name: 'Islas Heard y McDonald',
    isoCode3166: 'HM',
  },
  {
    name: 'Islas Marianas del Norte',
    isoCode3166: 'MP',
  },
  {
    name: 'Islas Marshall',
    isoCode3166: 'MH',
  },
  {
    name: 'Islas menores alejadas de los Estados Unidos',
    isoCode3166: 'UM',
  },
  {
    name: 'Islas Salomón',
    isoCode3166: 'SB',
  },
  {
    name: 'Islas Turcas y Caicos',
    isoCode3166: 'TC',
  },
  {
    name: 'Islas Vírgenes (Británicas)',
    isoCode3166: 'VG',
  },
  {
    name: 'Islas Vírgenes (EE. UU.)',
    isoCode3166: 'VI',
  },
  {
    name: 'Israel',
    isoCode3166: 'IL',
  },
  {
    name: 'Italia',
    isoCode3166: 'IT',
  },
  {
    name: 'Jamaica',
    isoCode3166: 'JM',
  },
  {
    name: 'Japón',
    isoCode3166: 'JP',
  },
  {
    name: 'Jordán',
    isoCode3166: 'JO',
  },
  {
    name: 'Katar',
    isoCode3166: 'QA',
  },
  {
    name: 'Kazajstán',
    isoCode3166: 'KZ',
  },
  {
    name: 'Kenia',
    isoCode3166: 'KE',
  },
  {
    name: 'Kirguistán',
    isoCode3166: 'KG',
  },
  {
    name: 'Kiribati',
    isoCode3166: 'KI',
  },
  {
    name: 'Kuwait',
    isoCode3166: 'KW',
  },
  {
    name: 'Lesoto',
    isoCode3166: 'LS',
  },
  {
    name: 'Letonia',
    isoCode3166: 'LV',
  },
  {
    name: 'Líbano',
    isoCode3166: 'LB',
  },
  {
    name: 'Liberia',
    isoCode3166: 'LR',
  },
  {
    name: 'Libia',
    isoCode3166: 'LY',
  },
  {
    name: 'Liechtenstein',
    isoCode3166: 'LI',
  },
  {
    name: 'Lituania',
    isoCode3166: 'LT',
  },
  {
    name: 'Luxemburgo',
    isoCode3166: 'LU',
  },
  {
    name: 'Macao',
    isoCode3166: 'MO',
  },
  {
    name: 'Macedonia del Norte',
    isoCode3166: 'MK',
  },
  {
    name: 'Madagascar',
    isoCode3166: 'MG',
  },
  {
    name: 'Malasia',
    isoCode3166: 'MY',
  },
  {
    name: 'Malawi',
    isoCode3166: 'MW',
  },
  {
    name: 'Maldivas',
    isoCode3166: 'MV',
  },
  {
    name: 'Mali',
    isoCode3166: 'ML',
  },
  {
    name: 'malta',
    isoCode3166: 'MT',
  },
  {
    name: 'Marruecos',
    isoCode3166: 'MA',
  },
  {
    name: 'Martinica',
    isoCode3166: 'MQ',
  },
  {
    name: 'Mauricio',
    isoCode3166: 'MU',
  },
  {
    name: 'Mauritania',
    isoCode3166: 'MR',
  },
  {
    name: 'Mayotte',
    isoCode3166: 'YT',
  },
  {
    name: 'México',
    isoCode3166: 'MX',
  },
  {
    name: 'Micronesia (Estados Federados de)',
    isoCode3166: 'FM',
  },
  {
    name: 'Moldavia, República de',
    isoCode3166: 'MD',
  },
  {
    name: 'Mónaco',
    isoCode3166: 'MC',
  },
  {
    name: 'Mongolia',
    isoCode3166: 'MN',
  },
  {
    name: 'Montenegro',
    isoCode3166: 'ME',
  },
  {
    name: 'Montserrat',
    isoCode3166: 'MS',
  },
  {
    name: 'Mozambique',
    isoCode3166: 'MZ',
  },
  {
    name: 'Myanmar',
    isoCode3166: 'MM',
  },
  {
    name: 'Namibia',
    isoCode3166: 'NA',
  },
  {
    name: 'Nauru',
    isoCode3166: 'NR',
  },
  {
    name: 'Nepal',
    isoCode3166: 'NP',
  },
  {
    name: 'Nicaragua',
    isoCode3166: 'NI',
  },
  {
    name: 'Níger',
    isoCode3166: 'NE',
  },
  {
    name: 'Nigeria',
    isoCode3166: 'NG',
  },
  {
    name: 'Niue',
    isoCode3166: 'NU',
  },
  {
    name: 'Noruega',
    isoCode3166: 'NO',
  },
  {
    name: 'Nueva Caledonia',
    isoCode3166: 'NC',
  },
  {
    name: 'Nueva Zelanda',
    isoCode3166: 'NZ',
  },
  {
    name: 'Omán',
    isoCode3166: 'OM',
  },
  {
    name: 'Países Bajos',
    isoCode3166: 'NL',
  },
  {
    name: 'Pakistán',
    isoCode3166: 'PK',
  },
  {
    name: 'Palau',
    isoCode3166: 'PW',
  },
  {
    name: 'Palestina, Estado de',
    isoCode3166: 'PS',
  },
  {
    name: 'Panamá',
    isoCode3166: 'PA',
  },
  {
    name: 'Papúa Nueva Guinea',
    isoCode3166: 'PG',
  },
  {
    name: 'Paraguay',
    isoCode3166: 'PY',
  },
  {
    name: 'pavo',
    isoCode3166: 'TR',
  },
  {
    name: 'Perú',
    isoCode3166: 'PE',
  },
  {
    name: 'Pitcairn',
    isoCode3166: 'PN',
  },
  {
    name: 'Polinesia francés',
    isoCode3166: 'PF',
  },
  {
    name: 'Polonia',
    isoCode3166: 'PL',
  },
  {
    name: 'Portugal',
    isoCode3166: 'PT',
  },
  {
    name: 'Puerto Rico',
    isoCode3166: 'PR',
  },
  {
    name: 'Reino Unido de Gran Bretaña e Irlanda del Norte',
    isoCode3166: 'GB',
  },
  {
    name: 'República Árabe Siria',
    isoCode3166: 'SY',
  },
  {
    name: 'República Centroafricana',
    isoCode3166: 'CF',
  },
  {
    name: 'República de Corea, Popular Democrática de)',
    isoCode3166: 'KP',
  },
  {
    name: 'República Democrática Popular Lao',
    isoCode3166: 'LA',
  },
  {
    name: 'República Dominicana',
    isoCode3166: 'DO',
  },
  {
    name: 'Reunión',
    isoCode3166: 'RE',
  },
  {
    name: 'Ruanda',
    isoCode3166: 'RW',
  },
  {
    name: 'Rumania',
    isoCode3166: 'RO',
  },
  {
    name: 'Sahara Occidental',
    isoCode3166: 'EH',
  },
  {
    name: 'Saint Kitts y Nevis',
    isoCode3166: 'KN',
  },
  {
    name: 'Samoa',
    isoCode3166: 'WS',
  },
  {
    name: 'Samoa Americana',
    isoCode3166: 'AS',
  },
  {
    name: 'San Bartolomé',
    isoCode3166: 'BL',
  },
  {
    name: 'San Marino',
    isoCode3166: 'SM',
  },
  {
    name: 'San Martín (parte francesa)',
    isoCode3166: 'MF',
  },
  {
    name: 'San Pedro y Miquelón',
    isoCode3166: 'PM',
  },
  {
    name: 'San Vicente y las Granadinas',
    isoCode3166: 'VC',
  },
  {
    name: 'Santa Elena, Ascensión y Tristán da Cunha',
    isoCode3166: 'SH',
  },
  {
    name: 'Santa Lucía',
    isoCode3166: 'LC',
  },
  {
    name: 'Santa Sede',
    isoCode3166: 'VA',
  },
  {
    name: 'Santo Tomé y Príncipe',
    isoCode3166: 'ST',
  },
  {
    name: 'Senegal',
    isoCode3166: 'SN',
  },
  {
    name: 'Serbia',
    isoCode3166: 'RS',
  },
  {
    name: 'Seychelles',
    isoCode3166: 'SC',
  },
  {
    name: 'Sierra Leona',
    isoCode3166: 'SL',
  },
  {
    name: 'Singapur',
    isoCode3166: 'SG',
  },
  {
    name: 'Sint Maarten (parte holandesa)',
    isoCode3166: 'SX',
  },
  {
    name: 'Somalia',
    isoCode3166: 'SO',
  },
  {
    name: 'Sri Lanka',
    isoCode3166: 'LK',
  },
  {
    name: 'Sudáfrica',
    isoCode3166: 'ZA',
  },
  {
    name: 'Sudán',
    isoCode3166: 'SD',
  },
  {
    name: 'Sudán del Sur',
    isoCode3166: 'SS',
  },
  {
    name: 'Suecia',
    isoCode3166: 'SE',
  },
  {
    name: 'suéter',
    isoCode3166: 'JE',
  },
  {
    name: 'Suiza',
    isoCode3166: 'CH',
  },
  {
    name: 'Surinam',
    isoCode3166: 'SR',
  },
  {
    name: 'Svalbard y Jan Mayen',
    isoCode3166: 'SJ',
  },
  {
    name: 'Tailandia',
    isoCode3166: 'TH',
  },
  {
    name: 'Taiwan, provincia de China',
    isoCode3166: 'TW',
  },
  {
    name: 'Tanzania, República Unida de',
    isoCode3166: 'TZ',
  },
  {
    name: 'Tayikistán',
    isoCode3166: 'TJ',
  },
  {
    name: 'Territorio Británico del Océano Índico',
    isoCode3166: 'IO',
  },
  {
    name: 'Territorios Franceses del Sur',
    isoCode3166: 'TF',
  },
  {
    name: 'Timor-Leste',
    isoCode3166: 'TL',
  },
  {
    name: 'Tokelau',
    isoCode3166: 'TK',
  },
  {
    name: 'Tonga',
    isoCode3166: 'TO',
  },
  {
    name: 'Trinidad y Tobago',
    isoCode3166: 'TT',
  },
  {
    name: 'Túnez',
    isoCode3166: 'TN',
  },
  {
    name: 'Turkmenistán',
    isoCode3166: 'TM',
  },
  {
    name: 'Tuvalu',
    isoCode3166: 'TV',
  },
  {
    name: 'Ucrania',
    isoCode3166: 'UA',
  },
  {
    name: 'Uganda',
    isoCode3166: 'UG',
  },
  {
    name: 'Uruguay',
    isoCode3166: 'UY',
  },
  {
    name: 'Uzbekistan',
    isoCode3166: 'UZ',
  },
  {
    name: 'Vanuatu',
    isoCode3166: 'VU',
  },
  {
    name: 'Venezuela (República Bolivariana de)',
    isoCode3166: 'VE',
  },
  {
    name: 'Vietnam',
    isoCode3166: 'VN',
  },
  {
    name: 'Wallis y Futuna',
    isoCode3166: 'WF',
  },
  {
    name: 'Yemen',
    isoCode3166: 'YE',
  },
  {
    name: 'Zambia',
    isoCode3166: 'ZM',
  },
  {
    name: 'Zimbabue',
    isoCode3166: 'ZW',
  },
];
