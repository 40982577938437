import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_XS } from '../../constants/screenSize';

const HeaderWrapper = styled((props) => <Grid {...props} />)`
  align-items: center;
  margin-bottom: 2rem;

  .RightCol {
    display: flex;
    justify-content: flex-end;
  }

  button {
    min-width: 200px;
    line-height: 1;
  }

  @media (max-width: ${SCREEN_XS}px) {
    .RightCol {
      justify-content: flex-start;

      button {
        width: 100%;
      }
    }

    h1 {
      margin-bottom: 1rem;
    }
  }
`;

const TableWrapper = styled((props) => <Box {...props} />)`
  .MuiTableBody-root {
    .MuiTableRow-root:hover {
      cursor: pointer;
    }
  }
`;

export { TableWrapper, HeaderWrapper };
