import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Highlighter from 'react-highlight-words';

import StyledSearchBar from './index.styled';
import { SearchContext } from '../../../contexts/SearchContext';
import type { Results } from './type';
import Card from '../../Card';
import BookingStatus from '../../BookingStatus';
import { setDateFormat } from '../../../utils/dataUtils';
import { BOOKINGS_ROUTE, CUSTOMERS_ROUTE } from '../../../constants/routes';

function SearchBar() {
  const [keyword, setKeyword] = useState<string>('');
  const [results, setResults] = useState<Results | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { getSearchBarResults } = useContext(SearchContext);
  const { t } = useTranslation();

  useEffect(() => {
    const cleanResults = () => {
      setResults(null);
      setKeyword('');
    };

    document.addEventListener('mousedown', cleanResults);

    return () => {
      document.removeEventListener('mousedown', cleanResults);
    };
  }, []);

  useEffect(() => {
    const timeoutHandler = setTimeout(async () => {
      if (!keyword) return;
      setLoading(true);
      const response = await getSearchBarResults(keyword);
      if (response.data) setResults(response.data);
      setLoading(false);
    }, 300);
    return () => clearTimeout(timeoutHandler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const bookingResults = results?.bookings && results?.bookings?.length > 0;
  const customerResults = results?.customers && results?.customers?.length > 0;

  return (
    <StyledSearchBar showResults={bookingResults || customerResults}>
      <TextField
        className="SearchInput"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className="SearchIcon" />
            </InputAdornment>
          ),
          ...(loading && {
            endAdornment: <CircularProgress size={20} />,
          }),
        }}
        value={keyword}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) => setKeyword(target.value)}
      />

      <Box className="ResultsWrapper" sx={{ opacity: bookingResults || customerResults ? 1 : 0 }}>
        <Card padding="0">
          {bookingResults && (
            <Box>
              <Typography className="Title">{t('searchBar.bookings')}</Typography>
              {results.bookings.map((booking) => {
                const route = `${BOOKINGS_ROUTE.BOOKING_DETAILS}?id=${booking.id}`;
                return (
                  <Link key={booking.id} to={route} className="ResultItem">
                    <Box className="Row">
                      <Highlighter
                        className="Paragraph Item-Id"
                        highlightClassName="HighlightedText"
                        searchWords={keyword.split(' ')}
                        autoEscape={true}
                        textToHighlight={`#${booking.id}`}
                      />
                      <Highlighter
                        className="Paragraph"
                        highlightClassName="HighlightedText"
                        searchWords={keyword.split(' ')}
                        autoEscape={true}
                        textToHighlight={`${booking.customer.firstName} ${booking.customer.lastName}`}
                      />
                      <BookingStatus status={booking.currentStatus} />
                    </Box>
                    <Highlighter
                      className="Paragraph"
                      highlightClassName="HighlightedText"
                      searchWords={keyword.split(' ')}
                      autoEscape={true}
                      textToHighlight={`${booking.car.id} ${booking.car.model.name}`}
                    />
                    <Typography className="Paragraph">{`${setDateFormat(
                      booking.fromDate,
                    )} - ${setDateFormat(booking.toDate)} (${
                      booking.totalDaysString
                    })`}</Typography>
                  </Link>
                );
              })}
            </Box>
          )}

          {bookingResults && customerResults && <Divider />}

          {customerResults && (
            <Box>
              <Typography className="Title">{t('searchBar.customers')}</Typography>
              {results.customers.map((customer) => {
                const phoneNum = customer.cellPhone
                  ? `${t('searchBar.phone')}: ${customer.cellPhone}`
                  : '';
                return (
                  <Link
                    key={customer.id}
                    to={`${CUSTOMERS_ROUTE.CUSTOMER_DETAILS}?id=${customer.id}`}
                    className="ResultItem"
                  >
                    <Highlighter
                      className="Paragraph"
                      highlightClassName="HighlightedText"
                      searchWords={keyword.split(' ')}
                      autoEscape={true}
                      textToHighlight={`${customer.firstName} ${customer.lastName}`}
                    />

                    <Highlighter
                      className="Paragraph"
                      highlightClassName="HighlightedText"
                      searchWords={keyword.split(' ')}
                      autoEscape={true}
                      textToHighlight={`${customer.emailAddress} ${phoneNum}`}
                    />
                    <Box className="Row">
                      <Typography className="Paragraph">{t('searchBar.document')}</Typography>
                      <Highlighter
                        className="Paragraph"
                        highlightClassName="HighlightedText"
                        searchWords={keyword.split(' ')}
                        autoEscape={true}
                        textToHighlight={customer.documentId}
                      />
                    </Box>
                  </Link>
                );
              })}
            </Box>
          )}
        </Card>
      </Box>
    </StyledSearchBar>
  );
}
export default SearchBar;
