import { useContext } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

import { StyledContainer } from './index.styled';
import Card from '../Card/index';
import AdditionalItem from '../AdditionalItem';
import { SearchContext } from '../../contexts/SearchContext';
import type { InsuranceAndAdditionalProps } from './types';
import { useEffectOnMount } from '../../hooks';

function InsuranceAndAdditional({
  selectedAdditionalList,
  setSelectedAdditionalList,
}: InsuranceAndAdditionalProps) {
  const { t } = useTranslation();

  const { additionalList, getPrices } = useContext(SearchContext);

  const onlyAdditional = additionalList.filter(
    (a) => a.type === 'Additional' || a.type === 'Adicional',
  );
  const insuranceAdditional = additionalList.filter(
    (a) => a.type === 'Insurance' || a.type === 'Seguro',
  );

  useEffectOnMount(() => {
    // Add required and default options
    const requiredAdditional = additionalList
      .filter((a) => a.isRequired || a.isDefault)
      .map((i) => ({ id: i.id, number: 1 }));

    setSelectedAdditionalList(requiredAdditional);
    getPrices(requiredAdditional);
  });

  const excludes = additionalList
    .filter((a) => selectedAdditionalList.some((i) => i.id === a.id))
    .map((i) => i.excludes)
    .flat(1);

  const handleSelectItem = async (id: number, counter: boolean, quantity: string) => {
    const index = selectedAdditionalList.findIndex((i) => i.id === id);
    const number = quantity ? Number(quantity) : 1;

    let newSelectedAdditionalList = [...selectedAdditionalList];

    if (index === -1) {
      newSelectedAdditionalList = [...selectedAdditionalList, { id, number }];
    } else {
      if (!counter || Number(quantity) < 1) {
        newSelectedAdditionalList = selectedAdditionalList.filter((i) => i.id !== id);
      } else {
        newSelectedAdditionalList = selectedAdditionalList.map((i) => {
          if (i.id === id) return { id, number };
          return i;
        });
      }
    }
    setSelectedAdditionalList(newSelectedAdditionalList);
    await getPrices(newSelectedAdditionalList);
  };

  return (
    <StyledContainer>
      <Grid>
        <Card padding="1rem">
          <Accordion defaultExpanded={true} className="AccordionItemsWrapper">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="additional-content"
              id="additional-header"
            >
              <Box className="IconWrapper">
                <GppGoodOutlinedIcon />
                <Typography variant="subtitle1" className="Typography">
                  {t('checkout.insuranceAndAdditional.title')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails className="AccordionDetails">
              <Grid container spacing={2} sx={{ width: '100%' }}>
                {insuranceAdditional.map((item) => {
                  const showCounter = item.maxQuantityPerBooking > 1;
                  return (
                    <Grid key={item.id} item sm={12} md={6} lg={4} xl={3} sx={{ width: '100%' }}>
                      <AdditionalItem
                        item={item}
                        icon={<SecurityOutlinedIcon />}
                        excludes={excludes}
                        selected={selectedAdditionalList}
                        onClick={(quantity) => handleSelectItem(item.id, showCounter, quantity)}
                      />
                    </Grid>
                  );
                })}
              </Grid>

              <Box className="Agreements">
                <DirectionsCarFilledOutlinedIcon />
                <Typography variant="subtitle1" className="Typography">
                  {t('checkout.insuranceAndAdditional.subTitle')}
                </Typography>
              </Box>

              <Grid container spacing={2} sx={{ width: '100%' }}>
                {onlyAdditional.map((item) => {
                  const showCounter = item.maxQuantityPerBooking > 1;
                  return (
                    <Grid key={item.id} item sm={12} md={6} lg={4} xl={3} sx={{ width: '100%' }}>
                      <AdditionalItem
                        item={item}
                        icon={<FavoriteBorderOutlinedIcon />}
                        excludes={excludes}
                        selected={selectedAdditionalList}
                        onClick={(quantity) => handleSelectItem(item.id, showCounter, quantity)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Grid>
    </StyledContainer>
  );
}

export default InsuranceAndAdditional;
