import MuiDrawer from '@mui/material/Drawer';
import {
  Box,
  CSSObject,
  styled,
  Theme,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import type {
  DrawerHeaderProps,
  ListItemTextProps,
  ListItemButtonProps,
  ListItemIconProps,
  StyledDrawerBoxProps,
} from './types';
import { SCREEN_XXL } from '../../constants/screenSize';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')<DrawerHeaderProps>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-end' : 'center',
  paddingTop: 55,
  marginLeft: '4px',
  paddingBottom: '30px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const StyledListItemText = styled(ListItemText)<ListItemTextProps>(({ open }) => ({
  opacity: open ? 1 : 0,
  marginLeft: '15%',
}));

export const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(({ open }) => ({
  justifyContent: open ? 'initial' : 'center',
}));

export const StyledListItemIcon = styled(ListItemIcon)<ListItemIconProps>(({ open }) => ({
  minWidth: 0,
  mr: open ? 3 : 'auto',
  justifyContent: 'center',
}));

export const StyledDrawerBox = styled((props: StyledDrawerBoxProps) => <Box {...props} />)`
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: ${drawerWidth},
    flexShrink: 0,
  },

  '& .ContentWrapper': {
    paddingTop: '80px',
  },

`;

export const StyledContainer = styled(Box)`
  display: flex;

  .LoadingWrapper {
    display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
  }

  .ErrorWrapper {
    display: flex;
    flex-direction: column;
    height: 50vh;
    align-items: center;
    justify-content: center;
    button {
      min-width: 8rem;
      line-height: 1;
    }
  }

  .DesktopLayoutMain {
    padding-top: 3rem;
    margin: 3rem;
    max-width: 1920px;
    width: 100%;
  }

  .MobileLayoutMain {
    padding: 55px 20px;
    margin-top: 20px;
    width: 100%;
  }

  .SideBar-Divider {
    border-color: rgba(0, 0, 0, 0.05);
  }

  .SideBar-Toggle {
    margin-top: 64px;
    min-height: 0;
    padding: 10px;
  }

  @media (max-width: ${SCREEN_XXL}px) {
    .DesktopLayoutMain {
      margin: 65px 40px;
      .LoadingWrapper,
      .ErrorWrapper {
        width: 100%;
      }
    }
  }

  @media (min-width: 2560px) {
    .DesktopLayoutMain {
      .LoadingWrapper,
      .ErrorWrapper {
        margin-left: 20%;
      }
    }
  }
`;

export const StyledDrawerContent = styled(Box)`
  .List {
    margin-top: 40px;
  }

  .Link {
    text-decoration: none;
    color: #2e2d35;
    width: 100%;
    transition: all 0.7s;
    padding: 12px 0 12px 3px;
    margin: 2px 0;

    &.Active,
    &:hover {
      background-color: ${({ theme: { palette } }) => palette.background.default};
    }
  }

  .ListItemButton {
    .ListItemText {
      color: #858c99;
    }

    &:hover {
      background: none;
    }
  }
`;

export const StyledItemButton = styled(ListItemButton)`
  &.MuiListItemButton-gutters {
    margin: 0 0 130px 3px;
  }

  @media (max-width: 500px) {
    &.MuiListItemButton-gutters {
      margin: 35px 0 70px 0;
    }
  }
`;
