import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledFileItem = styled((props) => <Box {...props} />)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: ${({ theme: { extra } }) => `1px solid ${extra.border}`};
  border-radius: 10px;
  padding: 0.2rem 0.7rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  .FileName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 80px);

    svg {
      margin-right: 10px;
    }

    p {
      width: calc(100% - 34px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .FileActions {
    display: flex;
    flex-wrap: nowrap;
  }
`;

export { StyledFileItem };
