import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledLoadingScreen = styled(Box)`
  display: flex;
  background: ${({ theme: { palette } }) => palette.background.default};
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export { StyledLoadingScreen };
