import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_SM } from '../../../constants/screenSize';

const StyledSearchBar = styled(({ showResults, ...otherProps }) => <Box {...otherProps} />)`
  width: 40%;
  max-width: 506px;
  position: relative;

  .SearchInput {
    &.MuiFormControl-root {
      background: ${({ theme: { palette } }) => palette.background.default};
      width: 100%;
      border: ${({ theme }) => `1px solid ${theme.extra.border}`};
      border-radius: 50px;
    }

    .SearchIcon {
      color: #5f6368;
    }

    &.MuiInputBase-root {
      padding: 2px;
      border-radius: 50px;
    }

    .MuiOutlinedInput-input {
      padding: 7px 0px;
    }

    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  }

  .ResultsWrapper {
    width: 100%;
    position: absolute;
    margin-top: 8px;
    white-space: nowrap;
    transition: all 0.3s;

    .CardComponent {
      min-height: ${({ showResults }) => (showResults ? ' 137px' : 0)};
    }

    .Title {
      padding: 0.5rem 1.2rem;
      font-weight: 500;
      color: ${({ theme: { palette } }) => palette.text.primary};
      font-size: 1rem;
      border-bottom: ${({ theme: { extra } }) => `1px solid ${extra.border}`};
    }

    .Paragraph {
      color: ${({ theme: { palette } }) => palette.text.secondary};
      font-size: 0.9rem;
      display: block;
      margin-right: 6px;
      line-height: 1.5;
    }

    .HighlightedText {
      font-weight: 600;
      color: ${({ theme: { palette } }) => palette.text.primary};
      background: transparent;
    }

    .Row {
      display: flex;
      align-items: center;
    }

    .ResultItem {
      display: block;
      padding: 1rem 1.2rem;
      text-decoration: none;
      transition: all 0.5s;

      &:hover {
        background: ${({ theme: { palette } }) => palette.background.default};
      }
    }

    .BookingStatus {
      margin-left: 20px;
    }

    .Item-Id {
      color: ${({ theme: { palette } }) => palette.text.primary};
    }
  }

  @media (max-width: ${SCREEN_SM}px) {
    .SearchInput.MuiTextField-root {
      display: none;
    }
  }
`;

export default StyledSearchBar;
