import {
  BadgeOutlined,
  ContentCopyOutlined,
  EmailOutlined,
  LocalAtmOutlined,
  OutlinedFlagTwoTone,
  PlaceOutlined,
  SmartphoneOutlined,
} from '@mui/icons-material';
import { Alert, Box, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import FileItem from '../../components/FileItem';
import SidebarLayout from '../../components/SidebarLayout';
import UploadFileBtn from '../../components/UploadFileBtn';
import { CUSTOMERS_ROUTE } from '../../constants/routes';
import { CustomerContext } from '../../contexts/CustomerContext';
import type { Customer, FileType } from '../../contexts/types';
import { useEffectOnMount } from '../../hooks';
import { convertToBase64 } from '../../utils/dataUtils';
import { getQueryParam } from '../../utils/windowUtils';
import { ContactItem, ContentWrapper, ErrorWrapper, HeaderWrapper } from './index.styled';
import { RentalContext } from '../../contexts/RentalContext';

function CustomerDetailsContainer() {
  const { customerList, getCustomerItem } = useContext(CustomerContext);
  const { documentTypes, fiscalConditionList } = useContext(RentalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [customer, setCustomer] = useState<Customer | null>(null);

  const { t } = useTranslation();
  const queryCustomerId = getQueryParam('id');
  const navigate = useNavigate();
  const [copiedAlert, setCopiedAlert] = useState<string>('');

  useEffectOnMount(() => {
    const handleGetBookingItem = async () => {
      setLoading(true);
      const response = await getCustomerItem(queryCustomerId!);
      // if (response.error) {
      //   setError(response.error);
      // } else {
      //   setCustomer(response.data);
      // }

      // TODO: Uncomment above code once API is ready and remove next line setCustomer(..)
      setCustomer(customerList!.results[0]);
      setLoading(false);
    };
    handleGetBookingItem();
  });

  const getFullAddress = () => {
    const street = customer?.address ? `${customer?.address} ` : '';
    const number = customer?.addressNumber || '';
    const city = customer?.city ? `${customer?.city} ` : '';
    const state = customer?.state || '';

    return `${street}${number}${street || number ? ', ' : ''}${city}${state}`;
  };

  const handleCopyContent = async (content: string) => {
    await navigator?.clipboard?.writeText(content);
    setCopiedAlert(content);
    setTimeout(() => {
      setCopiedAlert('');
    }, 800);
  };

  const handleUploadFiles = async (files: FileList | null) => {
    let newFiles = [] as FileType[];
    const inputFiles = files ? [...files] : [];

    if (files) {
      newFiles = await Promise.all(
        inputFiles.map(async (file) => {
          const base64 = await convertToBase64(file);
          return {
            name: file.name,
            content: typeof base64 === 'string' ? base64.split('base64,')?.[1] : '',
            type: '',
          };
        }),
      );
    }
    console.log(newFiles);
    // TODO call API to edit profile
  };

  const onDeleteFile = (index: number) => {
    // TODO
  };

  const getTaxPayerName = () => {
    return fiscalConditionList.find((i) => i.id === customer?.taxPayer)?.name || '';
  };

  const getDocumentIdName = () => {
    return documentTypes.find((i) => i.id === customer?.documentTypeId)?.name || '';
  };

  if (loading) {
    return (
      <SidebarLayout>
        <Box className="LoadingWrapper">
          <CircularProgress color="primary" />
        </Box>
      </SidebarLayout>
    );
  }

  if (error) {
    return (
      <SidebarLayout>
        <ErrorWrapper>
          <Typography variant="body1">{error}</Typography>
          <Button variant="contained" onClick={() => navigate(-1)}>
            {t('general.back')}
          </Button>
        </ErrorWrapper>
      </SidebarLayout>
    );
  }

  if (!customer) {
    return (
      <SidebarLayout>
        <Box />
      </SidebarLayout>
    );
  }

  return (
    <SidebarLayout>
      <HeaderWrapper container>
        <Grid item xs={12} md={6}>
          <Typography variant="h1">{`${customer?.firstName} ${customer?.lastName}`}</Typography>
        </Grid>
        <Grid item xs={12} md={6} className="RightCol">
          <Button
            variant="contained"
            onClick={() => navigate(`${CUSTOMERS_ROUTE.EDIT_CUSTOMER}?id=${customer.id}`)}
          >
            {t('customerDetails.edit')}
          </Button>
        </Grid>
      </HeaderWrapper>
      <ContentWrapper container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <Box className="ContactDetails-Wrapper">
              <Typography className="Subtitle" variant="h2">
                {t('customerDetails.contactDetails')}
              </Typography>
              {customer?.emailAddress && (
                <ContactItem>
                  <Box className="TextWrapper">
                    <EmailOutlined />
                    <Typography className="Ellipsis-Text">{customer.emailAddress}</Typography>
                  </Box>
                  <Box className="CopyBtn">
                    {copiedAlert === customer.emailAddress && (
                      <Alert severity="success">{t('customerDetails.copied')}</Alert>
                    )}
                    <IconButton
                      aria-label={t('general.copy')}
                      onClick={() => handleCopyContent(customer.emailAddress)}
                    >
                      <ContentCopyOutlined />
                    </IconButton>
                  </Box>
                </ContactItem>
              )}
              {customer?.cellPhone && (
                <ContactItem>
                  <Box className="TextWrapper">
                    <SmartphoneOutlined />
                    <Typography className="Ellipsis-Text">{customer.cellPhone}</Typography>
                  </Box>
                  <Box className="CopyBtn">
                    {copiedAlert === customer.cellPhone && (
                      <Alert severity="success">{t('customerDetails.copied')}</Alert>
                    )}
                    <IconButton
                      aria-label={t('general.copy')}
                      onClick={() => handleCopyContent(customer.cellPhone)}
                    >
                      <ContentCopyOutlined />
                    </IconButton>
                  </Box>
                </ContactItem>
              )}
              {getFullAddress() && (
                <ContactItem>
                  <Box className="TextWrapper">
                    <PlaceOutlined />
                    <Typography>{getFullAddress()}</Typography>
                  </Box>{' '}
                  <Box className="CopyBtn">
                    {copiedAlert === getFullAddress() && (
                      <Alert severity="success">{t('customerDetails.copied')}</Alert>
                    )}
                    <IconButton
                      aria-label={t('general.copy')}
                      onClick={() => handleCopyContent(getFullAddress())}
                    >
                      <ContentCopyOutlined />
                    </IconButton>
                  </Box>
                </ContactItem>
              )}
              {customer?.country && (
                <ContactItem>
                  <Box className="TextWrapper">
                    <OutlinedFlagTwoTone />
                    <Typography>{customer.country}</Typography>
                  </Box>
                </ContactItem>
              )}
              {customer?.documentTypeId !== undefined && customer?.documentId && (
                <ContactItem>
                  <Box className="TextWrapper">
                    <BadgeOutlined />
                    <Typography>{`${getDocumentIdName()} ${customer.documentId}`}</Typography>
                  </Box>
                </ContactItem>
              )}
              {customer?.taxPayer !== undefined && (
                <ContactItem>
                  <Box className="TextWrapper">
                    <LocalAtmOutlined />
                    <Typography>{getTaxPayerName()}</Typography>
                  </Box>
                </ContactItem>
              )}
            </Box>
            <Box>
              <Typography className="Subtitle" variant="h2">
                {t('customerDetails.notes')}
              </Typography>
              {customer?.notes || '-'}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <Typography className="Subtitle" variant="h2">
              {t('customerDetails.files')}
            </Typography>
            <UploadFileBtn onClick={handleUploadFiles} className="UploadFiles-Btn" />
            {customer.files?.map((file, index) => {
              return (
                <FileItem
                  key={index}
                  name={file.name}
                  link={file.content}
                  onDelete={() => onDeleteFile(index)}
                />
              );
            })}
          </Card>
        </Grid>
      </ContentWrapper>
    </SidebarLayout>
  );
}
export default CustomerDetailsContainer;
