import { Box } from '@mui/material';
import styled from 'styled-components';

const TablePaginationContainer = styled((props) => <Box {...props} />)`
  flex-shrink: 0;
  position: absolute;
  left: 0;
`;

export { TablePaginationContainer };
