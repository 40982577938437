import { FormEvent, HTMLAttributes, useContext, useEffect, useRef, useState } from 'react';
import { ReactDatePicker } from 'react-datepicker';
import { useSearchParams } from 'react-router-dom';
import { AccessTimeOutlined, ExpandMore, LocalOfferOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import isBetween from 'dayjs/plugin/isBetween';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import StyledSearchForm from './index.styled';
import type { SearchFormProps } from './types';
import CustomDatePicker from '../CustomDatePicker';
import SelectPlaces from '../SelectPlaces';
import Card from '../Card';
import { RentalContext } from '../../contexts/RentalContext';
import type { Place } from '../../contexts/types';
import { SearchContext, SearchParams } from '../../contexts/SearchContext';
import {
  getAvailableCommercialAgreementList,
  getDefaultSearchParams,
  getSearchQueryParams,
} from '../../utils/searchUtils';
import {
  getBranchOfficeByPlaceId,
  getCloseDatesByBranch,
  getDefaultEndHour,
  getNextAvailableDate,
  getPickDropHours,
  getSchedule,
  isDayAvailable,
  isDayOfTheWeekAvailable,
  getBookingDuration,
  DEFAULT_MIN_DAYS,
} from '../../utils/branchOfficeUtils';
import { buildSearchRouteWithParams } from '../../utils/dataUtils';
import { useEffectOnMount } from '../../hooks';
import { useWindowSize } from '../../utils/windowUtils';
import CustomAutocomplete from '../CustomAutocomplete';

dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);
dayjs.extend(isBetween);

function SearchForm({ isDialog, getSearchResults }: SearchFormProps) {
  const {
    places,
    commercialAgreementList,
    holidays,
    attentionSchedule,
    bookingConfiguration: { maxDays, minDays },
  } = useContext(RentalContext);

  const {
    searchParams,
    availableCommercialAgreementList,
    setAvailableCommercialAgreementList,
    diffDeliveryPlace,
    betweenAllowedAge,
    setSearchParams,
    setDiffDeliveryPlace,
    setBetweenAllowedAge,
  } = useContext(SearchContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [pickUpHours, setPickUpHours] = useState<string[]>([]);
  const [dropOffHours, setDropOffHours] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<{ [key: string]: boolean }>({
    age: false,
  });

  const orderedPlaces = _.sortBy(places, (i) => `${i.country}-${i.city}-${i.name}`);
  const [queryParams, setQueryParams] = useSearchParams();
  const urlParams = getSearchQueryParams(queryParams);
  const defaultParams = getDefaultSearchParams(
    urlParams,
    searchParams,
    places,
    holidays,
    attentionSchedule,
    minDays,
  );

  const { t } = useTranslation();
  const dateToRef = useRef<ReactDatePicker<string, undefined>>(null);
  const { width } = useWindowSize();

  const getHoursAfterChangeParams = (pickUpId: number, dropOffId: number) => {
    const pickUpBranchOfficeId = getBranchOfficeByPlaceId(places, pickUpId);
    const dropOffBranchOfficeId = getBranchOfficeByPlaceId(places, dropOffId);

    const { dropOffHours, pickUpHours } = getPickDropHours({
      pickUpDate: searchParams?.dateFrom || defaultParams.dateFrom,
      dropOffDate: searchParams?.dateTo || defaultParams.dateTo,
      pickUpBranchOfficeId,
      dropOffBranchOfficeId,
      schedules: attentionSchedule,
      holidays,
    });

    setPickUpHours(pickUpHours);
    setDropOffHours(dropOffHours);
    return { dropOffHours, pickUpHours };
  };

  useEffectOnMount(() => {
    setSearchParams(defaultParams);

    // Trigger search if going back from checkout
    if (!searchParams && urlParams?.dateFrom) {
      if (defaultParams.dropOffPlaceId !== defaultParams.pickUpPlaceId) {
        setDiffDeliveryPlace(true);
      }
      if (urlParams?.driverAge) setBetweenAllowedAge(false);
      getSearchResults(defaultParams);
    }
  });

  useEffect(() => {
    if (searchParams) {
      const response = getHoursAfterChangeParams(
        searchParams.pickUpPlaceId as number,
        searchParams.dropOffPlaceId as number,
      );

      let dateFrom = searchParams.dateFrom;
      let dateTo = searchParams.dateTo;
      let hourFrom = searchParams.hourFrom;
      let hourTo = searchParams.hourTo;

      const pickUpOfficeId = getBranchOfficeByPlaceId(places, searchParams.pickUpPlaceId);
      const dropOffOfficeId = getBranchOfficeByPlaceId(places, searchParams.dropOffPlaceId);

      const dateFromAvailable = isDayAvailable(
        searchParams.dateFrom,
        holidays,
        attentionSchedule,
        pickUpOfficeId,
      );

      const dateToAvailable = isDayAvailable(
        searchParams.dateTo,
        holidays,
        attentionSchedule,
        dropOffOfficeId,
      );

      if (!dateFromAvailable) {
        dateFrom = getNextAvailableDate(dateFrom, holidays, attentionSchedule, pickUpOfficeId);
      }

      if (!dateToAvailable) {
        dateTo = getNextAvailableDate(dateTo, holidays, attentionSchedule, dropOffOfficeId);
      }

      if (!hourFrom || !response.pickUpHours.includes(hourFrom)) {
        hourFrom = response.pickUpHours[0];
      }

      if (!hourTo) {
        const newHour = getDefaultEndHour(dateFrom, hourFrom, minDays);
        hourTo = response.dropOffHours.includes(newHour) ? newHour : '';
      } else {
        const duration = getBookingDuration(dateFrom, hourFrom, dateTo, hourTo);

        if (!response.dropOffHours.includes(hourTo) || duration < minDays) {
          const newHour = getDefaultEndHour(dateFrom, hourFrom, minDays);
          hourTo = response.dropOffHours.includes(newHour) ? newHour : '';
        }
      }

      setSearchParams({
        ...searchParams,
        dateFrom,
        dateTo,
        hourFrom,
        hourTo,
      } as SearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams?.pickUpPlaceId,
    searchParams?.dropOffPlaceId,
    searchParams?.dateFrom,
    searchParams?.dateTo,
    searchParams?.hourFrom,
  ]);

  // Filters commercialAgreement by branchOffice and date.
  useEffect(() => {
    const newList = getAvailableCommercialAgreementList(searchParams, commercialAgreementList);
    setAvailableCommercialAgreementList(newList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    commercialAgreementList,
    searchParams?.pickUpPlaceId,
    searchParams?.dateFrom,
    searchParams?.dateTo,
  ]);

  const handleChange = (key: keyof SearchParams, value: string | number | Date | null) => {
    setSearchParams({ ...searchParams, [key]: value } as SearchParams);
    setFormErrors({ ...formErrors, [key]: false });
  };

  const searchResults = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const newQueryParams = buildSearchRouteWithParams(searchParams!);
    setQueryParams(newQueryParams);
    await getSearchResults();
    setLoading(false);
  };

  const handleOpenTo = () => {
    if (dateToRef?.current) (dateToRef.current as any).handleFocus();
  };

  const getPlaceById = (id?: number | null) => {
    if (!id) return null;

    return places.find((p) => p.id === id) || null;
  };

  const handleSelectPickUpPlace = (value: Place | null) => {
    const newPlace = places.find((p) => p.id === value?.id);

    setSearchParams({
      ...searchParams,
      pickUpPlaceId: newPlace?.id || null,
      pickUpPlaceName: newPlace?.branchOfficeName || '',
      ...(!diffDeliveryPlace && {
        dropOffPlaceId: newPlace?.id || null,
        dropOffPlaceName: newPlace?.name || '',
      }),
    } as SearchParams);
  };

  const handleSelectDropOffPlace = (value: Place | null) => {
    const newPlace = places.find((p) => p.id === value?.id);

    setSearchParams({
      ...searchParams,
      dropOffPlaceId: newPlace?.id || null,
      dropOffPlaceName: newPlace?.branchOfficeName || newPlace?.name || '',
    } as SearchParams);
  };

  const getPickUpExcludeDates = () => {
    const branchOfficeId = getBranchOfficeByPlaceId(places, searchParams?.pickUpPlaceId);
    return getCloseDatesByBranch(holidays, attentionSchedule, branchOfficeId);
  };

  const getDropOffExcludeDates = () => {
    const branchOfficeId = getBranchOfficeByPlaceId(places, searchParams?.dropOffPlaceId);
    return getCloseDatesByBranch(holidays, attentionSchedule, branchOfficeId);
  };

  const filterPickUpDate = (day: Date) => {
    const branchOfficeId = getBranchOfficeByPlaceId(places, searchParams?.pickUpPlaceId);
    const scheduleConf = getSchedule(attentionSchedule, branchOfficeId);

    if (scheduleConf) return isDayOfTheWeekAvailable(scheduleConf.schedule, day);
    return true;
  };

  const filterDropOffDate = (day: Date) => {
    const branchOfficeId = getBranchOfficeByPlaceId(places, searchParams?.dropOffPlaceId);
    const scheduleConf = getSchedule(attentionSchedule, branchOfficeId);

    if (scheduleConf) return isDayOfTheWeekAvailable(scheduleConf.schedule, day);
    return true;
  };

  const getMinEndDate = (date?: Date) => {
    if (!date) return null;

    return dayjs(date)
      .add(minDays || DEFAULT_MIN_DAYS, 'days')
      .toDate();
  };

  const handleChangeDateFrom = (date: Date | null) => {
    let dateTo = searchParams?.dateTo as Date | null;

    if (date && dateTo && dayjs(dateTo).isBefore(dayjs(date))) {
      dateTo = getMinEndDate(date);
    }
    setSearchParams({ ...searchParams, dateFrom: date, dateTo } as SearchParams);
    setFormErrors({ ...formErrors, dateFrom: false, dateTo: false });
  };

  const getAgreementByCode = (code?: string) => {
    if (!code) return null;

    return availableCommercialAgreementList.find((p) => p.code === code) || null;
  };

  const disableSubmit =
    !searchParams?.pickUpPlaceId ||
    (diffDeliveryPlace && !searchParams?.dropOffPlaceId) ||
    (!betweenAllowedAge && !searchParams?.driverAge);

  const getAgreementsRenderOption = (item: string | null, props: HTMLAttributes<HTMLLIElement>) => {
    return (
      <li {...props}>
        <Typography className="Item-Name">{item}</Typography>
      </li>
    );
  };

  return (
    <StyledSearchForm component="form" isDialog={isDialog} onSubmit={searchResults}>
      <Card padding="1.4rem 2rem 1.5rem">
        <Grid container rowSpacing={1} columnSpacing={{ sm: 2, md: 3 }}>
          {/* Pick-up / delivery Place */}
          <Grid container item rowSpacing={1} columnSpacing={3}>
            <Grid item xs={12} lg={diffDeliveryPlace ? 6 : 12}>
              <SelectPlaces
                className="FormSelect"
                places={orderedPlaces}
                value={getPlaceById(searchParams?.pickUpPlaceId)}
                label={t('searchForm.pickUpPlace')}
                onChange={(e, value) => handleSelectPickUpPlace(value)}
              />
            </Grid>
            {diffDeliveryPlace && (
              <Grid item xs={12} lg={6}>
                <SelectPlaces
                  className="FormSelect"
                  places={orderedPlaces}
                  value={getPlaceById(searchParams?.dropOffPlaceId)}
                  label={t('searchForm.deliveryPlace')}
                  onChange={(e, value) => handleSelectDropOffPlace(value)}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={diffDeliveryPlace}
                    onClick={() => setDiffDeliveryPlace(!diffDeliveryPlace)}
                  />
                }
                label={t('searchForm.diffDeliveryPlace')}
              />
            </FormGroup>
          </Grid>

          {/* Pick-up Date & Time */}
          <Grid container item xs={12} rowSpacing={1} columnSpacing={2}>
            <Grid item xs={6} lg={7} className="DateTime-Col">
              <CustomDatePicker
                label={t('searchForm.pickUpDate')}
                selected={searchParams?.dateFrom || dayjs().toDate()}
                selectsStart
                startDate={searchParams?.dateFrom || dayjs().toDate()}
                endDate={searchParams?.dateTo || getMinEndDate()}
                monthsShown={width > 460 ? 2 : 1}
                onChange={(date) => handleChangeDateFrom(date)}
                onCalendarClose={handleOpenTo}
                excludeDates={getPickUpExcludeDates()}
                filterDate={filterPickUpDate}
                minDate={dayjs().toDate()}
                maxDate={
                  maxDays && searchParams?.dateFrom
                    ? dayjs(searchParams.dateFrom).add(maxDays, 'day').toDate()
                    : null
                }
              />
            </Grid>
            <Grid item xs={6} lg={5} className="DateTime-Col">
              <FormControl fullWidth className="FormSelect">
                <InputLabel id="pickup-time-label">{t('searchForm.hour')}</InputLabel>
                <Select
                  labelId="pickup-time-label"
                  value={pickUpHours.length > 0 ? searchParams?.hourFrom || '' : ''}
                  label={t('searchForm.hour')}
                  onChange={({ target }) => handleChange('hourFrom', target.value)}
                  IconComponent={ExpandMore}
                  defaultValue={searchParams?.hourFrom || ''}
                  margin="dense"
                  placeholder="select"
                  startAdornment={
                    <InputAdornment position="start">
                      <AccessTimeOutlined />
                    </InputAdornment>
                  }
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                  disabled={pickUpHours.length === 0}
                >
                  {pickUpHours.map((hour, idx) => {
                    return (
                      <MenuItem key={idx} value={hour}>
                        {hour}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Delivery Date & Time */}
          <Grid container item xs={12} rowSpacing={1} columnSpacing={2}>
            <Grid item xs={6} lg={7} className="DateTime-Col">
              <CustomDatePicker
                selected={searchParams?.dateTo}
                label={t('searchForm.deliveryDate')}
                onChange={(date) => handleChange('dateTo', date)}
                selectsEnd
                startDate={searchParams?.dateFrom || dayjs().toDate()}
                endDate={searchParams?.dateTo || getMinEndDate()}
                monthsShown={width > 460 ? 2 : 1}
                ref={dateToRef}
                excludeDates={getDropOffExcludeDates()}
                filterDate={filterDropOffDate}
                minDate={dayjs(searchParams?.dateFrom || undefined)
                  .add(minDays, 'day')
                  .toDate()}
                maxDate={
                  maxDays && searchParams?.dateFrom
                    ? dayjs(searchParams.dateFrom).add(maxDays, 'day').toDate()
                    : null
                }
              />
            </Grid>
            <Grid item xs={6} lg={5} className="DateTime-Col">
              <FormControl fullWidth className="FormSelect">
                <InputLabel id="pickup-time-label">{t('searchForm.hour')}</InputLabel>
                <Select
                  labelId="pickup-time-label"
                  value={dropOffHours.length > 0 ? searchParams?.hourTo || '' : ''}
                  label={t('searchForm.hour')}
                  onChange={({ target }) => handleChange('hourTo', target.value)}
                  IconComponent={ExpandMore}
                  defaultValue={searchParams?.hourTo || ''}
                  margin="dense"
                  startAdornment={
                    <InputAdornment position="start">
                      <AccessTimeOutlined />
                    </InputAdornment>
                  }
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                  disabled={dropOffHours.length === 0}
                >
                  {dropOffHours.map((hour, idx) => {
                    return (
                      <MenuItem key={idx} value={hour}>
                        {hour}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Commercial Agreement */}

          <Grid item xs={12}>
            <CustomAutocomplete
              placeholder={t('searchForm.commercialAgreement')}
              value={getAgreementByCode(searchParams?.commercialAgreement)}
              onChange={(e, value) => handleChange('commercialAgreement', value?.code || null)}
              options={availableCommercialAgreementList}
              renderOption={(props, item) => getAgreementsRenderOption(item?.code || null, props)}
              getOptionLabel={(option) => option?.code || ''}
              noOptionsText={t('searchForm.noAvailableCommercialAgreements')}
              disableClearable={false}
            />
          </Grid>

          {/* Promotional Code */}
          <Grid item xs={12}>
            <TextField
              value={searchParams?.promotionCode || ''}
              label={t('searchForm.promoCode')}
              placeholder={t('searchForm.writeHere')}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalOfferOutlined />
                  </InputAdornment>
                ),
              }}
              onChange={({ target }) => handleChange('promotionCode', target.value)}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} container direction="row" alignItems="center" className="DriverAge">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={betweenAllowedAge}
                    onClick={() => setBetweenAllowedAge(!betweenAllowedAge)}
                  />
                }
                label={t('searchForm.betweenAllowedAge', { min: 21, max: 65 })}
              />
            </FormGroup>
            {!betweenAllowedAge && (
              <TextField
                label={t('searchForm.driverAge')}
                size="small"
                value={searchParams?.driverAge || ''}
                onChange={({ target }) => handleChange('driverAge', target.value)}
                fullWidth={false}
                error={formErrors.age}
                helperText={formErrors.age && t('errors.completeInfo')}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              type="submit"
              disabled={disableSubmit}
              loading={loading}
            >
              {t('searchForm.search')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </StyledSearchForm>
  );
}
export default SearchForm;
