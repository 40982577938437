import { AxiosError } from 'axios';
import { createContext, useState } from 'react';
import {
  handleGetBookingItem,
  handleCancelBooking,
  handleGetBookings,
  handleGetBookingsByDay,
  handleChangePayer,
  handleGetBookingsSummary,
} from '../actions/actions.booking';
import { mockBookingList, mockBookingsSummary } from './dummy';
import type {
  AdditionalListItem,
  APIResponse,
  AscDesc,
  Car,
  CarCategory,
  Customer,
  FileType,
  PaginatedData,
  Place,
  PriceInformation,
} from './types';

export enum BookingStatusEnum {
  Reserved = 0,
  Confirmed = 1,
  Delivered = 2,
  Closed = 3,
  Canceled = 4,
  Quoted = 5,
}

export interface BookingItem {
  id: number;
  customer: Customer;
  balance: number;
  totalPayed: number;
  extra: string;
  promotion: number;
  isQuotation: boolean;
  car: Car;
  category: CarCategory;
  fromDate: string;
  toDate: string;
  deliveryPlace: Place;
  isOnRequest: boolean;
  returnPlace: Place;
  totalDaysString: string;
  price: number;
  currency: string;
  franchise: number;
  franchiseDamage: number;
  franchiseRollover: number;
  franchiseTheft: number;
  totalDays: number;
  unlimitedKm: boolean;
  averageDayPrice: number;
  priceInformation: PriceInformation;
  additional: AdditionalListItem[];
  currentStatus: BookingStatusEnum;
  deliveryTransportationId: string;
  returnTransportationId: string;
  isCustomerOver25: boolean;
  externalSystemId: string;
  promotionCode: string;
  maxAllowedDistance: number;
  maxAllowedDistanceByDay: number;
  exchangeRate: number;
  agentName: string;
  agentId: string;
  createdAt: string;
  commercialAgreement: string;
  notes?: string;
}

export interface BookingsSummary {
  summary: {
    month: string;
    total: number;
  }[];
}

export interface BookingList extends PaginatedData {
  results: BookingItem[];
}

export interface ExtraInfo {
  notes: string;
  companyFiles: FileType[];
}

export interface Agreements {
  commercialAgreement?: string;
  promotionType?: string;
  discountAmount?: string;
}

interface ContextProps {
  bookingList: BookingList;
  bookingsByDay: BookingItem[];
  bookingsSummary: BookingsSummary | null;
  getBookings: (
    offset: number,
    limit: number,
    sortBy: string | null,
    sortDirection: AscDesc | null,
  ) => Promise<APIResponse>;
  getBookingItem: (id: string) => Promise<APIResponse>;
  getBookingsSummary: () => Promise<APIResponse>;
  cancelBooking: (id: number) => Promise<APIResponse>;
  getBookingsByDay: (date: Date) => Promise<APIResponse>;
  changePayer: (bookingId: number, priceItemId: number, assigned: number) => Promise<APIResponse>;
}

export const BookingContext = createContext({} as ContextProps);

export const BookingProvider = ({ children }: { children: JSX.Element }) => {
  const [bookingList, setBookingList] = useState<BookingList>(mockBookingList);
  const [bookingsByDay, setBookingsByDay] = useState<BookingItem[]>(mockBookingList.results);
  const [bookingsSummary, setBookingsSummary] = useState<BookingsSummary | null>(
    mockBookingsSummary,
  );

  const getBookings = async (
    offset: number,
    limit: number,
    sortBy: string | null,
    sortDirection: AscDesc | null,
  ): Promise<APIResponse> => {
    try {
      const response = (await handleGetBookings(offset, limit, sortBy, sortDirection))?.data;
      if (response.error) return { error: response.error };
      else {
        setBookingList(response.data);
        return { error: '' };
      }
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const getBookingsByDay = async (date: Date): Promise<APIResponse> => {
    try {
      const response = (await handleGetBookingsByDay(date))?.data;
      if (response.error) return { error: response.error };
      else {
        setBookingsByDay(response.data);
        return { error: '' };
      }
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const getBookingItem = async (id: string): Promise<APIResponse> => {
    try {
      const response = (await handleGetBookingItem(id))?.data;
      if (response.error) return { error: response.error };
      else return { data: response.data, error: '' };
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const getBookingsSummary = async (): Promise<APIResponse> => {
    try {
      const response = (await handleGetBookingsSummary())?.data;
      if (response.error) return { error: response.error };
      else {
        setBookingsSummary(response.data);
        return { error: '' };
      }
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const cancelBooking = async (id: number): Promise<APIResponse> => {
    try {
      const response = (await handleCancelBooking(id))?.data;
      if (response.error) return { error: response.error };
      else return { data: response.data, error: '' };
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const changePayer = async (bookingId: number, priceItemId: number, assigned: number) => {
    try {
      const response = (await handleChangePayer(bookingId, priceItemId, assigned))?.data;
      if (response.error) return { error: response.error };
      else return { data: response.data, error: '' };
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  return (
    <BookingContext.Provider
      value={{
        bookingList,
        bookingsByDay,
        bookingsSummary,
        getBookingsByDay,
        getBookings,
        getBookingsSummary,
        getBookingItem,
        cancelBooking,
        changePayer,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
