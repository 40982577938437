import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import {
  PlaceOutlined,
  DomainOutlined,
  DirectionsBusFilledOutlined,
  DirectionsBoatFilledOutlined,
  LocalAirportOutlined,
} from '@mui/icons-material';
import type { IconProps, SelectPlacesProps } from './types';
import { CustomMenuItem } from './index.styled';
import {
  AirportNames,
  BusNames,
  OfficeNames,
  PointNames,
  PortNames,
} from '../../constants/placesTypes';
import type { Place } from '../../contexts/types';
import CustomAutocomplete from '../CustomAutocomplete';

function SelectPlaces({ label, value, places, className = '', onChange }: SelectPlacesProps) {
  const { t } = useTranslation();

  const Icon = ({ category }: IconProps) => {
    if (category) {
      if (AirportNames.some((i) => i === category)) {
        return <LocalAirportOutlined />;
      }
      if (OfficeNames.some((i) => i === category)) {
        return <DomainOutlined />;
      }
      if (PortNames.some((i) => i === category)) {
        return <DirectionsBoatFilledOutlined />;
      }
      if (PointNames.some((i) => i === category)) {
        return <PlaceOutlined />;
      }
      if (BusNames.some((i) => i === category)) {
        return <DirectionsBusFilledOutlined />;
      }
    }
    return <DomainOutlined />;
  };

  const getRenderOption = (props: HTMLAttributes<HTMLLIElement>, place: Place) => {
    const country = place.country ? `${place.city ? ' - ' : ''}${place.country}` : '';

    return (
      <CustomMenuItem {...props}>
        <Icon category={place.category.toLowerCase()} />
        <Box>
          <Typography className="Item-Name">{place.branchOfficeName || place.name}</Typography>
          <Typography className="Item-City">{`${place.city || ''}${country}`}</Typography>
        </Box>
      </CustomMenuItem>
    );
  };

  if (!value) return null;

  return (
    <CustomAutocomplete
      disablePortal
      disableClearable
      placeholder={label}
      className={className}
      value={value}
      onChange={onChange}
      options={places}
      startAdornment={<PlaceOutlined className="StartAdornment" />}
      renderOption={(props, place) => getRenderOption(props, place)}
      getOptionLabel={(option) => option.branchOfficeName || option.name}
      noOptionsText={t('searchForm.noAvailablePlaces')}
    />
  );
}

export default SelectPlaces;
