import { ExpandMore, NotesOutlined, PersonOutlineOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Card from '../../components/Card';
import CustomDatePicker from '../../components/CustomDatePicker';
import FileItem from '../../components/FileItem';
import SidebarLayout from '../../components/SidebarLayout';
import UploadFileBtn from '../../components/UploadFileBtn';
import { countries } from '../../constants/data';
import { CustomerContext } from '../../contexts/CustomerContext';
import { RentalContext } from '../../contexts/RentalContext';
import { Customer, FeedbackMsg, FileType, FormError } from '../../contexts/types';
import { useEffectOnMount } from '../../hooks';
import { convertToBase64, numberToString } from '../../utils/dataUtils';
import { isValidDocument } from '../../utils/validationUtils';
import { getQueryParam } from '../../utils/windowUtils';
import { ActionButtons, ContentWrapper, ErrorWrapper } from './index.styled';

function AddCustomerContainer() {
  const { documentTypes, fiscalConditionList } = useContext(RentalContext);
  const { customerList, getCustomerItem, addOrUpdateCustomer } = useContext(CustomerContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [getCustomerError, setGetCustomerError] = useState<string>('');
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [contactInfoAccordion, setContactInfoAccordion] = useState<boolean>(true);
  const [extraInfoAccordion, setExtraInfoAccordion] = useState<boolean>(true);
  const [newCustomerInfo, setNewCustomerInfo] = useState<Customer | null>(null);
  const [formErrors, setFormErrors] = useState<FormError | null>(null);
  const [saveActionFeedback, setSaveActionFeedback] = useState<FeedbackMsg>({
    severity: 'success',
    text: '',
  });

  const { t } = useTranslation();
  const queryCustomerId = getQueryParam('id');
  const navigate = useNavigate();

  useEffectOnMount(() => {
    const handleGetCustomer = async () => {
      if (!queryCustomerId) return;
      setLoading(true);
      const response = await getCustomerItem(queryCustomerId!);
      // if (response.error) {
      //   setGetCustomerError(response.error);
      // } else {
      //   setCustomer(response.data);
      //   setNewCustomerInfo(response.data)
      // }

      // TODO: Uncomment above code once API is ready and remove next line setCustomer(..)
      setCustomer(customerList!.results[0]);
      setNewCustomerInfo(customerList!.results[0]);
      setLoading(false);
    };
    handleGetCustomer();
  });

  yup.addMethod(yup.string, 'isValidCustomerDoc', function () {
    const errorMsg = t('errors.wrongDocumentFormat');

    return this.test('test-document-format', errorMsg, (documentId) => {
      return isValidDocument(documentTypes, newCustomerInfo?.documentTypeId, documentId as string);
    });
  });

  yup.addMethod(yup.array, 'areFilesValid', function () {
    return this.test('company-files', t('companyFilesError'), (files) => {
      if (files?.length === 0 || files?.some((f) => !f.name || !f.file)) {
        return false;
      }
      return true;
    });
  });

  const customerInfoSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.firstName') })),
    lastName: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.lastName') })),
    taxPayer: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.taxPayer') })),
    documentId: (yup as any)
      .string()
      .isValidCustomerDoc()
      .required(t('errors.inputIsRequired', { field: t('customerData.documentId') })),
    documentTypeId: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.documentType') })),
    emailAddress: yup
      .string()
      .email(t('errors.validEmail'))
      .required(t('errors.inputIsRequired', { field: t('customerData.emailAddress') })),
    cellPhone: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.cellPhone') })),
    zipCode: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.zipCode') })),
    country: yup
      .string()
      .required(t('errors.inputIsRequired', { field: t('customerData.country') })),
  });

  const handleChange = (key: string, value: string | number | Date | null) => {
    setNewCustomerInfo({ ...newCustomerInfo, [key]: value } as Customer);
  };

  const handleUploadFiles = async (files: FileList | null) => {
    let newFiles = [] as FileType[];
    const inputFiles = files ? [...files] : [];
    if (files) {
      newFiles = await Promise.all(
        inputFiles.map(async (file) => {
          const base64 = await convertToBase64(file);
          return {
            name: file.name,
            content: typeof base64 === 'string' ? base64.split('base64,')?.[1] : '',
            type: '',
          };
        }),
      );
      const prevFiles = newCustomerInfo?.files || [];
      setNewCustomerInfo({ ...newCustomerInfo, files: [...prevFiles, ...newFiles] } as Customer);
    }
  };

  const handleSaveOrUpdateProfile = async () => {
    setSaveActionFeedback({ severity: 'success', text: '' });
    customerInfoSchema
      .validate(newCustomerInfo, { abortEarly: false })
      .then(async (valid) => {
        if (valid) {
          const response = await addOrUpdateCustomer(newCustomerInfo!);
          if (response.error) {
            setSaveActionFeedback({ severity: 'error', text: response.error });
          } else {
            setCustomer(response.data);
            setNewCustomerInfo(response.data);

            setSaveActionFeedback({
              severity: 'success',
              text: newCustomerInfo?.id
                ? t('addCustomer.updateSuccess')
                : t('addCustomer.createSuccess'),
            });

            setTimeout(() => setSaveActionFeedback({ severity: 'success', text: '' }), 2000);
          }
        }
      })
      .catch((err) => {
        const formErrors = err.inner.reduce((prevErrors: any, currentError: any) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        setFormErrors(formErrors);
      });
  };

  const onDeleteFile = (index: number) => {
    const newFiles = newCustomerInfo?.files.filter((f, i) => i !== index);
    setNewCustomerInfo({ ...newCustomerInfo, files: newFiles } as Customer);
  };

  if (loading) {
    return (
      <SidebarLayout>
        <Box className="LoadingWrapper">
          <CircularProgress color="primary" />
        </Box>
      </SidebarLayout>
    );
  }

  if (getCustomerError) {
    return (
      <SidebarLayout>
        <ErrorWrapper>
          <Typography variant="body1">{getCustomerError}</Typography>
          <Button variant="contained" onClick={() => navigate(-1)}>
            {t('general.back')}
          </Button>
        </ErrorWrapper>
      </SidebarLayout>
    );
  }

  return (
    <SidebarLayout>
      <ContentWrapper>
        {saveActionFeedback.text && (
          <Alert className="AlertText" severity={saveActionFeedback.severity}>
            {saveActionFeedback.text}
          </Alert>
        )}
        <Card padding="1rem" className="ContactPanel">
          <Accordion
            expanded={contactInfoAccordion}
            onChange={() => setContactInfoAccordion(!contactInfoAccordion)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box className="AccordionTitle">
                <PersonOutlineOutlined />
                <Typography variant="h2" className="Subtitle">
                  {customer
                    ? `${customer.firstName} ${customer.lastName}`
                    : t('addCustomer.newCustomer')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    value={newCustomerInfo?.firstName || ''}
                    onChange={({ target }) => handleChange('firstName', target.value)}
                    label={t('customerData.firstName')}
                    error={!!formErrors?.firstName}
                    helperText={formErrors?.firstName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label={t('customerData.lastName')}
                    value={newCustomerInfo?.lastName || ''}
                    onChange={({ target }) => handleChange('lastName', target.value)}
                    error={!!formErrors?.lastName}
                    helperText={formErrors?.lastName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label={t('customerData.emailAddress')}
                    value={newCustomerInfo?.emailAddress || ''}
                    onChange={({ target }) => handleChange('emailAddress', target.value)}
                    error={!!formErrors?.emailAddress}
                    helperText={formErrors?.emailAddress}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    value={newCustomerInfo?.cellPhone || ''}
                    label={t('customerData.cellPhone')}
                    onChange={({ target }) => handleChange('cellPhone', target.value)}
                    error={!!formErrors?.cellPhone}
                    helperText={formErrors?.cellPhone}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <FormControl fullWidth>
                    <InputLabel id="documentTypeId-label">
                      {t('customerData.documentTypeId')}
                    </InputLabel>
                    <Select
                      labelId="documentTypeId-label"
                      value={numberToString(newCustomerInfo?.documentTypeId)}
                      onChange={({ target }) =>
                        handleChange('documentTypeId', Number(target.value))
                      }
                      label={t('customerData.documentTypeId')}
                      error={!!formErrors?.documentTypeId}
                    >
                      {documentTypes.map((document) => {
                        return (
                          <MenuItem key={`${document.id}`} value={`${document.id}`}>
                            {document.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formErrors?.documentTypeId && (
                      <FormHelperText error>{formErrors?.documentTypeId}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <TextField
                    value={newCustomerInfo?.documentId || ''}
                    label={t('customerData.documentId')}
                    onChange={({ target }) => handleChange('documentId', target.value)}
                    error={!!formErrors?.documentId}
                    helperText={formErrors?.documentId}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <FormControl fullWidth>
                    <InputLabel id="taxPayer-label">{t('customerData.taxPayer')}</InputLabel>
                    <Select
                      labelId="taxPayer-label"
                      value={numberToString(newCustomerInfo?.taxPayer)}
                      onChange={({ target }) => handleChange('taxPayer', Number(target.value))}
                      label={t('customerData.taxPayer')}
                      error={!!formErrors?.taxPayer}
                    >
                      {fiscalConditionList.map((item) => {
                        return (
                          <MenuItem key={`${item.id}`} value={`${item.id}`}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formErrors?.taxPayer && (
                      <FormHelperText error>{formErrors?.taxPayer}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <FormControl fullWidth>
                    <InputLabel id="country-label">{t('customerData.country')}</InputLabel>
                    <Select
                      labelId="country-label"
                      value={newCustomerInfo?.country || ''}
                      defaultValue=""
                      onChange={({ target }) => handleChange('country', target.value)}
                      autoWidth
                      label={t('customerData.country')}
                      error={!!formErrors?.country}
                    >
                      {countries.map((country, id) => {
                        return (
                          <MenuItem key={id} value={country.name}>
                            {country.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formErrors?.country && (
                      <FormHelperText error>{formErrors?.country}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <TextField
                    value={newCustomerInfo?.city || ''}
                    label={t('customerData.city')}
                    onChange={({ target }) => handleChange('city', target.value)}
                    error={!!formErrors?.city}
                    helperText={formErrors?.city}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <TextField
                    value={newCustomerInfo?.state || ''}
                    label={t('customerData.state')}
                    onChange={({ target }) => handleChange('state', target.value)}
                    error={!!formErrors?.state}
                    helperText={formErrors?.state}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <TextField
                    value={newCustomerInfo?.addressDepartment || ''}
                    label={t('customerData.addressDepartment')}
                    onChange={({ target }) => handleChange('addressDepartment', target.value)}
                    error={!!formErrors?.addressDepartment}
                    helperText={formErrors?.addressDepartment}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    value={newCustomerInfo?.address || ''}
                    label={t('customerData.address')}
                    onChange={({ target }) => handleChange('address', target.value)}
                    error={!!formErrors?.address}
                    helperText={formErrors?.address}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                  <TextField
                    value={newCustomerInfo?.addressNumber || ''}
                    label={t('customerData.addressNumber')}
                    onChange={({ target }) => handleChange('addressNumber', target.value)}
                    error={!!formErrors?.addressNumber}
                    helperText={formErrors?.addressNumber}
                    fullWidth
                    inputProps={{ inputMode: 'numeric' }}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <TextField
                    value={newCustomerInfo?.zipCode || ''}
                    label={t('customerData.zipCode')}
                    onChange={({ target }) => handleChange('zipCode', target.value)}
                    error={!!formErrors?.zipCode}
                    helperText={formErrors?.zipCode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <TextField
                    value={newCustomerInfo?.driverLicense || ''}
                    label={t('customerData.driverLicense')}
                    onChange={({ target }) => handleChange('driverLicense', target.value)}
                    error={!!formErrors?.driverLicense}
                    helperText={formErrors?.driverLicense}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <CustomDatePicker
                    className="DatePicker"
                    dateFormat="dd MMM yyyy"
                    label={t('customerData.licenseExpirationDate')}
                    selected={newCustomerInfo?.licenseExpirationDate || null}
                    monthsShown={1}
                    onChange={(date) => handleChange('licenseExpirationDate', date)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <FormControl fullWidth>
                    <InputLabel id="issue-by-country-label">{t('customerData.issueBy')}</InputLabel>
                    <Select
                      labelId="issue-by-country-label"
                      value={newCustomerInfo?.licenseIssueByCountry || ''}
                      defaultValue=""
                      onChange={({ target }) => handleChange('licenseIssueByCountry', target.value)}
                      autoWidth
                      label={t('customerData.issueBy')}
                      error={!!formErrors?.licenseIssueByCountry}
                    >
                      {countries.map((country, id) => {
                        return (
                          <MenuItem key={id} value={country.name}>
                            {country.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formErrors?.licenseIssueByCountry && (
                      <FormHelperText error>{formErrors?.licenseIssueByCountry}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <TextField
                    value={newCustomerInfo?.licenseIssueByState || ''}
                    label={t('customerData.licenseState')}
                    onChange={({ target }) => handleChange('licenseIssueByState', target.value)}
                    error={!!formErrors?.licenseIssueByState}
                    helperText={formErrors?.licenseIssueByState}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Card>
        <Card padding="1rem">
          <Accordion
            expanded={extraInfoAccordion}
            onChange={() => setExtraInfoAccordion(!extraInfoAccordion)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Box className="AccordionTitle">
                <NotesOutlined />
                <Typography variant="h2" className="Subtitle">
                  {t('addCustomer.additionalInfo')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="FilesWrapper">
                <UploadFileBtn onClick={handleUploadFiles} className="UploadFiles-Btn" />
                {newCustomerInfo?.files?.map((file, index) => {
                  return (
                    <FileItem
                      key={index}
                      name={file.name}
                      link={file.content}
                      onDelete={() => onDeleteFile(index)}
                    />
                  );
                })}
              </Box>
              <Box>
                <TextField
                  label={t('customerData.notes')}
                  value={newCustomerInfo?.notes || ''}
                  onChange={({ target }) => handleChange('notes', target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Card>
        <ActionButtons>
          <Button variant="secondary-action" onClick={() => navigate(-1)}>
            {t('general.back')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveOrUpdateProfile}
            disabled={!newCustomerInfo}
          >
            {t('general.save')}
          </Button>
        </ActionButtons>
      </ContentWrapper>
    </SidebarLayout>
  );
}
export default AddCustomerContainer;
