import { AxiosError } from 'axios';
import { createContext, useState } from 'react';
import {
  handleGetCustomers,
  handleGetCustomer,
  handleAddOrUpdateCustomer,
} from '../actions/actions.customers';
import { mockCustomerList } from './dummy';
import type { APIResponse, AscDesc, Customer, PaginatedData } from './types';

export interface CustomerList extends PaginatedData {
  results: Customer[];
}

interface ContextProps {
  customerList: CustomerList | null;
  getCustomers: (
    offset: number,
    limit: number,
    sortBy: string | null,
    sortDirection: AscDesc | null,
  ) => Promise<APIResponse>;
  getCustomerItem: (id: string) => Promise<APIResponse>;
  addOrUpdateCustomer: (customer: Customer) => Promise<APIResponse>;
}

export const CustomerContext = createContext({} as ContextProps);

export const CustomerProvider = ({ children }: { children: JSX.Element }) => {
  const [customerList, setCustomerList] = useState<CustomerList | null>(mockCustomerList);
  const getCustomers = async (
    offset: number,
    limit: number,
    sortBy: string | null,
    sortDirection: AscDesc | null,
  ): Promise<APIResponse> => {
    try {
      const response = (await handleGetCustomers(offset, limit, sortBy, sortDirection))?.data;
      if (response.error) return { error: response.error };
      else {
        setCustomerList(response.data);
        return { error: '' };
      }
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const getCustomerItem = async (id: string): Promise<APIResponse> => {
    try {
      const response = (await handleGetCustomer(id))?.data;
      if (response.error) return { error: response.error };
      return { data: response.data, error: '' };
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const addOrUpdateCustomer = async (customer: Customer): Promise<APIResponse> => {
    try {
      const response = (await handleAddOrUpdateCustomer(customer))?.data;
      if (response.error) return { error: response.error };
      return { data: response.data, error: '' };
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  return (
    <CustomerContext.Provider
      value={{
        customerList,
        getCustomers,
        getCustomerItem,
        addOrUpdateCustomer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
