import { AxiosError } from 'axios';
import { createContext, useState } from 'react';
import {
  handleGetOperators,
  handleGetOperator,
  handleChangeOperatorStatus,
  handleAddOrUpdateOperator,
} from '../actions/actions.operators';
import { mockOperatorList } from './dummy';
import type { APIResponse, AscDesc, PaginatedData } from './types';

export interface Operator {
  id: number;
  globalId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  status: OperatorStatusEnum;
  active: boolean;
}

export interface OperatorList extends PaginatedData {
  results: Operator[];
}

export enum OperatorStatusEnum {
  Confirmed = 0,
  Canceled = 1,
  Pending = 2,
}

interface ContextProps {
  operatorList: OperatorList | null;
  getOperators: (
    offset: number,
    limit: number,
    sortBy: string | null,
    sortDirection: AscDesc | null,
  ) => Promise<APIResponse>;
  getOperatorItem: (id: string) => Promise<APIResponse>;
  changeOperatorStatus: (id: number, status: string) => Promise<APIResponse>;
  addOrUpdateOperator: (operator: Operator) => Promise<APIResponse>;
}

export const OperatorContext = createContext({} as ContextProps);

export const OperatorProvider = ({ children }: { children: JSX.Element }) => {
  const [operatorList, setOperatorList] = useState<OperatorList | null>(mockOperatorList);
  const getOperators = async (
    offset: number,
    limit: number,
    sortBy: string | null,
    sortDirection: AscDesc | null,
  ): Promise<APIResponse> => {
    try {
      const response = (await handleGetOperators(offset, limit, sortBy, sortDirection))?.data;
      if (response.error) return { error: response.error };
      else {
        setOperatorList(response.data);
        return { error: '' };
      }
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const changeOperatorStatus = async (id: number, status: string): Promise<APIResponse> => {
    try {
      const response = (await handleChangeOperatorStatus(id, status))?.data;
      if (response.error) return { error: response.error };
      else {
        setOperatorList(response.data);
        return { error: '' };
      }
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const getOperatorItem = async (id: string): Promise<APIResponse> => {
    try {
      const response = (await handleGetOperator(id))?.data;
      if (response.error) return { error: response.error };
      else return { data: response.data, error: '' };
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  const addOrUpdateOperator = async (operator: Operator): Promise<APIResponse> => {
    try {
      const response = (await handleAddOrUpdateOperator(operator))?.data;
      if (response.error) return { error: response.error };
      return { data: response.data, error: '' };
    } catch (error) {
      const err = error as AxiosError;
      return { error: err?.message };
    }
  };

  return (
    <OperatorContext.Provider
      value={{
        operatorList,
        getOperators,
        getOperatorItem,
        changeOperatorStatus,
        addOrUpdateOperator,
      }}
    >
      {children}
    </OperatorContext.Provider>
  );
};
