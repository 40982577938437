import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Box, FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { StyledResetPasswordContainer } from './index.styled';
import Card from '../../components/Card';
import { AuthContext } from '../../contexts/AuthContext';
import { isValidPassword } from '../../utils/validationUtils';
import Logo from '../../assets/images/logo.png';
import { getQueryParam } from '../../utils/windowUtils';

function ResetPasswordContainer() {
  const { resetPassword } = useContext(AuthContext);
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const token = getQueryParam('token');
  const email = getQueryParam('email');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setError('');
    setLoading(true);
    const response = await resetPassword(token!, email!, password);
    if (response.error) setError(response.error);
    else navigate('/');
    setLoading(false);
  };

  return (
    <StyledResetPasswordContainer>
      <Card>
        <img className="CompanyLogo" src={Logo} alt="Company logo" />
        {error && (
          <Box className="ErrorWrapper">
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Box className="InputsWrapper">
          <FormControl fullWidth variant="outlined">
            <TextField
              value={password}
              onBlur={() => setPasswordError(!isValidPassword(password))}
              type={showPassword ? 'text' : 'password'}
              onChange={({ target }) => setPassword(target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={t('login.password')}
              error={passwordError}
              helperText={passwordError && t('errors.wrongPassword')}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              value={repeatPassword}
              onBlur={() => setRepeatPasswordError(password !== repeatPassword)}
              type={showRepeatPassword ? 'text' : 'password'}
              onChange={({ target }) => setRepeatPassword(target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    >
                      {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={t('login.repeatPassword')}
              error={repeatPasswordError}
              helperText={repeatPasswordError && t('errors.unmatchedPasswords')}
            />
          </FormControl>
        </Box>
        <LoadingButton
          variant="contained"
          fullWidth
          size="large"
          onClick={handleResetPassword}
          disabled={passwordError || repeatPasswordError}
          className="ResetButton"
          loading={loading}
        >
          {t('login.resetPassword')}
        </LoadingButton>
      </Card>
    </StyledResetPasswordContainer>
  );
}

export default ResetPasswordContainer;
