import { Box, Button } from '@mui/material';
import styled from 'styled-components';

const StyledDateHandler = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  right: -14px;

  .Date {
    margin: 0;
    color: ${({ theme: { palette } }) => palette.text.secondary};
    font-size: 0.9rem;
    padding: 0 20px;
  }
`;

const DateHandlerBtn = styled.button`
  border: none;
  background: transparent;
  color: ${({ theme: { palette } }) => palette.text.secondary};
  cursor: pointer;
`;

const BackToTodayBtn = styled(Button)`
  &.MuiButton-outlined {
    font-size: 0.8rem;
    padding: 0 8px;
    min-width: 0;
    border-radius: 5px;
    margin-right: 10px;
    line-height: 1.5;
  }
`;

export { StyledDateHandler, DateHandlerBtn, BackToTodayBtn };
