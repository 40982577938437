import { TableContainer } from '@mui/material';
import styled from 'styled-components';

const StyledCustomTable = styled((props) => <TableContainer {...props} />)`
  &.MuiPaper-root {
    box-shadow: none;
    border: ${({ theme: { extra } }) => `1px solid ${extra.border}`};
    border-radius: 14px;
  }

  .TableHeaderCell {
    font-size: 0.9rem;
    color: ${({ theme: { palette } }) => palette.text.primary} !important;
    cursor: pointer;
  }

  .TableLink {
    text-decoration: none;
    color: ${({ theme: { palette } }) => palette.info.main};

    &:hover {
      color: ${({ theme: { palette } }) => palette.info.dark};
    }
  }

  .MuiTableRow-root {
    transition: all 0.3s;
  }

  .MuiTableRow-root:hover {
    background-color: ${({ theme: { palette } }) => palette.background.default} !important;
  }

  .MuiTableRow-root.MuiTableRow-footer:hover {
    background-color: inherit !important;
  }

  .MuiTableCell-root {
    font-size: 0.9rem;
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }

  .MuiTablePagination-root {
    border: none;
    padding: 0 1.6rem !important;
  }
`;

export { StyledCustomTable };
