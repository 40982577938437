import { Box, Grid, Skeleton } from '@mui/material';
import { SCREEN_LG } from '../../constants/screenSize';
import { useWindowSize } from '../../utils/windowUtils';
import StyledResultItemLoading from './index.styled';

const ResultItemLoading = () => {
  const { width } = useWindowSize();

  const isMobileScreen = width < SCREEN_LG;

  return (
    <StyledResultItemLoading>
      <Grid container spacing={{ md: 1, lg: 6 }}>
        <Grid item md={12} lg={2} className="CarImage-Wrapper">
          <Skeleton variant="rectangular" className="Rectangle CarImage" />
          {isMobileScreen && (
            <Box className="Title-Wrapper">
              <Skeleton variant="text" className="Title" />
              <Skeleton variant="text" className="SubTitle" />
            </Box>
          )}
        </Grid>
        <Grid item md={12} lg={7}>
          {!isMobileScreen && (
            <>
              <Skeleton variant="text" className="Title" />
              <Skeleton variant="text" className="SubTitle" />
            </>
          )}
          <Grid container item spacing={{ xs: 2, lg: 5 }}>
            <Grid item xs={6}>
              <Skeleton variant="text" className="CarDetailsText" />
              <Skeleton variant="text" className="CarDetailsText" />
              <Skeleton variant="text" className="CarDetailsText" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" className="CarDetailsText" />
              <Skeleton variant="text" className="CarDetailsText" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} lg={3} alignItems="center" justifyContent="center">
          <Skeleton variant="text" className="PriceText" />
          <Skeleton variant="text" className="Price" />
          <Skeleton variant="rectangular" className="Rectangle" height={38} />
        </Grid>
      </Grid>
    </StyledResultItemLoading>
  );
};
export default ResultItemLoading;
