import { ChangeEvent } from 'react';
import {
  Grid,
  Box,
  TextField,
  IconButton,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SegmentIcon from '@mui/icons-material/Segment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { StyledContainer, FilesWrapper } from './index.styled';
import Card from '../Card/index';
import { convertToBase64 } from '../../utils/dataUtils';
import type { AdditionalInfoProps } from './types';
import type { ExtraInfo } from '../../contexts/BookingContext';
import type { FileType } from '../../contexts/types';

function AdditionalInfo({ extraInfo, setExtraInfo }: AdditionalInfoProps) {
  const { t } = useTranslation();

  const handleChange = (key: string, value: string | number | null) => {
    setExtraInfo({ ...extraInfo, [key]: value } as ExtraInfo);
  };

  const onCompanyFilesChange = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    let companyFiles = [] as FileType[];
    const inputFiles = files ? [...files] : [];

    if (files) {
      companyFiles = await Promise.all(
        inputFiles.map(async (file) => {
          const base64 = await convertToBase64(file);
          return {
            name: file.name,
            content: typeof base64 === 'string' ? base64.split('base64,')?.[1] : '',
            type: '',
          };
        }),
      );
    }
    setExtraInfo({ ...extraInfo, companyFiles } as ExtraInfo);
  };

  const handleDeleteFile = (index: number) => {
    const newCompanyFiles = extraInfo?.companyFiles?.filter((f, idx) => idx !== index) || [];
    setExtraInfo({ ...extraInfo, companyFiles: newCompanyFiles } as ExtraInfo);
  };

  const getCompanyFilesComponent = () => {
    if (extraInfo?.companyFiles && extraInfo?.companyFiles.length > 0) {
      return (
        <FilesWrapper>
          <List>
            {extraInfo.companyFiles.map((file, index) => {
              return (
                <Box key={index}>
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete-file"
                        onClick={() => handleDeleteFile(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={file.name} />
                  </ListItem>
                  {index !== extraInfo.companyFiles.length - 1 && <Divider component="li" />}
                </Box>
              );
            })}
          </List>
        </FilesWrapper>
      );
    }

    return (
      <Button
        className="UploadFiles-Button"
        variant="text"
        component="label"
        disableFocusRipple
        disableRipple
      >
        <input hidden accept="image/*,.pdf" type="file" onChange={onCompanyFilesChange} multiple />
        <AttachFileIcon />
        <Typography variant="body2" className="Typography2">
          {t('checkout.additionalInfo.attach')}
        </Typography>
      </Button>
    );
  };

  return (
    <StyledContainer>
      <Grid item sm={12}>
        <Card padding="1rem">
          <Box>
            <Accordion className="AccordionItemsWrapper">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="extra-info-content"
                id="extra-info-header"
              >
                <Box className="IconWrapper">
                  <SegmentIcon />
                  <Typography variant="subtitle1" className="Typography">
                    {t('checkout.additionalInfo.title')}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className="AccordionDetails">
                {getCompanyFilesComponent()}

                <TextField
                  className="TextfieldDetails"
                  id="additionalInfo-notes"
                  fullWidth
                  placeholder={t('checkout.additionalInfo.notes')}
                  multiline
                  rows={4}
                  label={t('checkout.additionalInfo.notes')}
                  onChange={({ target }) => handleChange('notes', target.value)}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Card>
      </Grid>
    </StyledContainer>
  );
}

export default AdditionalInfo;
