import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_XS } from '../../constants/screenSize';

const HeaderWrapper = styled((props) => <Grid {...props} />)`
  align-items: center;
  margin-bottom: 2rem;

  .RightCol {
    display: flex;
    justify-content: flex-end;
  }

  button {
    min-width: 200px;
    margin: 0 7px;
    line-height: 1;
    margin: auto 0;
  }

  @media (max-width: ${SCREEN_XS}px) {
    h1 {
      margin-bottom: 1rem;
    }

    button {
      width: 100%;
    }
  }
`;

const TableWrapper = styled((props) => <Box {...props} />)`
  .TableHeaderCell:first-child,
  .MuiTableCell-body:first-child {
    padding-left: 40px;
  }
`;

export { HeaderWrapper, TableWrapper };
