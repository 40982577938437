import { Box } from '@mui/material';
import styled from 'styled-components';
import { SCREEN_LG } from '../../constants/screenSize';

const StyledSearchResultItem = styled((props) => <Box {...props} />)`
  margin-bottom: 1.5rem;

  .Item-FirstCol {
    align-items: center;
    justify-content: center;
    display: flex;
    padding-right: 20px;
  }

  .Item-SndCol {
    padding-right: 20px;
  }

  .Item-ThirdCol {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    button {
      padding: 0.3rem 1rem;
    }

    .TotalDays {
      font-size: 0.8rem;
      color: ${({ theme: { palette } }) => palette.text.secondary};
    }

    .Price {
      font-size: 1.5rem;
      color: ${({ theme: { palette } }) => palette.text.primary};
      font-weight: 900;
      letter-spacing: 1;

      span {
        font-size: 0.8rem;
        margin-right: 5px;
      }
    }
  }

  .NoCarPicture {
    height: auto;
    width: 50px;
    color: ${({ theme: { palette } }) => palette.text.secondary};
    opacity: 0.3;
  }

  @media screen and (max-width: ${SCREEN_LG}px) {
    .Item-FirstCol {
      padding-right: 0;
      margin-bottom: 1rem;
      width: 100%;
    }

    .Item-SndCol {
      padding-right: 0;
      width: 100%;
    }

    .Item-ThirdCol {
      width: 100%;
      margin-top: 1.5rem;
    }
  }
`;

const CarImage = styled.img`
  width: 100%;

  @media screen and (max-width: ${SCREEN_LG}px) {
    width: 50%;
  }
`;

const CarTitle = styled(Box)`
  margin-bottom: 0.8rem;

  h2 {
    font-size: 1.1rem;
    color: ${({ theme: { palette } }) => palette.text.primary};
    font-weight: 600;

    span {
      font-size: 0.8rem;
      color: ${({ theme: { palette } }) => palette.text.secondary};
    }
  }

  p {
    font-size: 0.9rem;
    color: ${({ theme: { palette } }) => palette.text.secondary};
    font-weight: 600;
  }

  @media screen and (max-width: ${SCREEN_LG}px) {
    width: 50%;
  }
`;

const CarDescription = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    width: 20px;
    margin-right: 10px;
    fill: ${({ theme: { palette } }) => palette.text.primary};
  }

  p {
    font-size: 0.9rem;
    color: ${({ theme: { palette } }) => palette.text.secondary};
    line-height: 1.2;
  }
`;
export { StyledSearchResultItem, CarImage, CarDescription, CarTitle };
