import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledBookingStatus from './index.styled';
import { BookingStatusProps } from './types';

function BookingStatus({ status }: BookingStatusProps) {
  const { t } = useTranslation();

  return (
    <StyledBookingStatus status={status} className="BookingStatus">
      <span />
      <Typography>{t(`bookingStatus.${status}`)}</Typography>
    </StyledBookingStatus>
  );
}

export default BookingStatus;
