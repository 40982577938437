import { useState, useEffect } from 'react';

const getWindowSize = (): { width: number; height: number } => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowSize = (): { width: number; height: number } => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const getQueryParam = (key: string) => {
  const querystring = window.location.search;
  const query = new URLSearchParams(querystring);
  return query.get(key);
};
