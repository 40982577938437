import { useContext } from 'react';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SearchContext } from '../../contexts/SearchContext';
import { setDateFormat } from '../../utils/dataUtils';
import Card from '../Card';
import { StyledSearchForm } from './index.styled';
import type { MobileSearchFormProps } from './types';

function MobileSearchForm({ searchParams, handleChangeDates }: MobileSearchFormProps) {
  const { t } = useTranslation();
  const { diffDeliveryPlace } = useContext(SearchContext);

  if (!searchParams) return null;

  const fromDate = setDateFormat(searchParams.dateFrom, 'DD MMM');
  const toDate = setDateFormat(searchParams.dateTo, 'DD MMM');

  return (
    <StyledSearchForm>
      <Card padding="1rem 1.5rem">
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography className="Places">
              {diffDeliveryPlace && <ArrowForward className="ArrowIcon ArrowForward" />}
              {searchParams.pickUpPlaceName}
            </Typography>
            {diffDeliveryPlace && (
              <Typography className="Places">
                <ArrowBack className="ArrowIcon ArrowBack" />
                {searchParams.dropOffPlaceName}
              </Typography>
            )}
            <Typography className="DateWrapper">
              {fromDate}
              <span className="Hours"> {searchParams.hourFrom}</span>
              <span className="DotSeparator">•</span>
              {toDate}
              <span className="Hours"> {searchParams.hourTo}</span>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" fullWidth onClick={handleChangeDates}>
              {t('general.change')}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </StyledSearchForm>
  );
}
export default MobileSearchForm;
