export const AUTH_ROUTE = {
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  NOT_FOUND: '/not-found',
  FORGOT_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password',
};

export const DASHBOARD_ROUTE = {
  BOOKINGS: '/bookings',
  CUSTOMERS: '/customers',
  COMMISSIONS: '/commissions',
  OPERATORS: '/operators',
};

export const SEARCH_ROUTE = {
  SEARCH: '/search',
  CHECKOUT: '/checkout',
  BOOKING_CONFIRMATION: '/booking-confirmation',
  BOOKING_ERROR: '/booking-error',
};

export const BOOKINGS_ROUTE = {
  BOOKING_DETAILS: '/bookings/booking-details',
};

export const CUSTOMERS_ROUTE = {
  ADD_CUSTOMER: '/customers/add-customer',
  EDIT_CUSTOMER: '/customers/edit-customer',
  CUSTOMER_DETAILS: '/customers/customer-details',
};

export const ACCOUNT_ROUTE = {
  MY_ACCOUNT: '/my-account',
};

export const OPERATORS_ROUTE = {
  ADD_OPERATOR: '/add-operator',
  EDIT_OPERATOR: '/edit-operator',
};
